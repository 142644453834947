import {Injectable} from '@angular/core';
import {Mistake} from '../../../models/mistake';

@Injectable()
export class MistakeFactory {
    map(response) {
        return new Mistake(
            response.index,
            response.corrected,
            response.character,
            response.character_typed
        );
    }
}
