import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NEVER, Observable} from 'rxjs';
import {Menu} from '../../../models/menu';
import {UserService} from '../../../shared/services/user.service';
import {LanguageService} from '../../../shared/services/language.service';
import {MenuService} from '../../../shared/services/menu.service';
import {OrderService} from '../../../shared/services/order.service';
import {NavigationService} from '../../../shared/services/navigation.service';
import {AppService} from '../../../shared/services/app.service';
import {StateService} from '../../../services/state.service';

@Component({
    selector: 'course-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
    @Output() openMobileNav: EventEmitter<void> = new EventEmitter<void>();
    items$: Observable<Menu>;
    cartItems: Observable<number>;

    constructor(
        public userService: UserService,
        public stateService: StateService,
        public languageService: LanguageService,
        private router: Router,
        private menuService: MenuService,
        private orderService: OrderService,
        public navigationService: NavigationService,
        public appService: AppService
    ) {
    }

    ngOnInit() {
        this.items$ = this.menuService.getMenu();

        this.cartItems = this.stateService.getCartItemsObservable();
    }

    mobileNavClick() {
        this.openMobileNav.emit();
    }

    getBadgeObservable(id) {
        switch (id) {
            case 'progress':
                return this.stateService.getUnseenBadgesObservable();

            default:
                return NEVER;
        }
    }
}

