<div class="container">
    <div class="head">
        <a routerLink="/" class="logo">
            <img
                ngSrc="assets/images/layout/logo.svg"
                i18n-alt="@@website.logoAlt"
                alt="Tyyp logo"
                width="285.42"
                height="80"
            />
        </a>

        <div class="tabNav" *ngIf="!success">
            <a
                *ngFor="let page of formPages; let i = index" class="formPageLink"
                (click)="goToPage(i)"
                [class.active]="i === pageIndex"
                [class.completed]="validatedPages[i]"
            >
                <div class="graphic">
                    <div class="lineLeft"></div>
                    <div class="lineRight"></div>
                    <div class="ball">
                        <div></div>
                    </div>
                </div>
                <div class="label">
                    {{ pageNames[page] }}
                </div>
            </a>
        </div>

        <div class="filler"></div>
    </div>

    <div class="content">
        <div class="formContainer">
            <ng-content></ng-content>
        </div>
    </div>
</div>

<div class="nav">
    <div class="navContent">
        <div class="leftNav">
            <a
                *ngIf="!success && pageIndex > 0"
                (click)="back()"
                mat-flat-button
                color="primary"
                class="backBtn"
                i18n="@@previous"
            >Previous</a>

            <a *ngIf="success" routerLink="/" mat-flat-button color="primary" class="backBtn">
                <span>
                    <mat-icon>west</mat-icon>
                    <ng-container i18n="@@organizationRegistration.backToHome">Homepage</ng-container>
                </span>
            </a>
        </div>

        <div class="rightNav">
            <ng-container *ngIf="!success">
                <a *ngIf="pageIndex < formPages.length - 1" (click)="continue()" mat-flat-button color="accent" class="submitBtn">
                    <span>
                        <ng-container i18n="@@continue">Continue</ng-container>
                        <mat-icon class="buttonRightIcon">east</mat-icon>
                    </span>
                </a>
                <a *ngIf="pageIndex === formPages.length - 1" (click)="submit()" mat-flat-button color="accent" class="submitBtn">
                    <span>
                        <mat-icon>check</mat-icon>
                        <ng-container i18n="@@save">Save</ng-container>
                    </span>
                </a>
            </ng-container>

            <a *ngIf="success" routerLink="/login" mat-flat-button color="accent" class="submitBtn">
                <span i18n="@@login.title">Log in</span>
            </a>
        </div>
    </div>
</div>
