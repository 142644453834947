import {Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';

@Component({
    selector: 'course-faq-question',
    templateUrl: './faqQuestion.component.html',
    styleUrls: ['./faqQuestion.component.scss'],
})
export class FaqQuestionComponent{
    @Input() questionName: string;
    @ViewChild('answer') answerElement: ElementRef;
    isVisible = false;
    answerHeight = 0;

    toggleAnswer() {
        this.setAnswerHeight();
        this.isVisible = !this.isVisible;
    }

    setAnswerHeight() {
        this.answerHeight = this.answerElement.nativeElement.scrollHeight;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setAnswerHeight();
    }
}

