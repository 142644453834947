import {Injectable} from '@angular/core';
import {first, tap} from 'rxjs/operators';
import {StateService} from '../../services/state.service';

@Injectable()
export class AudioService {
    letterTyped: HTMLAudioElement;
    mistake: HTMLAudioElement;
    countScore: HTMLAudioElement;
    star1: HTMLAudioElement;
    star2: HTMLAudioElement;
    star3: HTMLAudioElement;

    constructor(
        private stateService: StateService
    ) {}

    preloadCourseAudio() {
        this.letterTyped = new Audio();
        this.letterTyped.src = 'assets/audio/letterTyped.mp3';
        this.letterTyped.load();

        this.mistake = new Audio();
        this.mistake.src = 'assets/audio/mistake.mp3';
        this.mistake.volume = 0.2;
        this.mistake.load();
    }

    preloadReportAudio() {
        this.countScore = new Audio();
        this.countScore.src = 'assets/audio/activityCompleted.mp3';
        this.countScore.volume = 0.3;
        this.countScore.load();

        this.star1 = new Audio();
        this.star1.src = 'assets/audio/star_1.mp3';
        this.star1.load();

        this.star2 = new Audio();
        this.star2.src = 'assets/audio/star_2.mp3';
        this.star2.load();

        this.star3 = new Audio();
        this.star3.src = 'assets/audio/star_3.mp3';
        this.star3.load();
    }

    checkPersonalSetting() {
        return this.stateService.personalSettings.getSetting('soundEffects').pipe(
            first()
        );
    }

    playLetterTyped() {
        return this.checkPersonalSetting().pipe(
            tap(
                (soundEffects) => {
                    if (soundEffects) {
                        this.letterTyped.currentTime = 0;
                        this.letterTyped.play();
                    }
                }
            )
        );
    }

    playMistake() {
        return this.checkPersonalSetting().pipe(
            tap(
                (soundEffects) => {
                    if (soundEffects) {
                        this.mistake.currentTime = 0;
                        this.mistake.play();
                    }
                }
            )
        );
    }

    playCountScore() {
        return this.checkPersonalSetting().pipe(
            tap(
                (soundEffects) => {
                    if (soundEffects) {
                        this.countScore.currentTime = 0;
                        this.countScore.play();
                    }
                }
            )
        );
    }

    playStar1() {
        return this.checkPersonalSetting().pipe(
            tap(
                (soundEffects) => {
                    if (soundEffects) {
                        this.star1.currentTime = 0;
                        this.star1.play();
                    }
                }
            )
        );
    }

    playStar2() {
        return this.checkPersonalSetting().pipe(
            tap(
                (soundEffects) => {
                    if (soundEffects) {
                        this.star2.currentTime = 0;
                        this.star2.play();
                    }
                }
            )
        );
    }

    playStar3() {
        return this.checkPersonalSetting().pipe(
            tap(
                (soundEffects) => {
                    if (soundEffects) {
                        this.star3.currentTime = 0;
                        this.star3.play();
                    }
                }
            )
        );
    }
}
