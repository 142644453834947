export const environment = {
    backendUrl: 'https://api.tyyp.io/',
    apiUrl: 'https://api.tyyp.io/api/',
    auth: {
        clientId: 1,
        clientSecret: 'CKpK4c4rxZyNwsuBxcfdWyEqtuRxDMCsCkaIUpdJ',
    },
    google: {
        oAuth: {
            clientId: '191934786882-hec4or76636tiv5tsj89pso4kj55lsri.apps.googleusercontent.com'
        }
    },
    microsoft: {
        clientId: '71a765b5-f4dd-44bd-ba1c-f2ef428db980',
        authority: 'https://login.microsoftonline.com/common'
    }
};
