<table
    mat-table
    [dataSource]="licenses"
>
    <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef i18n="@@admin.product">Product</th>
        <td mat-cell *matCellDef="let element">
           {{ Products[element.product.title] }}
        </td>
    </ng-container>

    <ng-container matColumnDef="valid_from">
        <th mat-header-cell *matHeaderCellDef i18n="@@admin.claimedAt">Activated at</th>
        <td mat-cell *matCellDef="let element">
            {{ element.claimedAt | date:'MMM d, y' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="valid_until">
        <th mat-header-cell *matHeaderCellDef i18n="@@admin.validUntil">Valid until</th>
        <td mat-cell *matCellDef="let element">
            {{ element.validUntil | date:'MMM d, y' }}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['product', 'valid_from', 'valid_until']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['product', 'valid_from', 'valid_until']"></tr>
</table>
