<div class="ctaFooter" [class.hideCtaFooter]="!(ctaFooter | async)">
    <div class="wrapper">
        <ng-container [ngSwitch]="ctaFooter | async">
            <ng-container *ngSwitchCase="'home'">
                <h3 i18n="@@website.ctaFooter.home">Start now with the free trial version</h3>
                <a
                    [routerLink]="'/' + navigationService.getRouteTranslation('course')"
                    mat-flat-button
                    class="ctaFooterBtn"
                    i18n="@@website.getStarted"
                >Try it free</a>
            </ng-container>

            <ng-container *ngSwitchCase="'school'">
                <h3 i18n="@@website.ctaFooter.school">Start for free with your school</h3>
                <a
                    routerLink="/school-registration"
                    mat-flat-button
                    class="ctaFooterBtn"
                    i18n="@@website.startFree"
                >Start for free</a>
            </ng-container>

            <ng-container *ngSwitchCase="'business'">
                <h3 i18n="@@website.ctaFooter.business">Start for free with your company</h3>
                <a
                    routerLink="/business-registration"
                    mat-flat-button
                    class="ctaFooterBtn"
                    i18n="@@website.startFree"
                >Start for free</a>
            </ng-container>

            <ng-container *ngSwitchCase="'purchase'">
                <h3 i18n="@@website.ctaFooter.purchase">Start now and get instant access</h3>
                <a
                    (click)="addToCart()"
                    mat-flat-button
                    class="ctaFooterBtn"
                    i18n="@@website.shop.order"
                >Buy now</a>
            </ng-container>
        </ng-container>
    </div>
</div>
