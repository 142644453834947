import {Injectable, Injector} from '@angular/core';
import {CurrencyFactory} from './currency.factory';
import {OrderFactory} from './order.factory';
import {ProductFactory} from './product.factory';
import {DiscountCode} from '../../../models/discountCode';
import {UserFactory} from './user.factory';

@Injectable()
export class DiscountCodeFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        const productFactory = this.injector.get(ProductFactory);
        const currencyFactory = this.injector.get(CurrencyFactory);
        const userFactory = this.injector.get(UserFactory);
        const orderFactory = this.injector.get(OrderFactory);

        return new DiscountCode(
            response.id,
            response.code,
            Number(response.amount),
            Date.parse(response.created_at),
            Date.parse(response.valid_until),
            response.maximum_uses,
            response.product ? productFactory.map(response.product) : undefined,
            response.currency ? currencyFactory.map(response.currency) : undefined,
            response.discount_code_claims_count ? response.discount_code_claims_count : undefined,
            response.discount_code_claims ? response.discount_code_claims.map(
                (discountCodeClaim) => {
                    return discountCodeClaim.order ? orderFactory.map(discountCodeClaim.order) : undefined;
                }
            ) : undefined,
            response.email ? response.email : undefined,
            response.user_id ? response.user_id : undefined,
            response.user ? userFactory.map(response.user) : undefined
        );
    }
}
