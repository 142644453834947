import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Currency} from '../models/currency';
import {Badge} from '../models/badge';
import {Modal} from '../models/modal';
import {Organization} from '../models/organization';
import {User} from '../models/user';
import {PersonalSettings} from '../models/personalSettings';
import {SsoData} from '../models/ssoData';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    private activeCurrency: BehaviorSubject<Currency> = new BehaviorSubject<Currency>(undefined);
    private notifications: BehaviorSubject<{[key: string]: any}> = new BehaviorSubject<{[key: string]: any}>({
        unpaidInvoices: null,
        withoutLicense: null
    });
    private loadingRequests: BehaviorSubject<{url: string, isBlocking: boolean, showLoader: boolean}[]>
        = new BehaviorSubject<{url: string, isBlocking: boolean, showLoader: boolean}[]>([]);
    private hasLoadingError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public activeTabs: {
        viewOrganization: BehaviorSubject<number>,
        viewClass: BehaviorSubject<number>,
        viewStudent: BehaviorSubject<number>,
        organizationSettings: BehaviorSubject<number>
    } = {
        viewOrganization: new BehaviorSubject<number>(undefined),
        viewClass: new BehaviorSubject<number>(undefined),
        viewStudent: new BehaviorSubject<number>(undefined),
        organizationSettings: new BehaviorSubject(undefined)
    };
    private earnedBadges: BehaviorSubject<Badge[]> = new BehaviorSubject<Badge[]>([]);
    private unseenBadges: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private includeArchivedClasses: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private activeCourseId: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
    private activityLogFilterExerciseIds: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    private currentModal: BehaviorSubject<Modal | undefined> = new BehaviorSubject<Modal | undefined>(undefined);
    private modalCloseEvents: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
    private cartItems: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private activeOrganization: BehaviorSubject<Organization | undefined> = new BehaviorSubject<Organization | undefined>(undefined);
    private activeUser: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
    private includeUsersFromArchived: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public personalSettings: PersonalSettings = new PersonalSettings();
    private firstHeroAnimationSkipped: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private ssoAccountCreationData: BehaviorSubject<SsoData> = new BehaviorSubject<SsoData>(null);
    private ssoAccountLinkData: BehaviorSubject<SsoData> = new BehaviorSubject<SsoData>(null);

    setActiveCurrency(currency: Currency) {
        this.activeCurrency.next(currency);
    }

    getActiveCurrency(): Currency {
        return this.activeCurrency.getValue();
    }

    setNotifications(notifications: {[key: string]: any}) {
        this.notifications.next(notifications);
    }

    getNotifications(): {[key: string]: any} {
        return this.notifications.getValue();
    }

    getNotificationsObservable(): Observable<{[key: string]: any}> {
        return this.notifications.asObservable();
    }

    setLoadingRequests(loadingRequests: {url: string, isBlocking: boolean, showLoader: boolean}[]) {
        this.loadingRequests.next(loadingRequests);
    }

    getLoadingRequests(): {url: string, isBlocking: boolean, showLoader: boolean}[] {
        return this.loadingRequests.getValue();
    }

    getLoadingRequestsObservable(): Observable<{url: string, isBlocking: boolean, showLoader: boolean}[]> {
        return this.loadingRequests.asObservable();
    }

    setHasLoadingError(hasLoadingError: boolean) {
        this.hasLoadingError.next(hasLoadingError);
    }

    getHasLoadingError(): boolean {
        return this.hasLoadingError.getValue();
    }

    getHasLoadingErrorObservable(): Observable<boolean> {
        return this.hasLoadingError.asObservable();
    }

    setEarnedBadges(badges: Badge[]) {
        this.earnedBadges.next(badges);
    }

    getEarnedBadges(): Badge[] {
        return this.earnedBadges.getValue();
    }

    getEarnedBadgesObservable(): Observable<Badge[]> {
        return this.earnedBadges.asObservable();
    }

    setUnseenBadges(unseenBadges: number) {
        this.unseenBadges.next(unseenBadges);
    }

    getUnseenBadges(): number {
        return this.unseenBadges.getValue();
    }

    getUnseenBadgesObservable(): Observable<number> {
        return this.unseenBadges.asObservable();
    }

    setIncludeArchivedClasses(includeArchived: boolean) {
        this.includeArchivedClasses.next(includeArchived);
    }

    getIncludeArchivedClasses(): boolean {
        return this.includeArchivedClasses.getValue();
    }

    getIncludeArchivedClassesObservable(): Observable<boolean> {
        return this.includeArchivedClasses.asObservable();
    }

    setActiveCourseId(id: number) {
        this.activeCourseId.next(id);
    }

    getActiveCourseId(): number {
        return this.activeCourseId.getValue();
    }

    getActiveCourseIdObservable(): Observable<number> {
        return this.activeCourseId.asObservable();
    }

    setActivityLogFilterExerciseIds(ids: number[]) {
        this.activityLogFilterExerciseIds.next(ids);
    }

    getActivityLogFilterExerciseIds(): number[] {
        return this.activityLogFilterExerciseIds.getValue();
    }

    setCurrentModal(modal: Modal) {
        this.currentModal.next(modal);
    }

    getCurrentModal(): Modal {
        return this.currentModal.getValue();
    }

    getCurrentModalObservable(): Observable<Modal> {
        return this.currentModal.asObservable();
    }

    setModalCloseEvents(events: string) {
        this.modalCloseEvents.next(events);
    }

    getModalCloseEvents(): string {
        return this.modalCloseEvents.getValue();
    }

    getModalCloseEventsObservable(): Observable<string> {
        return this.modalCloseEvents.asObservable();
    }

    setCartItems(items: number) {
        this.cartItems.next(items);
        localStorage.setItem('shop.items', '' + items);
    }

    getCartItems(): number {
        return this.cartItems.getValue();
    }

    getCartItemsObservable() {
        return this.cartItems.asObservable();
    }

    setActiveOrganization(organization: Organization) {
        this.activeOrganization.next(organization);
    }

    getActiveOrganization(): Organization {
        return this.activeOrganization.getValue();
    }

    setActiveUser(user: User) {
        this.activeUser.next(user);
    }

    getActiveUser(): User {
        return this.activeUser.getValue();
    }

    getActiveUserObservable(): Observable<User> {
        return this.activeUser.asObservable();
    }

    setIncludeUsersFromArchived(include: boolean) {
        this.includeUsersFromArchived.next(include);
    }

    getIncludeUsersFromArchived(): boolean {
        return this.includeUsersFromArchived.getValue();
    }

    getIncludeUsersFromArchivedObservable(): Observable<boolean> {
        return this.includeUsersFromArchived.asObservable();
    }

    setFirstHeroAnimationSkipped(value: boolean) {
        this.firstHeroAnimationSkipped.next(value);
    }

    getFirstHeroAnimationSkipped(): boolean {
        return this.firstHeroAnimationSkipped.getValue();
    }

    setSsoAccountCreationData(value: SsoData) {
        this.ssoAccountCreationData.next(value);
    }

    getSsoAccountCreationData(): SsoData {
        return this.ssoAccountCreationData.getValue();
    }

    setSsoAccountLinkData(value: SsoData) {
        this.ssoAccountLinkData.next(value);
    }

    getSsoAccountLinkData(): SsoData {
        return this.ssoAccountLinkData.getValue();
    }
}
