<h1 i18n="@@privacyPolicy">Privacy Policy</h1>
<p i18n="@@privacy.lastChange">Updated on {{ '07-07-2022' }}</p>


<h2 i18n="@@privacy.whatInfo">What data do we collect?</h2>
<h3 i18n="@@privacy.accountInfoTitle">Student, administrator, and instructor account information</h3>
<ng-container i18n="@@privacy.accountInfo">
    <p>When creating an account or activating a license by either the user himself, the user's organization, or by a Tyyp administrator, the following personal data is kept:</p>
    <ul>
        <li>First name and name</li>
        <li>E-mail address</li>
        <li>Username</li>
    </ul>
    <p>For security reasons, the IP address is recorded with every login.</p>
</ng-container>

<h3 i18n="@@privacy.organizationInfoTitle">Organization data</h3>
<ng-container i18n="@@privacy.organizationInfo">
    <p>When creating an organization, the following information is kept:</p>
    <ul>
        <li>
            Organization:
            <ul>
                <li>Name</li>
                <li>VAT number</li>
                <li>Address</li>
                <li>Billing em-ail address</li>
            </ul>
        </li>
        <li>
            Organization administrator account information:
            <ul>
                <li>First and last name</li>
                <li>E-mail address</li>
            </ul>
        </li>
    </ul>
    <p>This information and other organization settings can be changed at any time by an administrator of the organization. These organization settings include:</p>
    <ul>
        <li>Account permissions of students in the organization</li>
        <li>Course settings for students in the organization</li>
        <li>The default language setting for users in the organization</li>
        <li>The default course for users in the organization</li>
    </ul>
</ng-container>

<h3 i18n="@@privacy.courseProgressTitle">Course activity and progress</h3>
<ng-container i18n="@@privacy.courseProgress">
    <p>When using the course, the following data is kept:</p>
    <ul>
        <li>Aggregated data per exercise made, such as: characters per minute, ratio correctly/incorrectly typed, score achieved, stars achieved</li>
        <li>The characters typed in each exercise</li>
    </ul>
</ng-container>

<h3 i18n="@@privacy.billingTitle">Billing data</h3>
<ng-container i18n="@@privacy.billing">
    <p>When ordering licenses or other products, the following information is kept:</p>
    <ul>
        <li>First name and name</li>
        <li>E-mail address</li>
        <li>Organization name</li>
        <li>VAT number</li>
        <li>Address</li>
        <li>PO number</li>
    </ul>
</ng-container>

<h3 i18n="@@privacy.exerciseEditorTitle">Exercise editor</h3>
<ng-container i18n="@@privacy.exerciseEditor">
    <p>When organization administrators or instructors create custom exercises, the following data is recorded:</p>
    <ul>
        <li>Title</li>
        <li>Type of exercise</li>
        <li>Exercise text</li>
        <li>Completion criteria</li>
        <li>Settings related to student access</li>
    </ul>
</ng-container>

<h3 i18n="@@privacy.cookiesTitle">Cookies and LocalStorage</h3>
<ng-container i18n="@@privacy.cookies">
    <p>Upon opening the website for the first time, visitors will be shown an information window with information regarding the use of cookies on this website. Visitors can use this window to accept all cookies, or decline certain cookie categories.</p>
    <p>Visitors can modify there cookie consent at any time by clicking the cookie button that is visible at the bottom right of every website page.</p>
    <h4>Necessary cookies</h4>
    <p>Necessary cookies are cookies that are required for the website to function. As such, these cookies cannot be disabled.</p>
    <p>We use an LocalStorage, a technical alternative to cookies, to temporarily store certain settings in a user's browser.</p>
    <p>By working with LocalStorage, we ensure that the user does not have to re-enter the settings on every page. The LocalStorage settings are never used to track users online or to collect information for commercial purposes.</p>
    <p>The following data is stored in LocalStorage:</p>
    <ul>
        <li>The language of the user</li>
        <li>Accessibility settings</li>
        <li>The number of products in the shopping cart</li>
        <li>Audio settings of exercises and games</li>
        <li>For not logged in users: the progress and activity in the course</li>
        <li>For logged in users: authentication tokens to access their account</li>
        <li>The user's keyboard setting</li>
        <li>The user's active course</li>
    </ul>
    <h4>Analytical cookies</h4>
    <p>We use Google Analytics to analyse our web traffic. Google Analytics uses tracking cookies to monitor the actions taken by website visitors.</p>
    <p>We only use this data in aggregated form, individual visitors cannot be identified.</p>
    <h4>Marketing cookies</h4>
    <p>We use the Meta Pixel to inform Meta about the pages you viewed on this website and the conversions you completed.</p>
    <p>This helps us to personalize advertisements for you on Meta platforms such as Facebook and Instagram.</p>
    <p>To learn how Meta stores and uses your data, please refer to the <a href="https://www.facebook.com/privacy/policy/">Meta Privacy Policy</a>.</p>
</ng-container>


<h2 i18n="@@privacy.whyInfo">What do we use the data for?</h2>
<h3 i18n="@@privacy.generalTitle">General</h3>
<ng-container i18n="@@privacy.general">
    <p>All data is only used for the internal functioning of the Tyyp platform itself. The aggregated details collected by Google Analytics are the only exception to this rule.</p>
</ng-container>

<h3 i18n="@@privacy.accessToInfoTitle">Access to data</h3>
<ng-container i18n="@@privacy.accessToInfo">
    <p>Personal information is never posted publicly. Users always have access to their own data through their account overview, and can make corrections at any time.</p>
    <p>Organization administrators and instructors have access to personal data of the users within their organization. They must handle this data in a responsible manner.</p>
    <p>Tyyp's administrators have access to all data. They treat all personal data with the required focus on privacy.</p>
</ng-container>

<h3 i18n="@@privacy.communicationTitle">Communication</h3>
<ng-container i18n="@@privacy.communication">
    <p>We only send transactional e-mails to users, such as account details after registration.</p>
    <p>We never send commercial e-mails to students of an organization.</p>
    <p>Administrators of an organization organization can opt in to receive news updates about Tyyp when registering. This setting can be changed at any time in their account.</p>
</ng-container>


<h2 i18n="@@privacy.protectInfoTitle">How do we protect personal data?</h2>
<ng-container i18n="@@privacy.protectInfo">
    <p>Each Tyyp employee treats personal data with the necessary attention to privacy. We never pass on data to third parties. With every request for personal data, the identity of the applicant will be sufficiently verified.</p>
    <p>All data is stored in a secure data center in the EU. We recognize that no data transmission over the Internet is 100% secure, but we make reasonable efforts to keep data secure.</p>
</ng-container>


<h2 i18n="@@privacy.howLong">How long do we keep personal data?</h2>
<h3 i18n="@@privacy.howLongAccountInfoTitle">Account gegevens</h3>
<ng-container i18n="@@privacy.howLongAccountInfo">
    <p>Accounts that are no longer active will be automatically deleted. Inactive accounts are accounts without an active license, to which the user has not logged in once for more than 365 days.</p>
    <p>A user can also delete his own account at any time via the account overview, except when the user is in an organization that does not allow this. In that case, the administrator of the organization is responsible for deleting the account.</p>
    <p>When deleting an account, the data remains on the server for another 14 days, so that accidental deletion can still be undone. After this period, the personal data will be irrevocably anonymized. At that point, the account can no longer be linked to a person in any way. The anonymous data can still be used for statistical purposes or to improve the service.</p>
</ng-container>

<h3 i18n="@@privacy.ipTitle">IP address</h3>
<ng-container i18n="@@privacy.ip">
    <p>For security reasons, we keep track of the IP address with every login. This data will be deleted after one week.</p>
</ng-container>

<h3 i18n="@@privacy.howLongOrganizationInfoTitle">Organization data</h3>
<ng-container i18n="@@privacy.howLongOrganizationInfo">
    <p>Organizations are only deleted at the request of the administrator. When deleting an organization, all users of that organization will be deleted. After 14 days, the personal data of these users will be anonymised. At that point, the accounts can no longer be linked to a person in any way.</p>
</ng-container>

<h3 i18n="@@privacy.billingInfoTitle">Billing data</h3>
<ng-container i18n="@@privacy.billingInfo">
    <p>For legal reasons, we cannot delete billing data.</p>
</ng-container>


<h2 i18n="@@privacy.childrenTitle">Children's data</h2>
<ng-container i18n="@@privacy.children">
    <p>Children under the age of 16 from the EU or children under the age of 13 from the US may only use this website with the permission of their parents.</p>
    <p>If we receive a report that a child is using the website without permission, we will delete their account and anonymize their personal data.</p>
    <p>Parents may consult this privacy policy to see what data we collect, and if desired, have their child's account deleted and personal data anonymised.</p>
</ng-container>


<h2 i18n="@@privacy.rightsTitle">User Rights</h2>
<ng-container i18n="@@privacy.rights1">
    <p>Users can consult their personal data at any time by logging into their account and navigating to their account overview.</p>
    <p>From this account overview, corrections can also be made to their data at any time, and the account can also be deleted. If this is not allowed by their organization, one should contact an administrator of that organization.</p>
    <p>
        In case of problems, you can always contact a Tyyp administrator via the
        <a [routerLink]="'/' + navigationService.getRouteTranslation('contact')">
            contact page
        </a>.
    </p>
</ng-container>


<h2 i18n="@@privacy.changesTitle">Changes to this Privacy Policy</h2>
<ng-container i18n="@@privacy.changes">
    <p>This privacy policy is updated regularly. We advise users to check this page regularly.</p>
    <p>In the event that we request additional rights to users' personal data, we will always send a notification by e-mail to either the user himself or the administrator of the organization, or we will post a clear notice on the website for 14 days.</p>
</ng-container>


<h2 i18n="@@privacy.contactTitle">Contact us</h2>
<p
    i18n="@@privacy.contact"
>If you have any questions about this privacy policy or about your account, you can always contact us <a [routerLink]="'/' + navigationService.getRouteTranslation('contact')">contact us</a>.</p>
