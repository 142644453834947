import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {StateService} from '../../../services/state.service';
import {AppService} from '../../../shared/services/app.service';
import {ActivatedRoute} from '@angular/router';

interface StatNumber {
    nr: number;
    decimalPlaces: number;
    symbol?: string;
}

@Component({
    selector: 'course-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit, AfterViewChecked {
    aboutStats: {
        gamesPlayed: StatNumber,
        videosWatched: StatNumber,
        mistakesTyped: StatNumber
    } = {
        gamesPlayed: {nr: 0, decimalPlaces: 0},
        videosWatched: {nr: 0, decimalPlaces: 0},
        mistakesTyped: {nr: 0, decimalPlaces: 0}
    };
    krisCareerLength: number;

    constructor(
        public stateService: StateService,
        private appService: AppService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.formatStats();

        this.krisCareerLength = new Date().getFullYear() - 2009;
    }

    ngAfterViewChecked() {
        this.appService.initAnimations('course-about .slideLeft, course-about .slideRight, course-about .slideUp', 0);
    }

    formatStats() {
        const aboutStats = this.activatedRoute.snapshot.data.aboutStats;

        this.aboutStats.gamesPlayed = this.formatStatNumber(aboutStats.games_played);
        this.aboutStats.videosWatched = this.formatStatNumber(aboutStats.videos_watched);
        this.aboutStats.mistakesTyped = this.formatStatNumber(aboutStats.mistakes_typed);
    }

    formatStatNumber(nr: number): StatNumber {
        let symbol = null;

        if (nr > 1000000000) {
            // billions
            nr /= 1000000000;
            symbol = 'B';
        } else if (nr > 1000000) {
            // millions
            nr /= 1000000;
            symbol = 'M';
        } else if (nr > 1000) {
            // thousands
            nr /= 1000;
            symbol = 'K';
        }

        return {
            nr,
            decimalPlaces: this.determineDecimalPlaces(nr),
            symbol,
        };
    }

    determineDecimalPlaces(nr: number): number {
        const totalPlaces = 3;
        const beforeCommaLength = ('' + Math.round(nr)).length;
        let decimalPlaces = totalPlaces - beforeCommaLength;

        if (decimalPlaces < 0) {
            decimalPlaces = 0;
        }

        return decimalPlaces;
    }
}

