import {Injectable, Injector} from '@angular/core';
import {Sale} from '../../../models/sale';
import {OrderFactory} from './order.factory';
import {ProductFactory} from './product.factory';

@Injectable()
export class SaleFactory {
    constructor(
        private injector: Injector
    ) {
    }

    map(response) {
        const orderFactory = this.injector.get(OrderFactory);
        const productFactory = this.injector.get(ProductFactory);

        return new Sale(
            response.id,
            response.name,
            response.product_id,
            response.percentage,
            Date.parse(response.valid_from),
            Date.parse(response.valid_until),
            response.recurring,
            response.orders_count ? response.orders_count : undefined,
            response.orders ? response.orders.map(
                (order) => {
                    return orderFactory.map(order);
                }
            ) : undefined,
            response.product ? productFactory.map(response.product) : undefined,
        );
    }
}
