import {Component, Input, ViewChild} from '@angular/core';
import {AnalyticsService} from '../../../../services/analytics.service';
import {ModalService} from '../../../../shared/services/modal.service';
import {MatSlideToggle} from '@angular/material/slide-toggle';

@Component({
    selector: 'course-cookie-consent-dialog',
    templateUrl: './cookieConsentDialog.component.html',
    styleUrls: ['./cookieConsentDialog.component.scss'],
})
export class CookieConsentDialogComponent {
    @ViewChild('analytical') analytical: MatSlideToggle;
    @ViewChild('marketing') marketing: MatSlideToggle;
    @Input() showSettings = false;
    @Input() canClose = false;

    constructor(
        private analyticsService: AnalyticsService,
        private modalService: ModalService
    ) {
    }

    confirmAll() {
        this.confirm(true, true);
    }

    confirm(analytical: boolean, marketing: boolean) {
        this.analyticsService.setConsent(analytical, marketing);
        this.modalService.closeModal();
    }

    configure() {
        this.showSettings = true;
    }

    confirmSettings() {
        const analytical = this.analytical.checked;
        const marketing = this.marketing.checked;

        this.confirm(analytical, marketing);
    }

    checkAnalyticalConsent() {
        return localStorage.getItem('cookieConsentAnalytical') !== '0';
    }

    checkMarketingConsent() {
        return localStorage.getItem('cookieConsentMarketing') !== '0';
    }
}

