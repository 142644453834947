import {User} from './user';
import {Organization} from './organization';

export class Class {
    constructor(
        public id: number,
        public name: string,
        public selfJoinCode: string,
        public isArchived: boolean,
        public createdAt: number,
        public instructors?: User[],
        public studentsCount?: number,
        public organization?: Organization
    ) {}
}
