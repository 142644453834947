<mat-toolbar [class.smallMobileMode]="(cartItems | async) > 0">
    <div class="wrapper">
        <a routerLink="/" class="logoLink">
            <img
                ngSrc="assets/images/layout/logo.svg"
                i18n-alt="@@website.logoAlt"
                alt="Tyyp logo"
                class="logo"
                width="285.42"
                height="80"
            />
        </a>

        <nav>
            <span class="desktopNav">
                <ng-container *ngFor="let item of items$ | async">
                    <a
                        *ngIf="item.label"
                        [routerLink]="item.translatedRouterLink ? '/' + navigationService.getRouteTranslation(item.translatedRouterLink) : item.routerLink"
                        routerLinkActive="active"
                    >
                        <span
                            class="label"
                            matBadgeOverlap="false"
                            matBadgePosition="above after"
                            matBadgeColor="warn"
                            matBadgeSize="small"
                            [matBadge]="(getBadgeObservable(item.id) | async)"
                            [matBadgeHidden]="(getBadgeObservable(item.id) | async) < 1"
                        >
                            {{ item.label }}
                        </span>
                    </a>
                    <span *ngIf="item === '|'" class="menuSeparator"></span>
                </ng-container>

                <span class="menuSeparator"></span>
            </span>

            <course-language-menu [smallMobileMode]="(cartItems | async) > 0"></course-language-menu>
            <span class="menuSeparator"></span>

            <course-user-menu></course-user-menu>

            <ng-container *ngIf="(cartItems | async) > 0">
                <span class="menuSeparator"></span>
                <div class="cartButton">
                    <button
                        mat-icon-button
                        routerLink="/checkout"
                    >
                        <mat-icon
                            matBadgeOverlap="true"
                            matBadgePosition="above after"
                            matBadgeColor="warn"
                            matBadgeSize="small"
                            [matBadge]="cartItems | async"
                            aria-hidden="false"
                        >shopping_cart</mat-icon>
                    </button>
                </div>
            </ng-container>

            <div class="mobileNavButton">
                <span class="menuSeparator"></span>
                <button mat-icon-button (click)="mobileNavClick()" i18n-aria-label="@@website.menu" aria-label="Menu">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
        </nav>
    </div>
</mat-toolbar>
