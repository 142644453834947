import {Injectable, Injector} from '@angular/core';
import {ChapterFactory} from './chapter.factory';
import {Course} from '../../../models/course';
import {KeyboardLayoutFactory} from './keyboardLayout.factory';
import {LanguageFactory} from './language.factory';

@Injectable()
export class CourseFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        const chapterFactory = this.injector.get(ChapterFactory);
        const keyboardLayoutFactory = this.injector.get(KeyboardLayoutFactory);
        const languageFactory = this.injector.get(LanguageFactory);

        return new Course(
            response.id,
            response.title,
            response.language_id,
            response.keyboard_layout_id,
            response.chapters ?
                response.chapters.map(chapter => chapterFactory.map(chapter))
                : [],
            response.requires_base_product_id,
            response.students_count ? response.students_count : undefined,
            response.keyboard_layout ? keyboardLayoutFactory.map(response.keyboard_layout) : undefined,
        response.language ? languageFactory.map(response.language) : undefined
        );
    }
}
