import {Chapter} from './chapter';
import {KeyboardLayout} from './keyboardLayout';
import {Language} from './language';

export class Course {
    constructor(
        public id: number,
        public title: string,
        public languageId: number,
        public keyboardLayoutId: number,
        public chapters: Chapter[],
        public requiresBaseProductId: number,
        public studentsCount?: number,
        public keyboardLayout?: KeyboardLayout,
        public language?: Language
    ) {}
}
