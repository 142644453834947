<div class="features">
    <ng-container *ngFor="let featureName of featureList; let i = index">
        <ng-container [ngSwitch]="featureName">
            <div *ngSwitchCase="'lessonPlan'" class="feature lessonPlan">
                <div class="featureImg">
                    <img
                        ngSrc="assets/images/typingCourse/features/lesson_plan.webp"
                        i18n-alt="@@website.org.lessonPlanAlt"
                        alt="Lesson plan for schools"
                        [class]="(i % 2 === 0) ? 'slideRight' : 'slideLeft'"
                        width="550"
                        height="412"
                    />
                </div>
                <div class="featureDescription" [class]="(i % 2 === 0) ? 'slideLeft' : 'slideRight'">
                    <h2 i18n="@@website.org.lessonPlan">Tailor-made lesson plan</h2>
                    <p class="description">
                        <ng-container
                            *ngIf="isSchool"
                            i18n="@@website.org.lessonPlanInfo"
                        >Typing classes at school or self-study? We have a lesson plan available that works for your school!</ng-container>
                    </p>
                    <ul class="checklist">
                        <li>
                            <span
                                i18n="@@website.org.lessonPlan1"
                            >Download a <strong>custom lesson plan</strong></span>
                        </li>
                        <li>
                            <span
                                i18n="@@website.org.lessonPlan2"
                            ><strong>Free guide</strong> for teachers</span>
                        </li>
                        <li>
                            <span
                                i18n="@@website.org.lessonPlan3"
                            ><strong>Self-study</strong> without guidance is possible</span>
                        </li>
                    </ul>

                    <p class="featureCta">
                        <a routerLink="/{{ isSchool ? 'school' : 'business' }}-registration" mat-stroked-button color="primary" class="websiteButton">
                            <span i18n="@@website.startFree">Start for free</span>
                        </a>
                    </p>
                </div>
            </div>

            <div *ngSwitchCase="'trackingPlatform'" class="feature trackingPlatform">
                <div class="featureImg">
                    <img
                        ngSrc="assets/images/typingCourse/features/tracking_platform.webp"
                        i18n-alt="@@website.org.platformAlt"
                        alt="View student progress"
                        [class]="(i % 2 === 0) ? 'slideRight' : 'slideLeft'"
                        width="550"
                        height="412"
                    />
                </div>
                <div class="featureDescription" [class]="(i % 2 === 0) ? 'slideLeft' : 'slideRight'">
                    <h2 i18n="@@website.org.platform">Advanced tracking</h2>
                    <p class="description">
                        <ng-container
                            *ngIf="isSchool"
                            i18n="@@website.org.platformInfoSchool"
                        >The tracking platform was developed especially for schools. Enrolling students and following their progress is child's play!</ng-container>
                        <ng-container
                            *ngIf="!isSchool"
                            i18n="@@website.org.platformInfoBusiness"
                        >With the free tracking platform for companies it becomes really easy to follow the progress of your employees.</ng-container>
                    </p>
                    <ul class="checklist">
                        <ng-container *ngIf="isSchool">
                            <li>
                                <span
                                    i18n="@@website.org.platformSchool1"
                                >Create <strong>Classes</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.org.platformSchool2"
                                ><strong>Enroll students</strong> or let them enroll themselves using the class code</span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.org.platformSchool3"
                                ><strong>Follow up</strong> on students' <strong>results</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.org.platformSchool4"
                                ><strong>Free tracking platform</strong> for teachers</span>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="!isSchool">
                            <li>
                                <span
                                    i18n="@@website.org.platformBusiness1"
                                ><strong>Create Groups</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.org.platformBusiness2"
                                ><strong>Enroll participants</strong> or let them enroll themselves using the group code</span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.org.platformBusiness3"
                                ><strong>Follow up</strong> on participants' <strong>results</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.org.platformBusiness4"
                                ><strong>No fixed costs</strong></span>
                            </li>
                        </ng-container>
                    </ul>

                    <p class="featureCta">
                        <a routerLink="/{{ isSchool ? 'school' : 'business' }}-registration" mat-stroked-button color="primary" class="websiteButton">
                            <span i18n="@@website.startFree">Start for free</span>
                        </a>
                    </p>
                </div>
            </div>

            <div *ngSwitchCase="'extraDiscount'" class="feature extraDiscount">
                <div class="featureImg">
                    <img
                        *ngIf="isSchool"
                        ngSrc="assets/images/typingCourse/features/cheaper_for_schools.webp"
                        i18n-alt="@@website.org.extraDiscountSchoolsAlt"
                        alt="Discount for schools"
                        [class]="(i % 2 === 0) ? 'slideRight' : 'slideLeft'"
                        width="550"
                        height="412"
                    />
                    <img
                        *ngIf="!isSchool"
                        ngSrc="assets/images/typingCourse/features/cheaper_for_businesses.webp"
                        i18n-alt="@@website.org.extraDiscountBusinessesAlt"
                        alt="Discount for businesses"
                        [class]="(i % 2 === 0) ? 'slideRight' : 'slideLeft'"
                        width="550"
                        height="412"
                    />
                </div>
                <div class="featureDescription" [class]="(i % 2 === 0) ? 'slideLeft' : 'slideRight'">
                    <h2 i18n="@@website.org.extraDiscount">Extra advantageous</h2>
                    <p class="description">
                        <ng-container
                            *ngIf="isSchool"
                            i18n="@@website.org.extraDiscountInfoSchool"
                        >Students who sign up through the school enjoy an extra discount. For teachers, the course and the tracking platform are 100% free!</ng-container>
                        <ng-container
                            *ngIf="!isSchool"
                            i18n="@@website.org.extraDiscountInfoBusiness"
                        >Companies can register their employees at an extra advantageous rate. For instructors, the course and the tracking platform are 100% free!</ng-container>
                    </p>
                    <ul class="checklist">
                        <ng-container *ngIf="isSchool">
                            <li>
                                <span
                                    i18n="@@website.org.extraDiscountSchool1"
                                ><strong>{{ product?.discountOnFullPrice }}% extra discount</strong> for <strong>students</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.org.extraDiscountSchool2"
                                ><strong>Always free</strong> for <strong>teachers</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.org.extraDiscountSchool3"
                                ><strong>No fixed costs</strong> for the school</span>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="!isSchool">
                            <li>
                                <span
                                    i18n="@@website.org.extraDiscountBusiness1"
                                ><strong>{{ product?.discountOnFullPrice }}% extra discount</strong> for <strong>participants</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.org.extraDiscountBusiness2"
                                ><strong>Always free</strong> for <strong>instructors</strong></span>
                            </li>
                            <li>
                                <span
                                    i18n="@@website.org.extraDiscountBusiness3"
                                ><strong>No fixed costs</strong></span>
                            </li>
                        </ng-container>
                    </ul>

                    <div class="pricing">
                        <div class="price">
                            <p class="priceNumber" [innerHTML]="product?.listPrice | formatCurrency:currency:true"></p>
                            <p class="exclIncl">
                                <ng-container *ngIf="product && product.taxIncluded" i18n="@@incl">incl.</ng-container>
                                <ng-container *ngIf="product && !product.taxIncluded" i18n="@@excl">excl.</ng-container>
                                <ng-container i18n="@@taxes">taxes</ng-container>
                            </p>
                        </div>
                        <div class="perStudent">
                            /
                            <ng-container
                                *ngIf="isSchool"
                                i18n="@@website.org.schoolStudent"
                            >student</ng-container>
                            <ng-container
                                *ngIf="!isSchool"
                                i18n="@@website.org.businessStudent"
                            >participant</ng-container>
                        </div>
                    </div>

                    <p class="featureCta">
                        <a routerLink="/{{ isSchool ? 'school' : 'business' }}-registration" mat-stroked-button color="primary" class="websiteButton">
                            <span i18n="@@website.startFree">Start for free</span>
                        </a>
                    </p>
                </div>
            </div>

            <div *ngSwitchCase="'createExercises'" class="feature createExercises">
                <div class="featureImg">
                    <img
                        ngSrc="assets/images/typingCourse/features/create_exercises.webp"
                        i18n-alt="@@website.org.createAlt"
                        alt="Exercise editor"
                        [class]="(i % 2 === 0) ? 'slideRight' : 'slideLeft'"
                        width="550"
                        height="412"
                    />
                </div>
                <div class="featureDescription" [class]="(i % 2 === 0) ? 'slideLeft' : 'slideRight'">
                    <h2 i18n="@@website.org.create">Create your own exercises</h2>
                    <p
                        class="description"
                        i18n="@@website.org.createInfo"
                    >With the easy-to-use exercise wizard you can easily create new exercises for your students.</p>
                    <ul class="checklist">
                        <li>
                            <span
                                i18n="@@website.org.create1"
                            >Easy to use <strong>exercise wizard</strong></span>
                        </li>
                        <li>
                            <span
                                i18n="@@website.org.create2"
                            >Create your own <strong>typing tests</strong> with <strong>configurable passing criteria</strong></span>
                        </li>
                        <li>
                            <span
                                i18n="@@website.org.create3"
                            >Exercises can be <strong>released by class</strong></span>
                        </li>
                        <li>
                            <span
                                i18n="@@website.org.create4"
                            >Also works for the <strong>typing games</strong></span>
                        </li>
                    </ul>

                    <p class="featureCta">
                        <a routerLink="/{{ isSchool ? 'school' : 'business' }}-registration" mat-stroked-button color="primary" class="websiteButton">
                            <span i18n="@@website.startFree">Start for free</span>
                        </a>
                    </p>
                </div>
            </div>

            <div *ngSwitchCase="'interestingExercises'" class="feature interestingExercises">
                <div class="featureImg">
                    <img
                        ngSrc="assets/images/typingCourse/features/fascinating_exercises.webp"
                        i18n-alt="@@website.org.exercisesAlt"
                        alt="Exercise text topics"
                        [class]="(i % 2 === 0) ? 'slideRight' : 'slideLeft'"
                        width="550"
                        height="412"
                    />
                </div>
                <div class="featureDescription" [class]="(i % 2 === 0) ? 'slideLeft' : 'slideRight'">
                    <h2 i18n="@@website.org.exercises">Fascinating exercise texts</h2>
                    <p
                        class="description"
                        i18n="@@website.org.exercisesInfo"
                    >At Tyyp you won't find any boring exercise texts. Your students will discover how satellites work, where fireworks come from and why giraffes have such a long neck.</p>
                    <ul class="checklist">
                        <li>
                            <span
                                i18n="@@website.org.exercises1"
                            ><strong>Cross-curricular learning</strong> while typing</span>
                        </li>
                        <li>
                            <span
                                i18n="@@website.org.exercises2"
                            >Engaging exercise texts about <strong>nature, history, culture and science</strong>.</span>
                        </li>
                        <li>
                            <span
                                i18n="@@website.org.exercises3"
                            ><strong>Hundreds of exercises</strong> available</span>
                        </li>
                        <li>
                            <span
                                i18n="@@website.org.exercises4"
                            >... or <strong>use your own practice texts</strong></span>
                        </li>
                    </ul>

                    <p class="featureCta">
                        <a routerLink="/{{ isSchool ? 'school' : 'business' }}-registration" mat-stroked-button color="primary" class="websiteButton">
                            <span i18n="@@website.startFree">Start for free</span>
                        </a>
                    </p>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<course-extra-organization-features [isSchool]="isSchool"></course-extra-organization-features>

<course-organization-question-call></course-organization-question-call>
