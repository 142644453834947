import {Injectable} from '@angular/core';
import {KnownOrganization} from '../../../models/knownOrganization';

@Injectable()
export class KnownOrganizationFactory {
    map(response) {
        return new KnownOrganization(
            response.id,
            response.organization_type_id,
            response.id_code,
            response.name,
            response.tax_number,
            response.address,
            response.city,
            response.state,
            response.postal_code,
            response.country_code
        );
    }
}
