import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {catchError, first} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {UserService} from '../../../shared/services/user.service';
import {StateService} from '../../../services/state.service';

@Component({
    selector: 'course-forgot-password',
    templateUrl: './forgotPassword.component.html',
    styleUrls: ['./forgotPassword.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    success = false;
    form = new FormGroup({
        email: new FormControl<string | null>(null),
        repeatEmail: new FormControl<string | null>(null)
    });
    errors: {[field: string]: string[]} = {};
    organizationFeatureDisabled = false;

    constructor(
        private userService: UserService,
        private router: Router,
        private stateService: StateService
    ) {}

    ngOnInit() {
        this.subscribeToActiveUser();
    }

    subscribeToActiveUser() {
        this.stateService.getActiveUserObservable().subscribe(
            (activeUser) => {
                if (activeUser) {
                    this.router.navigate(['/']);
                }
            }
        );
    }

    submit() {
        this.organizationFeatureDisabled = false;

        const email = this.form.get('email').value;
        const repeatEmail = this.form.get('repeatEmail').value;

        this.userService.resetPassword(email, repeatEmail).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 403 && err.error === 'organizationFeatureDisabled') {
                        this.organizationFeatureDisabled = true;
                    }

                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        this.form.controls.email.markAsTouched();
                        this.form.controls.email.setErrors(this.errors.email);

                        this.form.controls.repeatEmail.markAsTouched();
                        this.form.controls.repeatEmail.setErrors(this.errors.repeat_email);
                    }

                    return NEVER;
                }
            )
        ).subscribe(
            () => {
                this.success = true;
            }
        );

        return false;
    }
}

