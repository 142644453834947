import {User} from './user';

export class UserStats {
    constructor(
        public user: User,
        public chapterProgress?: {
            id: number
            title: string,
            progress: number,
            lessonProgress: {
                title: string,
                progress: number
            }[]
        }[]
    ) {
    }
}
