<ng-container *ngIf="activeOption">
    <a [matMenuTriggerFor]="languageMenu" role="navigation" class="current" [class.smallMobileMode]="smallMobileMode">
        <span class="language">
            <img
                ngSrc="assets/images/roundFlags/{{ activeOption?.countryCode.toLowerCase() }}.svg"
                [alt]="activeOption.country" class="flag"
                width="512"
                height="512"
            />
            <span class="languageCode">{{ activeOption.languageCode }}</span>
        </span>
        <mat-icon class="arrowIcon">arrow_drop_down</mat-icon>
    </a>
    <mat-menu #languageMenu="matMenu">
        <a
            *ngFor="let option of options"
            href="{{ option.url }}"
            mat-menu-item
            [class.activeLanguage]="option.url === activeOption.url"
        >
            <img
                ngSrc="assets/images/roundFlags/{{ option.countryCode.toLowerCase() }}.svg"
                [alt]="option.country" class="flag"
                width="512"
                height="512"
            />
            <span class="optionLabel">
                {{ option.country }}
                <span>/ {{ option.language }}</span>
            </span>
        </a>
    </mat-menu>
</ng-container>
