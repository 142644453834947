import {Component, OnInit} from '@angular/core';
import {Menu} from '../../../models/menu';
import {Observable} from 'rxjs';
import {MenuService} from '../../../shared/services/menu.service';
import {NavigationService} from '../../../shared/services/navigation.service';

@Component({
    selector: 'course-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    items$: Observable<Menu>;

    constructor(
        private menuService: MenuService,
        public navigationService: NavigationService
    ) {}

    ngOnInit() {
        this.items$ = this.menuService.getMenu(true);
    }
}

