<div [class.keyboardDialog]="isKeyboardDialog">
    <h3 i18n="@@course.keyboardLayout">Keyboard layout</h3>
    <p i18n="@@course.keyboardSettingsInfo">Your current keyboard layout is set to:</p>
    <p *ngIf="!isEditingLayout" class="keyboardLayout">
        <strong class="selection">{{ layoutName }}</strong>
        <a
            *ngIf="canEditCourse"
            mat-mini-fab
            color="primary"
            (click)="editCourse()"
            i18n-matTooltip="@@account.editCourse"
            matTooltip="Change your keyboard layout"
            class="editBtn"
        >
            <mat-icon>edit</mat-icon>
        </a>

        <a
            *ngIf="!canEditCourse"
            mat-mini-fab
            color="primary"
            disabled
            i18n-matTooltip="@@account.changeCourseUnavailable"
            matTooltip="Your organization does not allow students to change their course."
            class="editBtn"
        >
            <mat-icon>edit</mat-icon>
        </a>
    </p>

    <p *ngIf="isEditingLayout" class="keyboardLayout editingKeyboardLayout">
        <span class="selection">
            <mat-form-field appearance="outline">
                <mat-label i18n="@@account.selectALayout">Select your keyboard layout</mat-label>
                <mat-select [value]="'' + getCurrentCourseId()" #courseId>
                    <mat-optgroup i18n-label="@@account.coursesInYourLanguage" label="Courses in your language">
                        <mat-option *ngFor="let course of myLanguageCourses" [value]="course.id">
                            {{ course.title }}
                        </mat-option>
                    </mat-optgroup>
                    <mat-optgroup i18n-label="@@account.otherCourses" label="Other courses">
                        <mat-option *ngFor="let course of otherCourses" [value]="course.id">
                            {{ course.title }}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </span>
        <a
            mat-mini-fab
            class="confirmBtn"
            (click)="confirmChangeCourse(courseId.value)"
        >
            <mat-icon>check</mat-icon>
        </a>
        <a
            mat-mini-fab
            color="warn"
            class="cancelBtn"
            (click)="cancelChangeCourse()"
        >
            <mat-icon>close</mat-icon>
        </a>
    </p>
    <hr />
    <h3 i18n="@@course.appleTitle">Apple keyboard?</h3>
    <p i18n="@@course.apple">Apple keyboards have a slightly different layout. Turn on the toggle switch below if you're using an Apple keyboard.</p>
    <p>
        <mat-slide-toggle
            (change)="changeApple($event)"
            [checked]="isApple"
            i18n="@@course.yesApple"
        >I'm using an Apple keyboard</mat-slide-toggle>
    </p>
</div>
