import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {UserService} from '../../../../shared/services/user.service';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {OrderService} from '../../../../shared/services/order.service';
import {StateService} from '../../../../services/state.service';
import {AnalyticsService} from '../../../../services/analytics.service';
import {Order} from '../../../../models/order';

@Component({
    selector: 'course-payment-completed',
    templateUrl: './paymentCompleted.component.html',
    styleUrls: ['./paymentCompleted.component.scss'],
})
export class PaymentCompletedComponent implements OnInit {
    isPaid: boolean;
    id: number;
    code: string;
    order: Order;

    constructor(
        public userService: UserService,
        public navigationService: NavigationService,
        private analyticsService: AnalyticsService,
        private activatedRoute: ActivatedRoute,
        private orderService: OrderService,
        public stateService: StateService
    ) {}

    ngOnInit() {
        this.isPaid = this.activatedRoute.snapshot.data.isPaid;
        this.order = this.activatedRoute.snapshot.data.order;
        this.id = this.activatedRoute.snapshot.params.id;
        this.code = this.activatedRoute.snapshot.params.code;

        if (this.isPaid) {
            this.analyticsService.trackConversion('individualSale', this.order.currency.code, this.order.totalIncl);
        }
    }

    retryPayment() {
        this.orderService.retryPayment(this.id, this.code).pipe(
            first()
        ).subscribe(
            (url) => {
                // redirect to payment
                window.location.href = url;
            }
        );
    }
}

