import {Component} from '@angular/core';
import {NavigationService} from '../../../shared/services/navigation.service';

@Component({
    selector: 'course-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss'],
})
export class TermsComponent {
    constructor(
        public navigationService: NavigationService
    ) {
    }
}

