import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class StatisticService {
    constructor(
        private http: HttpClient
    ) {}

    generate(
        groupBy: string,
        year: number | string,
        month: number | string,
        countryCode: string) {
        return this.http.get<any>(
            environment.apiUrl + 'statistics/generate/' + groupBy + '/' + year + '/' + month + '/' + countryCode
        );
    }
}
