import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {NEVER, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ResultService} from '../../shared/services/result.service';

@Injectable()
export class UserProgressResolver  {
    constructor(
        private resultService: ResultService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        let userId;
        if (route.params.id) {
            userId = route.params.id;
        }

        return this.resultService.getUserProgress(
            userId
        ).pipe(
            catchError(
                (err) => {
                    return NEVER;
                }
            )
        );
    }
}
