import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {NEVER, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CountryService} from '../../shared/services/country.service';
import {AppService} from '../../shared/services/app.service';

@Injectable()
export class CountriesResolver  {
    constructor(
        private countryService: CountryService,
        private appService: AppService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        return this.countryService.getAll().pipe(
            map(
                (countries) => {
                    return countries.filter(
                        (country) => {
                            return Object.keys(this.appService.activeCountries).includes(country.code);
                        }
                    );
                }
            ),
            catchError(
                (err) => {
                    return NEVER;
                }
            )
        );
    }
}
