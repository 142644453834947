<div class="websiteSectionTitle">
    <h2 i18n="@@website.faq.title">Frequently Asked Questions</h2>
    <p i18n="@@website.faq.sub">Get an answer to all your questions.</p>
</div>

<ul class="faq" *ngIf="section === 'home'">
    <li><course-faq-question questionName="tablet"></course-faq-question></li>
    <li><course-faq-question questionName="age"></course-faq-question></li>
    <li><course-faq-question questionName="duration"></course-faq-question></li>
    <li><course-faq-question questionName="dyslexia"></course-faq-question></li>
    <li><course-faq-question questionName="limitation"></course-faq-question></li>
    <li><course-faq-question questionName="schoolNotYet"></course-faq-question></li>
</ul>

<ul class="faq" *ngIf="section === 'school'">
    <li><course-faq-question questionName="costSchool"></course-faq-question></li>
    <li><course-faq-question questionName="freeForInstructorsSchool"></course-faq-question></li>
    <li><course-faq-question questionName="whoPaysSchool"></course-faq-question></li>
    <li><course-faq-question questionName="accessSchool"></course-faq-question></li>
    <li><course-faq-question questionName="coachingSchool"></course-faq-question></li>
    <li><course-faq-question questionName="classicalSchool"></course-faq-question></li>
    <li><course-faq-question questionName="accessibilitySchool"></course-faq-question></li>
</ul>

<ul class="faq" *ngIf="section === 'business'">
    <li><course-faq-question questionName="costBusiness"></course-faq-question></li>
    <li><course-faq-question questionName="freeForInstructorsBusiness"></course-faq-question></li>
    <li><course-faq-question questionName="whoPaysBusiness"></course-faq-question></li>
    <li><course-faq-question questionName="accessBusiness"></course-faq-question></li>
    <li><course-faq-question questionName="coachingBusiness"></course-faq-question></li>
    <li><course-faq-question questionName="classicalBusiness"></course-faq-question></li>
    <li><course-faq-question questionName="accessibilityBusiness"></course-faq-question></li>
</ul>
