import {Injectable} from '@angular/core';
import {DiscountCodeFactory} from './discountCode.factory';
import {CurrencyFactory} from './currency.factory';
import {ProductFactory} from './product.factory';
import {OrganizationFactory} from './organization.factory';
import {Order} from '../../../models/order';

@Injectable()
export class OrderFactory {
    constructor(
        private discountCodeFactory: DiscountCodeFactory,
        private currencyFactory: CurrencyFactory,
        private productFactory: ProductFactory,
        private organizationFactory: OrganizationFactory
    ) {}

    map(response) {
        return new Order(
            response.id,
            response.number,
            response.number ?
                (response.credited_order_id ? 'C' : '') + response.number
                : $localize `:@@order.noNr:NoNr` + '[ID:' + response.id + ']',
            response.credited_order_id,
            response.user_id,
            response.organization_id,
            Date.parse(response.paid_at),
            Date.parse(response.licenses_released_at),
            Date.parse(response.cancelled_at),
            response.first_name,
            response.name,
            response.email,
            response.company_name,
            response.tax_number,
            response.country_code,
            response.address,
            response.postal_code,
            response.city,
            response.state,
            Date.parse(response.created_at),
            Date.parse(response.due_at),
            response.external,
            response.external_products ? JSON.parse(response.external_products).map(
                (product) => {
                    return {
                        description: product.description,
                        amount: product.amount,
                        unitPrice: product.unit_price
                    };
                }
            ) : undefined,
            response.total_incl,
            response.total_excl,
            response.tax,
            response.tax_percentage,
            response.discount,
            response.discount_code_claim ?
                this.discountCodeFactory.map(response.discount_code_claim.discount_code)
                : undefined,
            response.currency ?
                this.currencyFactory.map(response.currency)
                : undefined,
            response.products ?
                response.products.map(
                    (product) => {
                        return {
                            amount: product.pivot.amount,
                            productListPrice: product.pivot.product_list_price,
                            product: this.productFactory.map(product)
                        };
                    }
                ) : undefined,
            response.organization ? this.organizationFactory.map(response.organization) : undefined,
            !isNaN(response.licenses_with_trashed_count) ? response.licenses_with_trashed_count : undefined,
            !isNaN(response.unused_licenses_count) ? response.unused_licenses_count : undefined,
            response.credit_notes ?
                response.credit_notes.map(
                    (creditNote) => {
                        return this.map(creditNote);
                    }
                ) : undefined,
            response.credited_order ?
                this.map(response.credited_order)
                : undefined,
            response.po_number ? response.po_number : undefined,
            response.sale ? response.sale.percentage : undefined
        );
    }
}
