import {Currency} from './currency';
import {User} from './user';
import {Order} from './order';
import {Product} from './product';

export class DiscountCode {
    constructor(
        public id: number,
        public code: string,
        public amount: number,
        public createdAt: number,
        public validUntil: number,
        public maximumUses: number,
        public product?: Product,
        public currency?: Currency,
        public claimsCount?: number,
        public orders?: Order[],
        public email?: string,
        public userId?: number,
        public user?: User
    ) {}
}
