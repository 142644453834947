import {Component, Input} from '@angular/core';
import {License} from '../../../models/license';
import {Products} from '../../../models/abstract/products';

@Component({
    selector: 'course-license-table',
    templateUrl: './licenseTable.component.html',
    styleUrls: ['./licenseTable.component.scss'],
})
export class LicenseTableComponent {
    protected readonly Products = Products;
    @Input() licenses: License[];
}

