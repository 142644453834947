import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {NEVER, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UserService} from '../../shared/services/user.service';
import {OrderService} from '../../shared/services/order.service';
import {StateService} from '../state.service';

@Injectable()
export class PreviousOrderResolver  {
    constructor(
        private userService: UserService,
        private orderService: OrderService,
        private stateService: StateService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        if (this.stateService.getActiveUser()) {
            return this.orderService.getLastOrder().pipe(
                catchError(
                    (err) => {
                        return NEVER;
                    }
                )
            );
        } else {
            return null;
        }
    }
}
