export class OrganizationSettings {
    constructor(
        public organizationId: number,
        public courseId: number,
        public languageId: number,
        public allowUpdateAccountDetails: boolean,
        public allowUpdatePassword: boolean,
        public allowUpdateCourse: boolean,
        public showKeyboard: boolean,
        public allowBackspace: boolean,
        public appleKeyboard: boolean,
        public basicExamUseDefaultCriteria: boolean,
        public basicExamCpm: number,
        public basicExamCorrectRatio: number,
        public finalExamUseDefaultCriteria: boolean,
        public finalExamCpm: number,
        public finalExamCorrectRatio: number,
        public organizationPaysForLicenses: boolean
    ) {}
}
