import {Injectable} from '@angular/core';
import {LanguageService} from './language.service';
import {RouteTranslations} from '../../models/abstract/routeTranslations';

@Injectable()
export class NavigationService {
    constructor(
        private languageService: LanguageService
    ) {}

    getRouteTranslation(key: string): string {
        const language = this.languageService.getActiveLanguage();
        let languageCode = 'en';
        if (language) {
            languageCode = language.code;
        }

        return RouteTranslations[key][languageCode];
    }

    getRouteUpData(activatedRoute) {
        const up = activatedRoute.snapshot.data.up;

        if (!up) {
            return;
        }

        const route = this.parseRoute(up.route, activatedRoute);
        const icon = up.icon;

        let isMatIcon = true;
        if (icon.includes('.svg')) {
            isMatIcon = false;
        }

        return {
            route,
            icon,
            isMatIcon
        };
    }

    parseRoute(route, activatedRoute) {
        return route.replaceAll(/\{\{[a-zA-Z\.]+\}\}/g, (match) => {
            return this.replaceData(match, activatedRoute);
        });
    }

    replaceData(match, activatedRoute) {
        match = match.replace('{{', '').replace('}}', '');

        const parts = match.split('.');

        // IDEA for now only uses route params, but data would be possible (see title directive)
        let data = activatedRoute.snapshot.params;

        parts.forEach(
            (part) => {
                data = data[part];
            }
        );

        return data;
    }
}
