import {CharacterTracking} from './characterTracking';

/**
 * WARNING: DEPRECATED!
 */
export class TrackingBlock {
    constructor(
        public id?: number,
        public resultId?: number,
        public startAt?: number,
        public endAt?: number,
        public characterTracking?: CharacterTracking[]
    ) {}

    start() {
        this.startAt = Date.now();
    }

    stop() {
        this.endAt = Date.now();
    }

    addChar(characterTracking: CharacterTracking) {
        characterTracking.character = this.replaceSpecialChars(characterTracking.character);

        if (!this.characterTracking) {
            this.characterTracking = [];
        }

        this.characterTracking.push(characterTracking);
    }

    upsertChar(position: number, characterTracking: CharacterTracking) {
        characterTracking.character = this.replaceSpecialChars(characterTracking.character);

        if (!this.characterTracking) {
            this.characterTracking = [];
        }

        this.characterTracking[position] = characterTracking;
    }

    replaceSpecialChars(char) {
        if (char === ' ') {
            char = 'space';
        }

        return char;
    }
}
