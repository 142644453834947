export class KnownOrganization {
    constructor(
        public id: number,
        public organizationTypeId: number,
        public idCode: number,
        public name: string,
        public taxNumber: string,
        public address: string,
        public city: string,
        public state: string,
        public postalCode: string,
        public countryCode: string
    ) {}
}
