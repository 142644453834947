import {BadgeTypes} from './enums/badgeTypes';

export class Badge {
    constructor(
        public id: number,
        public title: string,
        public level: number,
        public position: number,
        public type: BadgeTypes,
        public quantity: number,
        public state?: {
            earned: boolean,
            progress: number
        }
    ) {}
}
