import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Class} from '../../../models/class';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import {CourseService} from '../../../shared/services/course.service';
import {LanguageService} from '../../../shared/services/language.service';
import {StateService} from '../../../services/state.service';
import {MatTabGroup} from '@angular/material/tabs';
import {NavigationService} from '../../../shared/services/navigation.service';
import {catchError, first, switchMap} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClassService} from '../../../shared/services/class.service';
import {SsoTypes} from '../../../models/enums/ssoTypes';

@Component({
    selector: 'course-self-join',
    templateUrl: './selfJoin.component.html',
    styleUrls: ['./selfJoin.component.scss'],
})
export class SelfJoinComponent implements OnInit {
    @ViewChild('tabs') tabGroup: MatTabGroup;
    success = false;
    errors: { [field: string]: string[] } = {};
    formPages = [];
    pageNames = {
        account: $localize `:@@selfJoin.pages.account:Account`,
        class: $localize `:@@selfJoin.pages.class:Class`,
        license: $localize `:@@selfJoin.pages.license:License`,
    };
    validatedPages = {
        0: false,
        1: false,
        2: false
    };
    pageIndex = 0;
    class: Class;
    form: FormGroup<{
        account: FormGroup<{
            email: FormControl<string | null>;
            firstName: FormControl<string | null>;
            name: FormControl<string | null>;
            password: FormControl<string | null>;
            repeatPassword: FormControl<string | null>;
        }>,
        class: FormGroup<{
            enableClassCode: FormControl<boolean | null>;
            selfJoinCode: FormControl<string | null>;
        }>,
        license: FormGroup<{
            enableLicenseCode: FormControl<boolean | null>;
            licenseCode: FormControl<string | null>;
            acceptTerms: FormControl<boolean | null>;
            waiveRightToWithdraw: FormControl<boolean | null>
        }>
    }>;
    ssoType: SsoTypes;
    ssoToken: string;
    hidePassword = true;
    hidePassword2 = true;
    showClassCode = false;
    showLicense = false;
    hideClassForm = false;
    missingBasicProduct = false;
    licenseAlreadyClaimed = false;


    constructor(
        private userService: UserService,
        private courseService: CourseService,
        private languageService: LanguageService,
        private activatedRoute: ActivatedRoute,
        private stateService: StateService,
        public navigationService: NavigationService,
        private snackBar: MatSnackBar,
        private classService: ClassService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.initClass();
        this.initFormPages();
        this.initForm();
        this.fillSsoData();
    }

    initClass() {
        this.class = this.activatedRoute.snapshot.data.class;

        if (this.class) {
            this.showClassCode = true;
        }
    }

    initFormPages() {
        this.hideClassForm = this.activatedRoute.snapshot.data.hideClassForm;

        this.formPages = [
            'account',
            'class',
            'license'
        ];

        if (this.hideClassForm) {
            this.formPages = [
                'account',
                'license'
            ];
        }
    }

    initForm() {
        this.form = new FormGroup({
            account: new FormGroup({
                email: new FormControl<string | null>(''),
                firstName: new FormControl<string | null>(''),
                name: new FormControl<string | null>(''),
                password: new FormControl<string | null>(''),
                repeatPassword: new FormControl<string | null>(''),
            }),
            class: new FormGroup({
                enableClassCode: new FormControl<boolean | null>(null),
                selfJoinCode: new FormControl<string | null>(this.class ? this.class.selfJoinCode : ''),
            }),
            license: new FormGroup({
                enableLicenseCode: new FormControl<boolean | null>(null),
                licenseCode: new FormControl<string | null>(''),
                acceptTerms: new FormControl<boolean | null>(false),
                waiveRightToWithdraw: new FormControl<boolean | null>(false)
            })
        });
    }

    handleSsoAccountCreationRequest() {
        this.fillSsoData();
    }

    fillSsoData() {
        const ssoData = this.stateService.getSsoAccountCreationData();

        if (ssoData && ssoData.ssoType && ssoData.token) {
            if (ssoData.firstName) {
                this.form.controls.account.controls.firstName.setValue(ssoData.firstName);
            }

            if (ssoData.name) {
                this.form.controls.account.controls.name.setValue(ssoData.name);
            }

            if (ssoData.email) {
                this.form.controls.account.controls.email.setValue(ssoData.email);
            }

            this.ssoType = ssoData.ssoType;
            this.ssoToken = ssoData.token;

            this.continue();
        }
    }

    getPrevious() {
        let previous = this.pageIndex;

        if (this.pageIndex > 0) {
            previous -= 1;
        }

        return previous;
    }

    getNext() {
        let next = this.pageIndex;

        if (this.pageIndex < 2) {
            next += 1;
        }

        return next;
    }

    back() {
        this.pageIndex = this.getPrevious();
    }

    continue() {
        this.validateAndGoTo(
            this.getNext()
        );
    }

    goTo(goToIndex: number) {
        this.pageIndex = goToIndex;
    }

    validateAndGoTo(goToIndex: number) {
        switch (this.pageIndex) {
            case 0:
                this.validateAccountDetails().subscribe(
                    () => {
                        this.validatedPages[0] = true;
                        this.pageIndex = goToIndex;
                        this.cd.detectChanges();
                    }
                );
                break;

            case 1:
                this.validateClass().subscribe(
                    () => {
                        this.validatedPages[1] = true;
                        this.pageIndex = goToIndex;
                    }
                );
                break;
        }
    }

    validateAccountDetails() {
        // mark other page fields as untouched
        this.markClassFieldsUntouched();
        this.markLicenseFieldsUntouched();


        this.form.get('account.email').setErrors(null);
        this.form.get('account.firstName').setErrors(null);
        this.form.get('account.name').setErrors(null);
        this.form.get('account.password').setErrors(null);
        this.form.get('account.repeatPassword').setErrors(null);


        // let api validate account details
        return this.userService.validateAccountDetails(
            this.form.get('account.email').value,
            this.form.get('account.firstName').value,
            this.form.get('account.name').value,
            this.form.get('account.password').value,
            this.form.get('account.repeatPassword').value,
            this.ssoType,
            this.ssoToken
        ).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.setAccountErrors(err);
                            this.showErrorSnackbar();
                        }
                    }

                    return NEVER;
                }
            )
        );
    }

    validateClass() {
        // mark other page fields as untouched
        this.markAccountFieldsUntouched();
        this.markLicenseFieldsUntouched();

        let classCode = this.form.get('class.selfJoinCode').value;
        if (!this.form.get('class.enableClassCode').value) {
            classCode = null;
        }

        // let api validate org details
        return this.classService.validateClass(
            classCode
        ).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.setClassErrors(err);
                            this.showErrorSnackbar();
                        }
                    }

                    return NEVER;
                }
            )
        );
    }

    submit() {
        this.licenseAlreadyClaimed = false;
        this.missingBasicProduct = false;

        let licenseCode = this.form.get('license.licenseCode').value;
        if (!this.form.get('license.enableLicenseCode').value) {
            licenseCode = null;
        }

        this.courseService.getDefaultCourse().pipe(
            first(),
            switchMap(
                (courseId) => {
                    return this.userService.selfJoin(
                        this.class ? this.class.selfJoinCode : this.form.get('class.selfJoinCode').value,
                        this.form.get('account.email').value,
                        this.form.get('account.firstName').value,
                        this.form.get('account.name').value,
                        this.form.get('account.password').value,
                        this.form.get('account.repeatPassword').value,
                        licenseCode,
                        this.form.get('license.acceptTerms').value,
                        this.form.get('license.waiveRightToWithdraw').value,
                        this.languageService.getActiveLanguage().id,
                        courseId,
                        this.ssoType,
                        this.ssoToken
                    );
                }
            ),
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.setAccountErrors(err);
                            this.setClassErrors(err);

                            this.showErrorSnackbar();

                            this.form.get('license.licenseCode').markAsTouched();
                            this.form.get('license.licenseCode').setErrors(err.error.errors.license_code);

                            this.form.get('license.acceptTerms').markAsTouched();
                            this.form.get('license.acceptTerms').setErrors(err.error.errors.accept_terms);

                            this.form.get('license.waiveRightToWithdraw').markAsTouched();
                            this.form.get('license.waiveRightToWithdraw').setErrors(err.error.errors.waive_right_to_withdraw);
                        }

                        if (err.error === 'licenseAlreadyClaimed') {
                            this.licenseAlreadyClaimed = true;
                        } else if (err.error === 'missingBasicProduct') {
                            this.missingBasicProduct = true;
                        }
                    }

                    return NEVER;
                }
            )
        ).subscribe(
            () => {
                this.errors = {};
                this.success = true;
            }
        );

        return false;
    }

    showErrorSnackbar() {
        this.snackBar.open(
            $localize `:@@organizationRegistration.errorsFound:Please correct the errors`,
            '',
            {
                duration: 5000,
                panelClass: ['errorSnackbar']
            }
        );
    }

    markAccountFieldsUntouched() {
        this.form.get('account.email').setErrors(null);
        this.form.get('account.firstName').setErrors(null);
        this.form.get('account.name').setErrors(null);
        this.form.get('account.password').setErrors(null);
        this.form.get('account.repeatPassword').setErrors(null);
    }

    markClassFieldsUntouched() {
        this.form.get('class.selfJoinCode').setErrors(null);
    }

    markLicenseFieldsUntouched() {
        this.form.get('license.licenseCode').setErrors(null);
        this.form.get('license.acceptTerms').setErrors(null);
        this.form.get('license.waiveRightToWithdraw').setErrors(null);
    }

    setAccountErrors(err) {
        if (
            err.error.errors.first_name
            || err.error.errors.name
            || err.error.errors.email
            || err.error.errors.password
            || err.error.errors.repeat_password
        ) {
            this.validatedPages[0] = false;
        }

        this.form.get('account.firstName').markAsTouched();
        this.form.get('account.firstName').setErrors(err.error.errors.first_name);

        this.form.get('account.name').markAsTouched();
        this.form.get('account.name').setErrors(err.error.errors.name);

        this.form.get('account.email').markAsTouched();
        this.form.get('account.email').setErrors(err.error.errors.email);

        this.form.get('account.password').markAsTouched();
        this.form.get('account.password').setErrors(err.error.errors.password);

        this.form.get('account.repeatPassword').markAsTouched();
        this.form.get('account.repeatPassword').setErrors(err.error.errors.repeat_password);
    }

    setClassErrors(err) {
        if (
            err.error.errors.self_join_code
        ) {
            this.validatedPages[1] = false;
        }

        this.form.get('class.selfJoinCode').markAsTouched();
        this.form.get('class.selfJoinCode').setErrors(err.error.errors.self_join_code);
    }

    enableDisableClassCode(value: boolean) {
        this.showClassCode = value;
    }

    enableDisableLicense(value: boolean) {
        this.showLicense = value;
    }
}

