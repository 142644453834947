import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NavigationService} from '../../services/navigation.service';

@Component({
    selector: 'course-up-icon',
    templateUrl: './upIcon.component.html',
    styleUrls: ['./upIcon.component.scss'],
})
export class UpIconComponent implements OnInit {
    route: string;
    icon: string;
    isMatIcon: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        private navigationService: NavigationService
    ) {}

    ngOnInit() {
        const data = this.navigationService.getRouteUpData(this.activatedRoute);

        if (data) {
            this.route = data.route;
            this.icon = data.icon;
            this.isMatIcon = data.isMatIcon;
        }
    }
}

