import {CharacterStates} from './enums/characterStates';

/**
 * WARNING: DEPRECATED!
 */
export class CharacterTracking {
    constructor(
        public character: string,
        public state: CharacterStates,
        public id?: number,
        public trackingBlockId?: number
    ) {}
}
