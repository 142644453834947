<div class="contactContainer">
    <div class="contactContent">
        <h1 i18n="@@nav.contact">Contact us</h1>
        <p
            i18n="@@website.contact.technicalQuestion"
        >Question about the course or platform? Find the answer in our <a routerLink="/support">help center</a>.</p>

        <ng-container *ngIf="success">
            <div class="dialog success">
                <h2 i18n="@@contact.successTitle">Message received!</h2>
                <p i18n="@@contact.success">We'll get back to you as soon as possible!</p>
            </div>

            <p class="listActions">
                <button
                    mat-flat-button
                    color="primary"
                    (click)="reset()"
                    i18n="@@contact.sendAnother"
                >Send another e-mail</button>
            </p>
        </ng-container>

        <form *ngIf="!success" [formGroup]="form" (submit)="submit()">
            <p>
                <mat-form-field appearance="outline">
                    <mat-label i18n="@@name">Name</mat-label>
                    <input matInput required formControlName="name">
                    <mat-error *ngIf="errors?.name">
                        <ng-container *ngFor="let err of errors.name">
                            {{ err }} <br>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field appearance="outline">
                    <mat-label i18n="@@email">E-mail</mat-label>
                    <input type="email" matInput required formControlName="email">
                    <mat-error *ngIf="errors?.email">
                        <ng-container *ngFor="let err of errors.email">
                            {{ err }} <br>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field appearance="outline">
                    <mat-label i18n="@@message">Message</mat-label>
                    <textarea matInput required formControlName="message" rows="7"></textarea>
                    <mat-error *ngIf="errors?.message">
                        <ng-container *ngFor="let err of errors.message">
                            {{ err }} <br>
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </p>

            <p class="alignRight">
                <button
                    type="submit"
                    class="keyboardButton orangeKeyboardButton"
                >
                    <span i18n="@@send">Send</span>
                </button>
            </p>
        </form>

        <div class="contactInfo">
            <div>
                <p>
                    <strong>Tyyp</strong>
                </p>
                <p>
                    Groendreef 101<br />
                    9160 Lokeren<br />
                    <ng-container i18n="@@website.contact.belgium">Belgium</ng-container>
                </p>
            </div>

            <div>
                <p class="emptyParagraph">&nbsp;</p>
                <p class="phone">
                    <mat-icon>call</mat-icon> +32 487 41 52 58
                </p>
                <p>
                    BE 0521.975.113<br />
                    IBAN BE82 0016 9180 3268
                </p>
            </div>
        </div>
    </div>
</div>
