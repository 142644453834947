<div class="extraFeatures">
    <div class="slideUp privacy">
        <img
            ngSrc="assets/images/typingCourse/features/privacy.svg"
            i18n-alt="@@website.org.privacyAlt"
            alt="Privacy shield"
            width="300"
            height="300"
        />
        <h2 i18n="@@website.org.privacy">Focus on privacy</h2>
        <p>
            <ng-container
                *ngIf="isSchool" i18n="@@website.org.privacyInfoSchool"
            >Your students' data is treated discreetly and securely. We are 100% GDPR compliant on data protection.</ng-container>
            <ng-container
                *ngIf="!isSchool"
                i18n="@@website.org.privacyInfoBusiness"
            >Your employees' data is treated discreetly and securely, in accordance with the GDPR data protection rules.</ng-container>
        </p>
    </div>

    <div class="slideUp noAds">
        <img
            ngSrc="assets/images/typingCourse/features/no_ads.svg"
            i18n-alt="@@website.org.noAdsAlt"
            alt="Megaphone advertisements"
            width="300"
            height="300"
        />
        <h2 i18n="@@website.org.noAds">No Ads</h2>
        <p>
            <ng-container
                *ngIf="isSchool"
                i18n="@@website.org.noAdsInfoSchool"
            >We never show ads to your students. The website and course are completely ad-free.</ng-container>
            <ng-container
                *ngIf="!isSchool"
                i18n="@@website.org.noAdsInfoBusiness"
            >We never show ads to your employees. The website and course are completely ad-free.</ng-container>
        </p>
    </div>
</div>
