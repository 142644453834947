import {Injectable, Injector} from '@angular/core';
import {ProductType} from '../../../models/productType';

@Injectable()
export class ProductTypeFactory {
    constructor(
        private injector: Injector
    ) {
    }

    map(response) {
        return new ProductType(
            response.id,
            response.code,
            response.product_category_id
        );
    }
}
