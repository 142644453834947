import {Component, Input} from '@angular/core';

@Component({
    selector: 'course-auth-form-layout',
    templateUrl: './authFormLayout.component.html',
    styleUrls: ['./authFormLayout.component.scss'],
})
export class AuthFormLayoutComponent {
    @Input() hideIllustration = false;
}

