import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Product} from '../../../models/product';
import {ModalService} from '../../../shared/services/modal.service';
import {AppService} from '../../../shared/services/app.service';
import {first} from 'rxjs/operators';
import {ProductService} from '../../../shared/services/product.service';
import {StateService} from '../../../services/state.service';

@Component({
    selector: 'course-for-organizations',
    templateUrl: './forOrganizations.component.html',
    styleUrls: ['./forOrganizations.component.scss'],
})
export class ForOrganizationsComponent implements OnInit, AfterViewChecked {
    isSchool: boolean;
    product: Product;

    constructor(
        private modalService: ModalService,
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private productService: ProductService,
        public stateService: StateService
    ) {}

    ngOnInit() {
        this.isSchool = this.activatedRoute.snapshot.data.isSchool;

        this.productService.getBasicProducts().pipe(
            first()
        ).subscribe(
            (products: {school: Product, business: Product}) => {
                if (this.isSchool) {
                    this.product = products.school;
                } else {
                    this.product = products.business;
                }
            }
        );

        // test: scroll to an element
        // window.setTimeout(
        //     () => {
        //         // document.getElementsByClassName('extraDiscount')[0].scrollIntoView(true);
        //         // document.getElementsByTagName('course-faq')[0].scrollIntoView(true);
        //     }, 100
        // );
        // end test
    }

    ngAfterViewChecked() {
        this.appService.initAnimations('.organizationHero .slideLeft, .organizationHero .slideRight', 0);
    }

    openBookADemo() {
        this.modalService.setModal('bookADemo', {type: this.isSchool ? 'school' : 'business'});
    }
}

