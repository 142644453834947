import {Component} from '@angular/core';
import {NavigationService} from '../../../shared/services/navigation.service';

@Component({
    selector: 'course-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent {
    constructor(
        public navigationService: NavigationService
    ) {}
}

