import {Component} from '@angular/core';

@Component({
    selector: 'course-page-not-found',
    templateUrl: './pageNotFound.component.html',
    styleUrls: ['./pageNotFound.component.scss'],
})
export class PageNotFoundComponent {

}

