import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError, timer} from 'rxjs';
import {catchError, finalize, mergeMap, retryWhen} from 'rxjs/operators';
import {AppService} from '../../shared/services/app.service';
import {environment} from '../../../environments/environment';


@Injectable()
export class RetryOrFailInterceptor {
    retryDelay = 2000;
    retryMaxAttempts = 3;

    constructor(
        private appService: AppService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // skip this interceptor flag (header)?
        if (request.headers.get('SkipRetryOrFail') === '1') {
            const headers = request.headers.delete('SkipRetryOrFail');
            const newRequest = request.clone({headers});
            return next.handle(newRequest);
        }

        // do intercept...
        const isBlocking = request.method.toLowerCase() !== 'get';
        let showLoader = true;
        [
            'products/basic_products',
            'countries/localized_list/'
        ].forEach(
            (urlPart) => {
                if (request.url.startsWith(environment.apiUrl + urlPart)) {
                    showLoader = false;
                }
            }
        );

        this.appService.startLoading(request.urlWithParams, isBlocking, showLoader);

        return next.handle(request).pipe(
            retryWhen(errors => {
                return errors.pipe(
                    mergeMap((err, count) => {
                        /*
                        Throw error when we've retried ${retryMaxAttempts} number of times and still get an error
                        OR if the error status code indicates an error that can not be recovered by retrying
                        (f.e. 404 -> will not exist even if you retry 100 times)
                         */
                        if (count === this.retryMaxAttempts
                            || err.status === 400
                            || err.status === 401
                            || err.status === 403
                            || err.status === 404
                            || err.status === 405
                            || err.status === 422
                            || err.status === 500
                        ) {
                            return throwError(err);
                        }

                        return of(err).pipe(
                            mergeMap(() => timer(this.retryDelay))
                        );
                    })
                );
            }),
            catchError(
                (err) => {
                    // console.log('LOADING ERROR!', err, request);
                    this.appService.setLoadingError();

                    throw err;
                }
            ),
            finalize(
                () => {
                    this.appService.stopLoading(request.urlWithParams);
                }
            )
        );
    }
}
