import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class WebsiteService {
    constructor(
        private http: HttpClient
    ) {}

    getAboutStats() {
        return this.http.get<any>(
            environment.apiUrl + 'website/about_stats'
        );
    }
}
