<ng-container [ngSwitch]="(modal | async)?.name">
    <course-cookie-consent-dialog
        *ngSwitchCase="'cookieConsent'"
        [showSettings]="(modal | async).params?.showSettings"
        [canClose]="(modal | async).params?.canClose"
    ></course-cookie-consent-dialog>

    <course-book-a-demo
        *ngSwitchCase="'bookADemo'"
        [type]="(modal | async).params?.type"
    ></course-book-a-demo>
</ng-container>
