<div class="container">
    <div class="spacer"></div>

    <div class="checkoutForm">
        <div class="checkoutFormHolder">
            <h1 class="formLogo">
                <a routerLink="/">
                    <img
                        ngSrc="assets/images/layout/logo.svg"
                        i18n-alt="@@website.logoAlt"
                        alt="Tyyp logo"
                        width="285.42"
                        height="80"
                    />
                </a>
            </h1>

            <form [formGroup]="form" (submit)="submitPurchase()">
                <h2 i18n="@@purchase.contactInfo">Contact info</h2>
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@email">E-mail</mat-label>
                        <input type="email" matInput required formControlName="email" autocomplete="email">
                        <mat-error *ngIf="errors?.email">
                            <ng-container *ngFor="let err of errors.email">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <h2 i18n="@@purchase.billingInfo">Billing info</h2>
                <p class="formRow">
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@firstName">First name</mat-label>
                        <input type="text" matInput required formControlName="firstName" autocomplete="given-name">
                        <mat-error *ngIf="errors?.first_name">
                            <ng-container *ngFor="let err of errors.first_name">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@name">Name</mat-label>
                        <input type="text" matInput required formControlName="name" autocomplete="family-name">
                        <mat-error *ngIf="errors?.name">
                            <ng-container *ngFor="let err of errors.name">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <p class="formRow">
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@purchase.companyName">Company name</mat-label>
                        <input type="text" matInput formControlName="companyName" (change)="companyChanges()" autocomplete="off-disabled">
                        <mat-hint i18n="@@optional">Optional</mat-hint>
                        <mat-error *ngIf="errors?.company_name">
                            <ng-container *ngFor="let err of errors.company_name">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@purchase.taxNumber">Tax number</mat-label>
                        <input type="text" matInput formControlName="taxNumber" (change)="taxNumberChanges()" autocomplete="off-disabled">
                        <mat-hint i18n="@@optional">Optional</mat-hint>
                        <mat-error *ngIf="errors?.tax_number">
                            <ng-container *ngFor="let err of errors.tax_number">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>


                <p>
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@country">Country</mat-label>
                        <mat-select required formControlName="countryCode" (selectionChange)="countryChanges()">
                            <mat-option *ngFor="let country of countries" [value]="country.code">{{ country.name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="errors?.country_code">
                            <ng-container *ngFor="let err of errors.country_code">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@address">Address</mat-label>
                        <input type="text" matInput required formControlName="address" autocomplete="street-address">
                        <mat-error *ngIf="errors?.address">
                            <ng-container *ngFor="let err of errors.address">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <p class="formRow">
                    <span id="stateField" [class.invisible]="!countryService.countryHasStates(form.get('countryCode').value)">
                        <mat-form-field appearance="outline">
                            <mat-label i18n="@@stateProvince">State / province</mat-label>
                            <input type="text" matInput formControlName="state" autocomplete="on">
                            <mat-hint>Optional</mat-hint>
                            <mat-error *ngIf="errors?.state">
                                <ng-container *ngFor="let err of errors.state">
                                    {{ err }} <br>
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </span>

                    <span id="postalCodeField">
                        <mat-form-field appearance="outline">
                            <mat-label i18n="@@postalCode">Postal code</mat-label>
                            <input type="text" matInput required formControlName="postalCode" autocomplete="postal-code">
                            <mat-error *ngIf="errors?.postal_code">
                                <ng-container *ngFor="let err of errors.postal_code">
                                    {{ err }} <br>
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </span>

                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@city">City</mat-label>
                        <input type="text" matInput required formControlName="city" autocomplete="locality">
                        <mat-error *ngIf="errors?.city">
                            <ng-container *ngFor="let err of errors.city">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <p>
                    <mat-checkbox
                        required
                        formControlName="acceptTerms"
                        i18n="@@acceptTerms"
                    >I have read and accept the <a routerLink="/terms" target="_blank">Terms of Service</a> and <a routerLink="/privacy" target="_blank">Privacy Policy</a>.</mat-checkbox>
                    <mat-error *ngIf="errors?.accept_terms">
                        <ng-container *ngFor="let err of errors.accept_terms">
                            {{ err }} <br>
                        </ng-container>
                    </mat-error>
                </p>

                <p>
                    <button
                        type="submit"
                        mat-flat-button
                        color="primary"
                        class="largeButton"
                    >
                        <span>
                            <ng-container i18n="@@purchase.continueToPayment">Continue to payment</ng-container>
                            <mat-icon class="buttonRightIcon">east</mat-icon>
                        </span>
                    </button>
                </p>
            </form>
        </div>
    </div>

    <div class="shoppingCart">
        <div class="shoppingCartHolder">
            <h2 i18n="@@purchase.orderOverview">Order overview</h2>
            <div class="orderList">
                <img
                    ngSrc="assets/images/shop/{{ mainProduct.image }}.webp"
                    alt="{{ Products[mainProduct.title] }}"
                    class="productImage"
                    width="500"
                    height="412"
                />

                <div class="productInfo">
                    <p class="productName">
                        {{ Products[mainProduct.title] }}
                    </p>
                    <p i18n="@@purchase.digitalLicenseCode">Digital license code</p>
                    <p *ngIf="mainProduct.discountOnFullPrice > 0" class="discountNotice">
                        <mat-icon>discount</mat-icon>
                        <ng-container
                            i18n="@@purchase.salePercentageOff"
                        >Sale: {{ mainProduct.discountOnFullPrice }}% off</ng-container>
                    </p>
                </div>

                <form [formGroup]="quantityForm">
                    <mat-form-field [class.invisible]="quantityForm.get('quantitySelect').value === '5+'" appearance="outline" class="quantityInput">
                        <mat-select formControlName="quantitySelect" (selectionChange)="selectQuantity($event)">
                            <mat-option *ngFor="let quantity of quantities" [value]="quantity">{{ quantity }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field [class.invisible]="quantityForm.get('quantitySelect').value !== '5+'" appearance="outline" class="quantityInput">
                        <input
                            type="number"
                            min="1"
                            max="{{ maxLogins }}"
                            matInput
                            formControlName="quantityInput"
                            (change)="changeQuantityInput(quantityForm.get('quantityInput').value)"
                            #quantityInput
                        >
                    </mat-form-field>
                </form>

                <span class="subtotal">
                    <ng-container *ngIf="mainProduct.discountOnFullPrice">
                        <span class="before">{{ getFullPrice() | formatCurrency:currency }}</span><br />
                    </ng-container>
                    <span class="now">{{ getMainListPrice() | formatCurrency:currency }}</span>
                </span>
            </div>

            <ng-container *ngIf="comboDealProduct">
                <hr />
                <course-purchase-upgrade-to-full
                    [basicProduct]="mainProduct"
                    [upgradeProduct]="upgradeProduct"
                    [comboDealProduct]="comboDealProduct"
                    [quantity]="quantity"
                    (toggleUpgrade)="toggleFullVersion($event)"
                ></course-purchase-upgrade-to-full>
            </ng-container>

            <hr />

            <form
                *ngIf="!currentDiscountCode"
                [formGroup]="discountForm"
                (submit)="submitDiscountCode()"
                class="discountCode"
                [class.discountCodeError]="discountForm.get('discountCode').errors"
            >
                <mat-form-field appearance="fill">
                    <mat-label i18n="@@purchase.discountCode">Discount code</mat-label>
                    <input matInput formControlName="discountCode">
                    <mat-error *ngIf="errors?.discount_code">
                        <ng-container *ngFor="let err of errors.discount_code">
                            {{ DiscountCodeErrors[err] }} <br>
                        </ng-container>
                    </mat-error>
                </mat-form-field>

                <button
                    type="submit"
                    mat-flat-button
                    color="primary"
                    [disabled]="discountForm.get('discountCode').value === ''"
                    i18n="@@apply"
                >Apply</button>
            </form>

            <div *ngIf="currentDiscountCode" class="activeDiscountCode">
                <span class="activeCode">
                    {{ currentDiscountCode.code}}

                    <a (click)="clearDiscountCode()">
                        <mat-icon>close</mat-icon>
                    </a>
                </span>

                <span class="codeValue">
                    <ng-container *ngIf="currentDiscountCode.amount >= getMainListPrice()">
                        {{ -getProductTotal() | formatCurrency:currency }}
                    </ng-container>
                    <ng-container *ngIf="currentDiscountCode.amount < getMainListPrice()">
                        {{ -currentDiscountCode.amount | formatCurrency:currency }}
                    </ng-container>
                </span>
            </div>

            <hr />

            <div class="total">
                <span
                    class="totalLabel"
                    i18n="@@total"
                >Total</span>

                <span class="totalSum">
                    <span class="totalValue">{{ getTotal() | formatCurrency:currency }}</span>
                    <br />
                    <ng-container i18n="@@incl">incl.</ng-container>
                    <ng-container i18n="@@taxes">taxes</ng-container>
                </span>
            </div>
        </div>
    </div>

    <div class="spacer"></div>

    <h1 class="mobileLogo">
        <a routerLink="/">
            <img
                ngSrc="assets/images/layout/logo.svg"
                i18n-alt="@@website.logoAlt"
                alt="Tyyp logo"
                width="285.42"
                height="80"
            />
        </a>
    </h1>
</div>
