import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {NEVER, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {OrderService} from '../../shared/services/order.service';

@Injectable()
export class RestoreOrderResolver  {
    constructor(
        private orderService: OrderService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        const id = route.params.id;
        const code = route.params.code;

        return this.orderService.getOrder(id, code).pipe(
            catchError(
                (err) => {
                    return NEVER;
                }
            )
        );
    }
}
