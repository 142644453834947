import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';

export class PersonalSettings {
    constructor(
        private settings: {[key: string]: BehaviorSubject<any>} =  {
            allowUpdateAccountDetails: new BehaviorSubject<boolean>(true),
            allowUpdatePassword: new BehaviorSubject<boolean>(true),
            allowUpdateCourse: new BehaviorSubject<boolean>(true),
            showKeyboard: new BehaviorSubject<boolean>(true),
            allowBackspace: new BehaviorSubject<boolean>(true),
            appleKeyboard: new BehaviorSubject<boolean>(false),
            soundEffects: new BehaviorSubject<boolean>(true),
            gameSoundEffects: new BehaviorSubject<boolean>(true),
            gameMusic: new BehaviorSubject<boolean>(true),
            largeText: new BehaviorSubject<boolean>(false),
            highContrast: new BehaviorSubject<boolean>(false),
            closedCaptions: new BehaviorSubject<boolean>(false),
            disableGames: new BehaviorSubject<boolean>(false)
        }
    ) {}

    setSetting(field, value, updateLocalStorage = false) {
        if (updateLocalStorage) {
            let lsValue = value;

            // boolean to string
            if (lsValue === true) {
                lsValue = '1';
            } else if (lsValue === false) {
                lsValue = '';
            }

            localStorage.setItem(field, lsValue);
        }

        this.settings[field].next(value);
    }

    getSetting(field) {
        return this.settings[field].pipe(
            map(
                (value) => {
                    // if a localStorage value exists, it overwrites the (possible) default value
                    let lsValue: any = localStorage.getItem(field);

                    if (lsValue !== null) {
                        if (value === true || value === false) {
                            // we expect a boolean, so return a boolean (localStorage = string)
                            lsValue = lsValue === '1';
                        }
                        return lsValue;
                    }

                    return value;
                }
            )
        );
    }
}
