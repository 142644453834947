import {Exercise} from './exercise';
import {User} from './user';
import {Mistake} from './mistake';
import {TrackingBlock} from './trackingBlock';

export class Result {
    constructor(
        public exerciseId: number,
        public createdAt: number,
        public data: string,
        public duration?: number,
        public id?: number,
        public userId?: number,
        public cpm?: number,
        public correctRatio?: number,
        public stars?: number,
        public completed?: boolean,
        public exercise?: Exercise,
        public user?: User,
        public score?: number,
        public mistakes?: Mistake[],
        public trackingBlocks?: TrackingBlock[]
    ) {}
}
