import {Injectable, Injector} from '@angular/core';
import {Badge} from '../../../models/badge';

@Injectable()
export class BadgeFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        return new Badge(
            response.id,
            response.title,
            response.level,
            response.position,
            response.type,
            response.quantity,
            response.state ? {
                earned: response.state.earned,
                progress: response.state.progress
            } : undefined
        );
    }
}
