import {AfterViewChecked, Component, Input} from '@angular/core';
import {AppService} from '../../../../../shared/services/app.service';

@Component({
    selector: 'course-extra-organization-features',
    templateUrl: './extraFeatures.component.html',
    styleUrls: ['./extraFeatures.component.scss'],
})
export class ExtraOrganizationFeaturesComponent implements AfterViewChecked {
    @Input() isSchool: boolean;

    constructor(
        private appService: AppService
    ) {}

    ngAfterViewChecked() {
        this.appService.initAnimations('course-extra-organization-features .slideUp');
    }
}

