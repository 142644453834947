<div class="organizationHero">
    <img
        ngSrc="assets/images/typingCourse/school.svg"
        i18n-alt="@@website.shop.schoolAlt"
        alt="Student and teacher at school"
        class="heroImg"
        [class.slideRight]="stateService.getFirstHeroAnimationSkipped()"
        width="556.21"
        height="429.59"
        priority="priority"
    />
    <div
        class="heroDescription"
        [class.slideLeft]="stateService.getFirstHeroAnimationSkipped()"
    >
        <h1>
            <ng-container
                i18n="@@website.school.title"
            >The typing course for <strong>your school</strong></ng-container>
        </h1>
        <p>
            <ng-container
                i18n="@@website.shop.schoolInfo"
            >Enjoy extra favourable rates and use the follow-up platform for free! This makes it really easy to follow up your pupils.</ng-container>
        </p>
        <course-school-features [product]="product"></course-school-features>

        <p class="startBtn">
            <a routerLink="/school-registration" class="keyboardButton">
                <span i18n="@@website.startFree">Start for free</span>
            </a>

            <a (click)="openBookADemo()" class="keyboardButton blueKeyboardButton">
                <span i18n="@@website.bookADemo">Book a demo</span>
            </a>
        </p>
    </div>
</div>

<course-organization-features [isSchool]="isSchool" [product]="product"></course-organization-features>

<course-how-does-it-work [isSchool]="isSchool"></course-how-does-it-work>

<course-features [parentPage]="isSchool ? 'school' : 'business'"></course-features>

<course-faq [section]="isSchool ? 'school' :'business'"></course-faq>

<course-organization-question-call></course-organization-question-call>
