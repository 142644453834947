import {Injectable} from '@angular/core';
import {KeyboardLayoutMap} from '../../models/keyboardLayouts/keyboardLayoutMap';
import {SpecialKeys} from '../../models/enums/specialKeys';
import {Key} from '../../models/key';
import {DeadKey} from '../../models/keyboardLayouts/deadKeys';

@Injectable()
export class CharacterKeysService {
    getCharacterKeys(keyboardLayoutMap: KeyboardLayoutMap, character: string): Key[][] {
        if (!character) {
            return [];
        }

        // prepare a string with all the literal chars on the keyboard
        const allChars = keyboardLayoutMap.keyRows.map(
            (row) => {
                return row.map(
                    (key) => {
                        return key.chars.join('');
                    }
                ).join('');
            }
        )
            .join('')
            .replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'); // escape regex special chars

        const allCharsRegex = new RegExp('[' + allChars + ']');

        // literal char
        if (character.length === 1 && character.match(allCharsRegex)) {
            const key = this.findCharacterKey(keyboardLayoutMap, character);
            const keys = [key];

            this.addModifier(key, character, keys, keyboardLayoutMap);

            return [keys];
        }

        // uppercase letter?
        if (character.length === 1 && character.match(/[A-Z]/)) {
            const lowercaseKey = this.findCharacterKey(keyboardLayoutMap, character.toLowerCase());

            // shift on opposite side
            const shiftKey = this.getOppositeShift(lowercaseKey);

            return [
                [
                    lowercaseKey,
                    this.findSpecialKey(keyboardLayoutMap, shiftKey)
                ]
            ];
        }

        // special keys
        switch (character) {
            case ' ':
                return [
                    [
                        this.findSpecialKey(keyboardLayoutMap, SpecialKeys.SpaceBar)
                    ]
                ];

            case '↲':
                return [
                    [
                        this.findSpecialKey(keyboardLayoutMap, SpecialKeys.Enter)
                    ]
                ];

            case '⭲':
                return [
                    [
                        this.findSpecialKey(keyboardLayoutMap, SpecialKeys.Tab)
                    ]
                ];
        }

        // compound keys
        if (['ê', 'ë', 'î', 'ï'].includes(character)) {
            let compoundKey;
            let finalKey;
            let compoundCharacter;

            // compound key
            if (['ê', 'î'].includes(character)) {
                compoundCharacter = '^';
                compoundKey = this.findCompoundKey(keyboardLayoutMap, '^');
            } else if (['ë', 'ï'].includes(character)) {
                compoundCharacter = '¨';
                compoundKey = this.findCompoundKey(keyboardLayoutMap, '¨');
            }

            // final (character) key
            if (['ê', 'ë'].includes(character)) {
                finalKey = this.findCharacterKey(keyboardLayoutMap, 'e');
            } else if (['î', 'ï'].includes(character)) {
                finalKey = this.findCharacterKey(keyboardLayoutMap, 'i');
            }

            if (compoundKey) {
                const keys = [compoundKey];
                this.addModifier(compoundKey, compoundCharacter, keys, keyboardLayoutMap);

                return [
                    keys,
                    [finalKey]
                ];
            }
        }
    }

    addModifier(key, character, keys, keyboardLayoutMap) {
        switch (key.chars.indexOf(character)) {
            case 0:
                // add a shift
                const shiftKey = this.getOppositeShift(key);
                keys.push(this.findSpecialKey(keyboardLayoutMap, shiftKey));
                break;

            case 2:
                if (keyboardLayoutMap.isApple) {
                    // add an Option
                    const optionKey = this.getOppositeOption(key);
                    keys.push(this.findSpecialKey(keyboardLayoutMap, optionKey));
                } else {
                    // add an AltGr
                    const altGrKey = SpecialKeys.AltGr;
                    keys.push(this.findSpecialKey(keyboardLayoutMap, altGrKey));
                }
                break;
        }
    }

    getOppositeShift(key) {
        let shiftKey = SpecialKeys.LShift;
        if (key.finger < 6) {
            shiftKey = SpecialKeys.RShift;
        }

        return shiftKey;
    }

    getOppositeOption(key) {
        let optionKey = SpecialKeys.LOption;
        if (key.finger < 6) {
            optionKey = SpecialKeys.ROption;
        }

        return optionKey;
    }

    findCharacterKey(keyboardLayout: KeyboardLayoutMap, character: string): Key {
        let characterKey;

        keyboardLayout.keyRows.some(
            (rowKeys, row) => {
                return rowKeys.some(
                    (key, column) => {
                        return key.chars.some(
                            (char) => {
                                if (char === character) {
                                    characterKey = key;
                                    return true;
                                }
                            }
                        );
                    }
                );
            }
        );

        return characterKey;
    }

    findSpecialKey(keyboardLayout: KeyboardLayoutMap, special: SpecialKeys): Key {
        let specialKey;

        keyboardLayout.keyRows.some(
            (rowKeys, row) => {
                return rowKeys.some(
                    (key, column) => {
                        if (key.specialKey && key.specialKey === special) {
                            specialKey = key;
                            return true;
                        }
                    }
                );
            }
        );

        return specialKey;
    }

    findCompoundKey(keyboardLayout: KeyboardLayoutMap, compound: string): Key {
        let compoundKey;

        keyboardLayout.keyRows.some(
            (rowKeys, row) => {
                return rowKeys.some(
                    (key, column) => {
                        if (key.compoundFor && key.compoundFor.includes(compound)) {
                            compoundKey = key;
                            return true;
                        }
                    }
                );
            }
        );

        return compoundKey;
    }

    checkDeadKey(keyboardLayout: KeyboardLayoutMap, evt: KeyboardEvent) {
        const hasShift = evt.shiftKey;
        return keyboardLayout.deadKeys.filter(
            key => key.code === evt.code && key.shift === hasShift
        )[0];
    }

    modifyWithDeadKey(pressedKey: string, deadKey: DeadKey) {
        const replacement = deadKey.replacements[pressedKey];

        if (replacement) {
            return replacement;
        }

        return pressedKey;
    }
}
