export enum SpecialKeys {
    BackSpace = 'backSpace',
    Tab = 'tab',
    Enter = 'enter',
    CapsLock = 'capsLock',
    LShift = 'lShift',
    RShift = 'rShift',
    LCtrl = 'lCtrl',
    RCtrl = 'rCtrl',
    LControl = 'lControl',
    RControl = 'rControl',
    LOption = 'lOption',
    ROption = 'rOption',
    LCommand = 'lCommand',
    RCommand = 'rCommand',
    LMeta = 'lMeta',
    RMeta = 'rMeta',
    Alt = 'alt',
    AltGr = 'altGr',
    SpaceBar = 'spaceBar'
}
