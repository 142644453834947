import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {first} from 'rxjs/operators';
import {UserService} from '../../../shared/services/user.service';
import {NavigationService} from '../../../shared/services/navigation.service';

@Component({
    selector: 'course-logout',
    template: ``
})
export class LogoutComponent implements OnInit {
    constructor(
        private userService: UserService,
        private router: Router,
        private snackBar: MatSnackBar,
        public navigationService: NavigationService
    ) {
    }

    ngOnInit() {
        this.userService.logout().pipe(
            first()
        ).subscribe(
            () => {
                this.router.navigate(['/']);
            }
        );
    }
}

