import {SpecialKeys} from './enums/specialKeys';
import {KeyboardZones} from './enums/keyboardZones';

export class Key {
    public x: number;
    public y: number;
    public width: number;
    public height: number;

    constructor(
        public code: string,
        public finger: number,
        public chars: string[],
        public zone: KeyboardZones,
        public specialKey?: SpecialKeys,
        public widthAdjust?: number,
        public isBigEnter: boolean = false,
        public homeBase: boolean = false,
        public compoundFor?: string[]
    ) {}
}
