import {Injectable, Injector} from '@angular/core';
import {NEVER, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppService} from './app.service';
import {environment} from '../../../environments/environment';
import {catchError, first, map, switchMap, tap} from 'rxjs/operators';
import {ClassFactory} from './factories/class.factory';
import {User} from '../../models/user';
import {UserFactory} from './factories/user.factory';
import {OrganizationService} from './organization.service';
import {OrderService} from './order.service';
import {BadgeService} from './badge.service';
import {BadgeFactory} from './factories/badge.factory';
import {DownloadService} from './download.service';
import {StateService} from '../../services/state.service';
import {CourseService} from './course.service';
import {LanguageFactory} from './factories/language.factory';
import {SsoTypes} from '../../models/enums/ssoTypes';


@Injectable()
export class UserService {
    constructor(
        private http: HttpClient,
        private injector: Injector,
        private appService: AppService,
        private userFactory: UserFactory,
        private organizationService: OrganizationService,
        private orderService: OrderService,
        private badgeService: BadgeService,
        private badgeFactory: BadgeFactory,
        private downloadService: DownloadService,
        private stateService: StateService
    ) {
    }

    getOne(id: number) {
        return this.http.get<any>(environment.apiUrl + 'users/' + id).pipe(
            map(
                (response) => {
                    return this.userFactory.map(response);
                }
            )
        );
    }

    update(
        id: number,
        firstName: string,
        name: string,
        email: string,
        roleId?: number,
        classId?: number,
        username?: string,
        courseId?: number,
        languageId?: number,
        optIn?: boolean,
        appleKeyboard?: boolean,
        soundEffects?: boolean,
        gameSoundEffects?: boolean,
        gameMusic?: boolean,
        gameSpeed?: number,
        gameSpeedAutomatic?: boolean
    ) {
        return this.http.patch<any>(environment.apiUrl + 'users/' + id, {
            first_name: firstName,
            name,
            email,
            role_id: roleId,
            group_id: classId,
            username,
            course_id: courseId,
            language_id: languageId,
            opt_in: optIn,
            apple_keyboard: appleKeyboard,
            sound_effects: soundEffects,
            game_sound_effects: gameSoundEffects,
            game_music: gameMusic,
            game_speed: gameSpeed,
            game_speed_automatic: gameSpeedAutomatic
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    updatePersonalGameSpeedByHearts(heartsRemaining: number) {
        // not logged in?
        if (!this.stateService.getActiveUser()) {
            return of(null);
        }

        // user disabled automatic game speed?
        if (!this.stateService.getActiveUser().gameSpeedAutomatic) {
            return of(null);
        }

        let gameSpeed = this.stateService.getActiveUser().gameSpeed;

        switch (heartsRemaining) {
            case 0:
                gameSpeed -= 0.2;
                break;
            case 1:
                gameSpeed -= 0.15;
                break;
            case 2:
                gameSpeed -= 0.1;
                break;
            case 3:
                gameSpeed -= 0.05;
                break;

            case 4:
                // neutral
                break;

            case 5:
                gameSpeed += 0.1;
                break;
        }

        return this.updatePersonalGameSpeed(gameSpeed);
    }

    updatePersonalGameSpeed(gameSpeed: number) {
        if (gameSpeed < 0.5) {
            gameSpeed = 0.5;
        }

        if (gameSpeed > 1.3) {
            gameSpeed = 1.3;
        }

        if (gameSpeed !== this.stateService.getActiveUser().gameSpeed) {
            return this.http.patch<any>(environment.apiUrl + 'users/game_speed', {
                game_speed: gameSpeed
            }).pipe(
                tap(
                    () => {
                        this.stateService.getActiveUser().gameSpeed = gameSpeed;
                    }
                ),
                catchError(
                    (err) => {
                        if (err.status === 422) {
                            this.appService.clearLoadingError();
                        }

                        throw err;
                    }
                )
            );
        } else {
            return of(null);
        }
    }

    changePassword(current, newPassword, validation) {
        return this.http.patch<any>(environment.apiUrl + 'users/change_password', {
            current,
            new_password: newPassword,
            validation
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    updatePreferences(preferences: { [field: string]: boolean }) {
        let obs = of(null);
        const activeUser = this.stateService.getActiveUser();
        if (activeUser) {
            // logged in -> send to server
            obs = this.http.patch<any>(environment.apiUrl + 'users/change_preferences', {preferences}).pipe(
                catchError(
                    (err) => {
                        if (err.status === 422) {
                            this.appService.clearLoadingError();
                        }

                        throw err;
                    }
                ),
                tap(
                    () => {
                        // update active user
                        for (const field of Object.keys(preferences)) {
                            activeUser[field] = preferences[field];
                        }

                        this.stateService.setActiveUser(activeUser);
                    }
                )
            );
        }

        return obs.pipe(
            tap(
                () => {
                    for (const field of Object.keys(preferences)) {
                        this.stateService.personalSettings.setSetting(field, preferences[field], true);
                    }
                }
            )
        );
    }

    updateUserPreferences(id: number, preferences: { [field: string]: boolean }) {
        return this.http.patch<any>(environment.apiUrl + 'users/change_user_preferences', {
            id,
            preferences
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    updateGameSpeedAutomatic(value: boolean) {
        return this.http.patch<any>(environment.apiUrl + 'users/change_preference', {
            field: 'gameSpeedAutomatic',
            value
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            ),
            tap(
                () => {
                    // update active user
                    const activeUser = this.stateService.getActiveUser();
                    activeUser.gameSpeedAutomatic = value;

                    this.stateService.setActiveUser(activeUser);
                }
            )
        );
    }

    updatePreference(field: string, value: boolean) {
        if (![
            'appleKeyboard',
            'soundEffects',
            'gameSoundEffects',
            'gameMusic',
            'largeText',
            'highContrast',
            'closedCaptions',
            'disableGames'
        ].includes(field)) {
            return NEVER;
        }

        let obs = of(null);
        const activeUser = this.stateService.getActiveUser();
        if (activeUser) {
            // logged in -> send to server
            obs = this.http.patch<any>(environment.apiUrl + 'users/change_preference', {
                field,
                value
            }).pipe(
                catchError(
                    (err) => {
                        if (err.status === 422) {
                            this.appService.clearLoadingError();
                        }

                        throw err;
                    }
                ),
                tap(
                    () => {
                        // update active user
                        activeUser[field] = value;

                        this.stateService.setActiveUser(activeUser);
                    }
                )
            );
        }

        return obs.pipe(
            tap(
                () => {
                    this.stateService.personalSettings.setSetting(field, value, true);
                }
            )
        );
    }

    updateCourse(
        courseId: number
    ) {
        return this.http.patch<any>(environment.apiUrl + 'users/change_course', {
            course_id: courseId
        });
    }

    deleteMe() {
        return this.http.delete(environment.apiUrl + 'users/me');
    }

    completeLogout() {
        this.stateService.setActiveUser(undefined);
        this.stateService.setActiveOrganization(undefined);
        localStorage.removeItem('auth.accessToken');
        localStorage.removeItem('auth.refreshToken');
        this.appService.clearActiveTabs();
        this.stateService.setCartItems(0);
    }

    selfJoin(
        selfJoinCode: string,
        email: string,
        firstName: string,
        name: string,
        password: string,
        repeatPassword: string,
        licenseCode: string,
        acceptTerms: boolean,
        waiveRightToWithdraw: boolean,
        languageId: number,
        courseId: number,
        ssoType?: string,
        ssoToken?: string
    ) {
        return this.http.post<any>(environment.apiUrl + 'users/self_join', {
            self_join_code: selfJoinCode,
            email,
            first_name: firstName,
            name,
            password,
            repeat_password: repeatPassword,
            license_code: licenseCode,
            accept_terms: acceptTerms,
            waive_right_to_withdraw: waiveRightToWithdraw,
            language_id: languageId,
            course_id: courseId,
            sso_type: ssoType,
            sso_token: ssoToken
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    activateLicenseLoggedIn(licenseCode: string, waiveRightToWithdraw: boolean) {
        return this.http.patch<any>(environment.apiUrl + 'users/activate_license_logged_in', {
            license_code: licenseCode,
            waive_right_to_withdraw: waiveRightToWithdraw
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    selfJoinExisting(selfJoinCode: string): Observable<Class> {
        return this.http.patch<any>(environment.apiUrl + 'users/self_join_existing', {
            self_join_code: selfJoinCode
        }).pipe(
            map(
                (response) => {
                    const classFactory = this.injector.get(ClassFactory);
                    return classFactory.map(response);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    setSettings() {
        const activeUser = this.stateService.getActiveUser();

        if (activeUser.organization && activeUser.organization.settings) {
            const settings = activeUser.organization.settings;

            this.stateService.personalSettings.setSetting('allowUpdateAccountDetails',
                activeUser.roleId === 4 ? settings.allowUpdateAccountDetails : true
            );
            this.stateService.personalSettings.setSetting('allowUpdatePassword',
                activeUser.roleId === 4 ? settings.allowUpdatePassword : true
            );
            this.stateService.personalSettings.setSetting('allowUpdateCourse',
                activeUser.roleId === 4 ? settings.allowUpdateCourse : true
            );
            this.stateService.personalSettings.setSetting('showKeyboard', settings.showKeyboard);
            this.stateService.personalSettings.setSetting('allowBackspace', settings.allowBackspace);
        }
    }

    resetPassword(email: string, repeatEmail: string) {
        return this.http.post<any>(environment.apiUrl + 'users/reset_password', {
            email,
            repeat_email: repeatEmail
        }).pipe(
            catchError(
                (err) => {
                    if (
                        err.status === 422
                        || (err.status === 403 && err.error === 'organizationFeatureDisabled')
                    ) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    submitPasswordReset(password: string, repeatPassword: string, userId: number, code: string) {
        return this.http.patch<any>(environment.apiUrl + 'users/submit_password_reset', {
            password,
            repeat_password: repeatPassword,
            userId,
            code
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    login(email, password): Observable<boolean> {
        return this.http.post<any>(environment.backendUrl + 'oauth/token', {
            grant_type: 'password',
            client_id: environment.auth.clientId,
            client_secret: environment.auth.clientSecret,
            username: email,
            password,
            scope: '*'
        }).pipe(
            map(
                (response) => {
                    this.storeTokens(response.access_token, response.refresh_token);
                    return true;
                }
            ),
            catchError(
                (err) => {
                    if (
                        (err.status === 400 && err.error.error === 'invalid_grant')
                        ||
                        err.status === 404
                    ) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    storeTokens(accessToken: string, refreshToken: string) {
        localStorage.setItem('auth.accessToken', accessToken);
        localStorage.setItem('auth.refreshToken', refreshToken);
    }

    logout() {
        return this.http.get<any>(environment.apiUrl + 'users/logout').pipe(
            tap(
                () => {
                    this.completeLogout();
                }
            )
        );
    }

    getLoggedInUser(): Observable<User> {
        return this.http.get<any>(environment.apiUrl + 'users/get_logged_in').pipe(
            tap(
                (response) => {
                    if (response.earned_badges.length > 0) {
                        this.stateService.setEarnedBadges(
                            response.earned_badges.map(badge => this.badgeFactory.map(badge))
                        );

                        this.badgeService.addUnseenBadges(response.earned_badges.length);
                    }
                }
            ),
            map(
                (response) => {
                    return this.userFactory.map(response.user);
                }
            ),
            tap(
                (user) => {
                    this.stateService.setActiveUser(user);
                    this.setSettings();

                    // set active course
                    const stateService = this.injector.get(StateService);
                    stateService.setActiveCourseId(user.courseId);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 401) {
                        this.appService.clearLoadingError();

                        /*
                        We have an access token, but it appears to be not valid anymore.
                        So we try the refresh token.
                        Then get the user again with the new access token.
                         */
                        return this.refreshToken().pipe(
                            switchMap(
                                (hasRefreshed) => {
                                    if (hasRefreshed) {
                                        // successfully refreshed -> get the user
                                        return this.getLoggedInUser();
                                    } else {
                                        // failed to refresh
                                        return of(false);
                                    }
                                }
                            )
                        );
                    }

                    return of(err);
                }
            )
        );
    }

    refreshToken() {
        return this.http.post<any>(environment.backendUrl + 'oauth/token', {
            grant_type: 'refresh_token',
            refresh_token: localStorage.getItem('auth.refreshToken'),
            client_id: environment.auth.clientId,
            client_secret: environment.auth.clientSecret,
            scope: '*'
        }).pipe(
            map(
                (response) => {
                    this.storeTokens(response.access_token, response.refresh_token);
                    return true;
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 401 || err.status === 404) {
                        this.appService.clearLoadingError();
                        return of(false);
                    }

                    return of(err);
                }
            )
        );
    }

    getStudentDetails(id: number) {
        return this.http.get<any>(environment.apiUrl + 'users/student_details/' + id).pipe(
            map(
                (response) => {
                    return this.userFactory.map(response);
                }
            )
        );
    }

    getDeletedUsers(organizationId: number | string, roleIds: number[], classId: number) {
        return this.http.get<any>(
            environment.apiUrl + 'users/get_deleted_organization_users/'
            + organizationId
            + '/' + roleIds.join(',')
            + '/' + classId
        ).pipe(
            map(
                (response) => {
                    return response.map(
                        (student) => {
                            return this.userFactory.map(student);
                        }
                    );
                }
            )
        );
    }

    createOrganizationUser(
        organizationId: number | string,
        firstName: string,
        name: string,
        email: string,
        roleId: number,
        languageId: number
    ) {
        return this.http.post<any>(environment.apiUrl + 'users/create_organization_user', {
            organization_id: organizationId,
            first_name: firstName,
            name,
            email,
            role_id: roleId,
            language_id: languageId
        }).pipe(
            map(
                (response) => {
                    return this.userFactory.map(response);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    createOrganizationUsers(organizationId: number | string, data: { [key: string]: any }, classId: number) {
        return this.http.post<any>(environment.apiUrl + 'users/create_organization_users', {
            organization_id: organizationId,
            data,
            group_id: classId
        }).pipe(
            map(
                (response) => {
                    return {
                        importBatchId: response.importBatchId,
                        users: response.users.map(
                            (user) => {
                                return this.userFactory.map(user);
                            }
                        )
                    };
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    organizationCountByRoleId(roleId: number) {
        return this.http.get<any>(
            environment.apiUrl + 'users/organization_count_by_role_id/' + roleId
        );
    }

    organizationCountDeletedByRoleId(organizationId: number | string, roleIds: number[], classId?: number) {
        return this.http.get<any>(
            environment.apiUrl + 'users/organization_count_deleted_by_role_id/' + organizationId + '/' + roleIds.join(',') + '/' + classId
        );
    }

    countDeleted() {
        return this.http.get<any>(
            environment.apiUrl + 'users/count_deleted'
        );
    }

    getOrganizationUsers(
        organizationId: number | string,
        roleIds: number[],
        classId: number,
        licenseFilter: string,
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string,
        includeFromArchived = true
    ) {
        let roleString = 'all';
        if (roleIds && roleIds.length > 0) {
            roleString = roleIds.join(',');
        }

        let url = environment.apiUrl + 'users/get_organization_users/' + organizationId + '/' + roleString;

        if (classId) {
            url += '/' + classId;
        }

        if (!filter) {
            filter = '';
        }

        url += '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&include-from-archived=' + includeFromArchived
            + '&filter=' + filter;

        if (licenseFilter) {
            url += '&license-filter=' + licenseFilter;
        }

        return this.http.get<any>(
            url
        );
    }

    resetOrganizationUserPassword(id: number) {
        return this.http.post<any>(environment.apiUrl + 'users/reset_organization_user_password', {
            id
        });
    }

    downloadImportBatchCredentials(importBatchId: number) {
        return this.downloadService.downloadFile('users/import_batch_credentials/' + importBatchId);
    }

    downloadImportBatchCards(importBatchId: number) {
        return this.downloadService.downloadFile('users/import_batch_cards/' + importBatchId);
    }

    downloadClassCredentials(id: number, asPdf: boolean) {
        return this.downloadService.downloadFile('users/group_credentials/' + id + '/' + (asPdf ? '1' : '0'));
    }

    downloadClassCards(id: number) {
        return this.downloadService.downloadFile('users/group_cards/' + id);
    }

    downloadLoginCard(id: number) {
        return this.downloadService.downloadFile('users/login_card/' + id);
    }

    emailImportBatchCredentials(importBatchId: number) {
        return this.http.post<any>(environment.apiUrl + 'users/email_import_batch_credentials', {
            import_batch_id: importBatchId
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + 'users/' + id);
    }

    restore(id: number) {
        return this.http.patch(environment.apiUrl + 'users/restore/' + id, {});
    }

    signInWithSso(jwt: string, type: SsoTypes) {
        return this.http.post<any>(environment.apiUrl + 'auth/' + type + '/callback', {
            jwt
        }).pipe(
            map(
                (response) => {
                    this.storeTokens(response.access_token, response.refresh_token);
                    return true;
                }
            ),
            catchError(
                (err) => {
                    if (
                        (err.status === 400 && err.error.error === 'invalid_grant')
                        ||
                        err.status === 404
                        ||
                        err.status === 422
                    ) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    validateAccountDetails(
        email: string,
        firstName: string,
        name: string,
        password: string,
        repeatPassword: string,
        ssoType?: SsoTypes,
        ssoToken?: string
    ) {
        return this.http.post<any>(environment.apiUrl + 'users/validate_account_details', {
            email,
            first_name: firstName,
            name,
            password,
            repeat_password: repeatPassword,
            sso_type: ssoType,
            sso_token: ssoToken
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }
}

