import {Injectable} from '@angular/core';
import {TrackingBlock} from '../../../models/trackingBlock';
import {CharacterTrackingFactory} from './characterTracking.factory';

@Injectable()
export class TrackingBlockFactory {
    constructor(
        private characterTrackingFactory: CharacterTrackingFactory
    ) {}

    map(response) {
        return new TrackingBlock(
            response.id,
            response.result_id,
            Date.parse(response.start_at),
            Date.parse(response.end_at),
            response.character_tracking ?
                response.character_tracking.map(characterTracking => this.characterTrackingFactory.map(characterTracking))
                : []
        );
    }
}
