export interface DeadKey {
    code: string;
    modifier: string;
    shift: boolean;
    replacements: {[key: string]: string};
}

export abstract class DeadKeys {
    public static azertyCirconflexe: DeadKey = {
        code: 'BracketLeft',
        modifier: '^',
        shift: false,
        replacements: {
            a: 'â',
            e: 'ê',
            i: 'î',
            o: 'ô',
            u: 'û'
        }
    };

    public static azertyDiaeresis: DeadKey = {
        code: 'BracketLeft',
        modifier: '¨',
        shift: true,
        replacements: {
            a: 'ä',
            e: 'ë',
            i: 'ï',
            o: 'ö',
            u: 'ü',
            y: 'ÿ'
        }
    };
}
