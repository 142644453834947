<h1 i18n="@@termsOfService">Terms of Service</h1>
<p i18n="@@terms.lastChange">Updated on {{ '07-07-2022' }}</p>


<h2 i18n="@@terms.generalTitle">General</h2>
<ng-container i18n="@@terms.general">
    <p>Tyyp is an online course offered under three licensing models: individual edition, school edition or business edition.</p>
    <p>The following terms of service apply to the entire website. If you do not agree with these terms and conditions, we request that you no longer use the website.</p>
</ng-container>


<h2 i18n="@@terms.privacyTitle">Privacy Policy</h2>
<p
    i18n="@@terms.privacy"
>All data provided will be treated according to our <a routerLink="/privacy">Privacy Policy</a>.</p>


<h2 i18n="@@terms.copyrightsTitle">Copyrights</h2>
<ng-container i18n="@@terms.copyrights">
    <p>All texts, exercises, multimedia files, designs and educational concepts are the property of Tyyp. No copies or derivative works may be made or distributed.</p>
</ng-container>


<h2 i18n="@@terms.usageTitle">Usage</h2>
<ng-container i18n="@@terms.usage">
    <p>The website, course and web services may only be used in the ways provided for them.</p>
    <p>You are prohibited from using scripts, programs or any other means to:</p>
    <ul>
        <li>Manipulate or intercept data</li>
        <li>Gain unauthorized access to accounts of other users</li>
        <li>Gain unauthorized access to protected areas of the website</li>
        <li>Gain unauthorized access the course without a valid license</li>
        <li>Gain unauthorized access to the servers or networks</li>
        <li>Cause damage to systems, servers or other hardware</li>
        <li>Artificially overload web services, servers or networks</li>
    </ul>
</ng-container>


<h2 i18n="@@terms.purchasesTitle">Purchases & right of withdrawal</h2>
<ng-container i18n="@@terms.purchases">
    <p>Each purchase is binding. Nevertheless, we provide the possibility to cancel your purchase within 14 days. This is of course only possible for unused licenses. Once a license has been activated, it cannot be revoked.</p>
    <p>If a discount was applied to your order, this will also be deducted from the refund.In the case of a partial refund, the discount will be deducted according to the ratio of the value of the refund to the total order.</p>
    <p>A refund can never exceed the amount paid for the order.</p>

    <p>
        If you would like to use this service, please
        <a [routerLink]="'/' + navigationService.getRouteTranslation('contact')">get in touch</a>.
    </p>

    <p>Prices on the website are indicative. The final price depends on the taxes in your country, the currency used, your account type and any ongoing discounts. The amount to be paid will be shown in your shopping cart after entering your details.</p>
</ng-container>


<h2 i18n="@@terms.licenseRightsTitle">Rights to licenses</h2>
<ng-container i18n="@@terms.licenseRights">
    <p>Licenses are non-transferable and may not be resold. A license grants one user access to the course for the applicable term.</p>
    <p>During the term of a license, we reserve the right to make changes to the course and website.</p>
</ng-container>


<h2 i18n="@@terms.discountsTitle">Discounts</h2>
<ng-container i18n="@@terms.discounts">
    <p>For promotional purposes, sales may occasionally run or discount codes may be distributed.</p>
    <p>These discounts may be limited in number or duration. When the amount or time has expired, we cannot guarantee that a discount or discount code will be accepted.</p>
    <p>Certain discount codes may also be limited to a specific user or e-mail address.</p>
    <p>Only one discount code can be used per order. Discounts cannot be combined. Any discount code can only be used once per customer.</p>
    <p>Only discount codes in the same currency as the order currency can be accepted.</p>
    <p>The discount amount will always be deducted from the total amount including taxes. If the discount amount is greater than the total amount to be paid, the discount will be reduced so that the amount to be paid cannot be less than zero.</p>
    <p>Obtained discounts do not guarantee a discount in the future.</p>
</ng-container>


<h2 i18n="@@terms.cancellationTitle">Cancellation</h2>
<ng-container i18n="@@terms.cancellation">
    <p>Users can cancel their account at any time by logging in and navigating to their account overview, and canceling their account there.</p>
    <p>If not allowed by their organization, one should contact an administrator of that organization.</p>

    <p>
        If an account has been accidentally canceled, it can be reactivated within 14 days. Please contact your organization's administrator. Individual users without an organization can
        <a [routerLink]="'/' + navigationService.getRouteTranslation('contact')">contact us</a>.
    </p>

    <p>After 14 days, all personal data will be permanently anonymized.</p>
</ng-container>


<h2 i18n="@@terms.terminationTitle">Termination</h2>
<ng-container i18n="@@terms.termination">
    <p>We reserve the right to terminate accounts for any violation of these terms of service, or to ensure the security and proper functioning of the website or our organization.</p>
</ng-container>


<h2 i18n="@@terms.availabilityTitle">Availability</h2>
<ng-container i18n="@@terms.availability">
    <p>The website may be offline indefinitely due to maintenance work, hardware or software problems, natural disasters and more. We try to keep the website online 100% of the time, but cannot guarantee this.</p>
</ng-container>


<h2 i18n="@@terms.qualityTitle">Quality Guarantee</h2>
<ng-container i18n="@@terms.quality">
    <p>While we believe Tyyp is the best and most fun way to learn typing, we cannot guarantee results.</p>
    <p>
        Found an error in the course?
        <a [routerLink]="'/' + navigationService.getRouteTranslation('contact')">Let us know!</a>
    </p>
</ng-container>


<h2 i18n="@@terms.liabilityTitle">Liability</h2>
<ng-container i18n="@@terms.liability">
    <p>We are not responsible for any damage to users' computers or networks, or those of third parties, through the use of our website and course.</p>
    <p>We cannot be held responsible for the loss of income or other damages.</p>
    <p>Under no circumstances can the amount of liability exceed the amount paid by the customer to Tyyp during the last 3 months.</p>
</ng-container>


<h2 i18n="@@terms.legislationTitle">Legislation</h2>
<ng-container i18n="@@terms.legislation">
    <p>All agreements follow Belgian law. In case of disputes, the competent court is the court of the judicial district in which Tyyp's registered office is located.</p>
</ng-container>


<h2 i18n="@@terms.organizations">Organizations</h2>
<h3 i18n="@@terms.organizationsGeneralTitle">General</h3>
<ng-container i18n="@@terms.organizationsGeneral">
    <p>Users within an organization have additional capabilities. This section lists some specific conditions associated with them.</p>
</ng-container>

<h3 i18n="@@terms.dataPrivacyTitle">Data & Privacy</h3>
<ng-container i18n="@@terms.dataPrivacy">
    <p>Administrators of an organization are responsible for the management of their employees, classes and students. They are expected to handle this data with due attention to privacy.</p>
    <p>Organizations are responsible for handling corrections to personal data of employees and students. Requests from employees and students to be removed must also be handled by the administrators of the organization.</p>
</ng-container>

<h3 i18n="@@terms.extraContentTitle">Additional Content</h3>
<ng-container i18n="@@terms.extraContent">
    <p>The organization, and specifically the authors of extra content, are responsible for any extra exercises or other content they create in Tyyp.</p>
    <p>It is forbidden to publish texts or multimedia with racist or discriminatory content, or content related to illegal activities.</p>
</ng-container>

<h3 i18n="@@terms.licensesTitle">Licenses</h3>
<ng-container i18n="@@terms.licenses">
    <p>A license is activated as soon as it is assigned to a student. From that moment on, this license is assigned to that student and is no longer available for assignment to other students.</p>
    <p>An activated license can only be released again by removing the linked student, but this is only possible if this student has not yet logged in since the activation of the license.</p>
</ng-container>

<h3 i18n="@@terms.instructorAccessTitle">Course access for instructors and administrators</h3>
<ng-container i18n="@@terms.instructorAccess">
    <p>We offer instructors and administrators free access to the course. The only requirement for this is that there at least as many students with an active license as there are instructors and administrators in the organization.</p>
    <p>If there are more instructors and administrators than there are students with an active license in the organization, instructors and administrators will only have access to the trial version of the course.</p>
</ng-container>

<h3 i18n="@@terms.refundsTitle">Refunds</h3>
<ng-container i18n="@@terms.refunds">
    <p>A refund of purchased licenses is only possible within 14 days of ordering, and only for licenses that have not yet been activated.</p>
    <p>The standard conditions for refund, referred to under "Purchases & right of withdrawal" apply here.</p>
    <p>Refunds are no longer possible after the organization account has been canceled or terminated.</p>
</ng-container>

<h3 i18n="@@terms.organizationCancellationTitle">Cancel organization account</h3>
<ng-container i18n="@@terms.organizationCancellation">
    <p>
        An organization account can be canceled by
        <a [routerLink]="'/' + navigationService.getRouteTranslation('contact')">contacting us</a>.
        To ensure this is done securely, you will be asked for a
        <a routerLink="/support">secure PIN code</a>
        to be included.
    </p>
    <p>
        Refunds, if applicable, are not created automatically. They must be requested in advance via the platform. After the organization account has been cancelled, no refunds are possible.
    </p>
</ng-container>


<h3 i18n="@@terms.organizationTerminationTitle">Termination</h3>
<ng-container i18n="@@terms.organizationTermination">
    <p>We reserve the right to terminate organization accounts for any violation of these terms of service, or to ensure the security and proper functioning of the website or our organization.</p>
</ng-container>


<h2 i18n="@@terms.changesTitle">Changes to these Terms of Service</h2>
<ng-container i18n="@@terms.changes">
    <p>These terms are updated regularly. We encourage users to check this page regularly.</p>
    <p>If we decide that the new terms will have a major impact on your account, we may inform you notify you by email.</p>
    <p>By continuing to use the website, you agree to the new terms of service.</p>
</ng-container>


<h2 i18n="@@terms.contactTitle">Contact Us</h2>
<p
    i18n="@@terms.contact"
>If you have any questions about these terms of service or about your account, you can always <a [routerLink]="'/' + navigationService.getRouteTranslation('contact')">contact us</a>.</p>
