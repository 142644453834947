<course-modal
    [modalWidth]="600"
    [disableClose]="true"
>
    <ng-container slot="modal">
        <h1 i18n="@@loadingError.title">Whoops, something went wrong!</h1>
    </ng-container>

    <ng-container slot="controls">
        <a href="/" mat-stroked-button color="primary">
            <mat-icon>home</mat-icon>
            <ng-container i18n="@@loadingError.backToHome">Homepage</ng-container>
        </a>

        <a mat-flat-button color="primary" (click)="retry()">
            <mat-icon>refresh</mat-icon>
            <ng-container i18n="@@loadingError.refreshPage">Refresh page</ng-container>
        </a>
    </ng-container>
</course-modal>
