import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AppService} from './app.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class ContactService {
    constructor(
        private http: HttpClient,
        private appService: AppService
    ) {}


    send(
        name: string,
        email: string,
        message: string
    ) {
        return this.http.post<any>(environment.apiUrl + 'contact/send', {
            name,
            email,
            message
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }
}
