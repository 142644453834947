import {Component} from '@angular/core';

@Component({
    selector: 'course-typerace',
    templateUrl: './typerace.component.html',
    styleUrls: ['./typerace.component.scss'],
})
export class TyperaceComponent {

}

