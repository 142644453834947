<div class="authContainer" [class.hiddenIllustration]="hideIllustration">
    <div class="authBox">
        <div class="authContent">
            <ng-content></ng-content>
        </div>
        <div *ngIf="!hideIllustration" class="illustration">
            <img
                ngSrc="assets/images/typingCourse/typing.svg"
                width="669.19"
                height="553.74"
                alt=""
            />
        </div>
    </div>
</div>
