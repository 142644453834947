<div class="websiteSectionTitle">
    <h2 i18n="@@website.why.title">Why touch typing is so amazing</h2>
    <p i18n="@@website.why.sub">By touch typing you can type much faster and make fewer mistakes.</p>
</div>

<div class="whyTouchTypingExamples">
    <div class="slideUp why1">
        <img
            ngSrc="assets/images/typingCourse/features/save_time.svg"
            i18n-alt="@@website.why.efficientAlt"
            alt="Clock"
            width="300"
            height="300"
        />
        <h3 i18n="@@website.why.efficient">Work more efficiently</h3>
        <p
            i18n="@@website.why.efficientInfo"
        >No more searching for the right key. Focus on what matters!</p>
    </div>

    <div class="slideUp why2">
        <img
            ngSrc="assets/images/typingCourse/features/save_fingers.svg"
            i18n-alt="@@website.why.ergoAlt"
            alt="Ok hand gesture"
            width="300"
            height="300"
        />
        <h3 i18n="@@website.why.ergo">Spare your fingers</h3>
        <p i18n="@@website.why.ergoInfo">Type correctly and avoid muscle and joint pain.</p>
    </div>

    <div class="slideUp why3">
        <img
            ngSrc="assets/images/typingCourse/features/dream_job.svg"
            i18n-alt="@@website.why.jobAlt"
            alt="Rainbow"
            width="300"
            height="300"
        />
        <h3 i18n="@@website.why.job">Score your dream job</h3>
        <p i18n="@@website.why.jobInfo">Applying for a job gets a lot easier with a typing diploma on your CV.</p>
    </div>
</div>

<p class="slideUp">
    <a [routerLink]="'/' + navigationService.getRouteTranslation('course')" mat-stroked-button color="primary" class="websiteButton">
        <span i18n="@@website.getStarted">Try it free</span>
    </a>
</p>
