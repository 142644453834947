import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StateService} from '../state.service';

@Injectable()
export class ActivateLicenseGuard  {
    constructor(
        private stateService: StateService,
        private router: Router
    ) {}

    canActivate(): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        if (!this.stateService.getActiveUser()) {
            return true;
        }

        this.router.navigate(['account/activate-license']);
    }
}
