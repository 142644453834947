export abstract class Products {
    // old (keep for old licenses)
    public static individualLicense = $localize `:@@products.individualLicense:Tyyp Individual Edition`;
    public static schoolLicense = $localize `:@@products.schoolLicense:Tyyp School Edition`;
    public static businessLicense = $localize `:@@products.businessLicense:Tyyp Business Edition`;

    // new
    public static tyypIndividualBasicLicense = $localize `:@@products.tyypIndividualBasicLicense:Tyyp Basic Typing Course`;
    public static tyypIndividualAdvancedLicense = $localize `:@@products.tyypIndividualAdvancedLicense:Tyyp Advanced Typing Course`;
    public static tyypIndividualFullLicense = $localize `:@@products.tyypIndividualFullLicense:Tyyp Basic + Advanced Typing Course`;
    public static tyypSchoolBasicLicense = $localize `:@@products.tyypSchoolBasicLicense:Tyyp Basic Typing Course for Schools`;
    public static tyypSchoolAdvancedLicense = $localize `:@@products.tyypSchoolAdvancedLicense:Tyyp Advanced Typing Course for Schools`;
    public static tyypSchoolFullLicense = $localize `:@@products.tyypSchoolFullLicense:Tyyp Basic + Advanced Typing Course for Schools`;
    public static tyypBusinessBasicLicense = $localize `:@@products.tyypBusinessBasicLicense:Tyyp Basic Typing Course for Business`;
    public static tyypBusinessAdvancedLicense = $localize `:@@products.tyypBusinessAdvancedLicense:Tyyp Advanced Typing Course for Business`;
    public static tyypBusinessFullLicense = $localize `:@@products.tyypBusinessFullLicense:Tyyp Basic + Advanced Typing Course for Business`;
}
