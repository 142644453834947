import {Injectable} from '@angular/core';
import {ImportBatch} from '../../../models/importBatch';

@Injectable()
export class ImportBatchFactory {
    map(response) {
        return new ImportBatch(
            response.id,
            response.group_id,
            response.organization_id
        );
    }
}
