import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {BadgeFactory} from './factories/badge.factory';
import {environment} from '../../../environments/environment';
import {StateService} from '../../services/state.service';

@Injectable()
export class BadgeService {
    constructor(
        private http: HttpClient,
        private badgeFactory: BadgeFactory,
        private stateService: StateService
    ) {}

    addUnseenBadges(amount: number) {
        this.stateService.setUnseenBadges(
            this.stateService.getUnseenBadges() + amount
        );
    }

    getBadgesProgress(id?: number) {
        let url = environment.apiUrl + 'badges/progress';
        if (id) {
            url += '/' + id;
        }

        return this.http.get<any>(url)
            .pipe(
                map(
                    (response) => {
                        return response.map(
                            (badge) => {
                                return this.badgeFactory.map(badge);
                            }
                        );
                    }
                )
            );
    }
}
