<course-tabbed-auth-form-layout
    [success]="success"
    [formPages]="formPages"
    [validatedPages]="validatedPages"
    [pageNames]="pageNames"
    [pageIndex]="pageIndex"
    (onGoTo)="goTo($event)"
    (onRequestValidationAndGoTo)="validateAndGoTo($event)"
    (onBack)="back()"
    (onContinue)="continue()"
    (onSubmit)="submit()"
>
    <ng-container *ngIf="success">
        <div class="formSectionHeader">
            <div class="websiteSectionTitle">
                <h2 i18n="@@selfJoin.successTitle">Welcome to Tyyp!</h2>
                <p i18n="@@selfJoin.success"
                >We have sent your login details to your e-mail. Have fun typing!</p>
            </div>

            <img
                ngSrc="assets/images/typingCourse/signUpFinal.png"
                class="heroImg"
                width="483"
                height="373"
                alt=""
            />
        </div>

        <div class="spamboxInfo" i18n="@@purchase.spamBox">
            <p><strong>Didn't receive the e-mail?</strong> Sometimes e-mails arrive with a little delay. If our e-mail doesn't reach you within <strong>30 minutes</strong>, check if it didn't accidentaly end up in your <strong>spambox</strong>!</p>
            <p>
                Still didn't receive the e-mail?
                <a [routerLink]="'/' + navigationService.getRouteTranslation('contact')">Send us a message</a>
                , we're happy to help!
            </p>
        </div>
    </ng-container>

    <ng-container *ngIf="!success">
        <form
            [formGroup]="form"
            (submit)="pageIndex < formPages.length - 1 ? continue() : submit()"
        >
            <div formGroupName="account" [hidden]="pageIndex !== formPages.indexOf('account')">
                <div class="formSectionHeader">
                    <div class="websiteSectionTitle">
                        <h2 i18n="@@selfJoin.accountTitle">Get started with Tyyp!</h2>
                        <p
                            i18n="@@selfJoin.account"
                        >Enter the details of the person who will use Tyyp below.</p>
                    </div>

                    <img
                        ngSrc="assets/images/typingCourse/business.svg"
                        class="heroImg"
                        width="556.21"
                        height="429.59"
                        alt=""
                    />
                </div>

                <h3
                    i18n="@@selfJoin.accountDetails"
                >Who will use Tyyp?</h3>

                <p class="formRow">
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@firstName">First name</mat-label>
                        <input matInput required formControlName="firstName">
                        <mat-error *ngIf="errors?.first_name">
                            <ng-container *ngFor="let err of errors.first_name">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@name">Name</mat-label>
                        <input matInput required formControlName="name">
                        <mat-error *ngIf="errors?.name">
                            <ng-container *ngFor="let err of errors.name">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@email">E-mail</mat-label>
                        <input type="email" matInput required formControlName="email">
                        <mat-error *ngIf="errors?.email">
                            <ng-container *ngFor="let err of errors.email">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <p class="formRow">
                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@activateLicense.choosePassword">Choose a password</mat-label>
                        <input
                            matInput
                            required
                            [type]="hidePassword ? 'password' : 'text'"
                            formControlName="password"
                            autocomplete="new-password"
                        />
                        <button
                            type="button"
                            mat-icon-button
                            matSuffix
                            tabindex="-1"
                            (click)="hidePassword = !hidePassword"
                            [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hidePassword"
                        >
                            <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                        <mat-error *ngIf="errors?.password">
                            <ng-container *ngFor="let err of errors.password">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label i18n="@@repeatPassword">Repeat password</mat-label>
                        <input
                            matInput required
                            [type]="hidePassword2 ? 'password' : 'text'"
                            formControlName="repeatPassword"
                            autocomplete="new-password"
                        />
                        <button
                            type="button"
                            mat-icon-button
                            matSuffix
                            tabindex="-1"
                            (click)="hidePassword2 = !hidePassword2"
                            [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hidePassword2"
                        >
                            <mat-icon>{{ hidePassword2 ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                        <mat-error *ngIf="errors?.repeat_password">
                            <ng-container *ngFor="let err of errors.repeat_password">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </p>

                <course-sso-buttons
                    [signUp]="true"
                    (onSsoAccountCreationRequest)="handleSsoAccountCreationRequest()"
                ></course-sso-buttons>

                <!-- invisible button to make submit with enter key work -->
                <button
                    type="submit"
                    class="invisible"
                ></button>
            </div>

            <div formGroupName="class" [hidden]="pageIndex !== formPages.indexOf('class')">
                <div class="formSectionHeader">
                    <div class="websiteSectionTitle">
                        <ng-container *ngIf="class">
                            <h2>
                                <ng-container i18n="@@selfJoin.joinClass">Join the class</ng-container>
                                "{{ class.name }}"
                            </h2>

                            <p
                                i18n="@@selfJoin.joinClassInfo"
                            >You are currently using the class link and will therefore be automatically added to this class.</p>
                        </ng-container>

                        <ng-container *ngIf="!class">
                            <h2 i18n="@@selfJoin.classTitle">Do you use Tyyp at school?</h2>

                            <p
                                i18n="@@selfJoin.classCodeInfo"
                            >Ask your teacher for the class code and enter it below to become a member of the class.</p>
                        </ng-container>
                    </div>

                    <img
                        ngSrc="assets/images/typingCourse/school.svg"
                        class="heroImg"
                        width="556.21"
                        height="429.59"
                        alt=""
                    />
                </div>

                <p [hidden]="class">
                    <mat-radio-group (change)="enableDisableClassCode($event.value)" formControlName="enableClassCode">
                        <mat-radio-button [value]="true" i18n="@@selfJoin.yesLinkClass">Yes, I want to fill in my class code</mat-radio-button>
                        <mat-radio-button [value]="false" i18n="@@selfJoin.noDontLinkClass">No, I don't have a class code</mat-radio-button>
                    </mat-radio-group>
                </p>

                <div [hidden]="!showClassCode">
                    <h3
                        i18n="@@selfJoin.enterClassCode"
                    >Enter your class code</h3>

                    <p>
                        <mat-form-field appearance="outline">
                            <mat-label i18n="@@selfJoin.selfJoinCode">Class code</mat-label>
                            <input type="email" matInput [readonly]="!!class" formControlName="selfJoinCode">
                            <mat-hint *ngIf="!class" i18n="@@optional">Optional</mat-hint>
                            <mat-error *ngIf="errors?.self_join_code">
                                <ng-container *ngFor="let err of errors.self_join_code">
                                    {{ err }} <br>
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </p>
                </div>

                <!-- invisible button to make submit with enter key work -->
                <button
                    type="submit"
                    class="invisible"
                ></button>
            </div>

            <div formGroupName="license" [hidden]="pageIndex !== formPages.indexOf('license')">
                <div class="formSectionHeader">
                    <div class="websiteSectionTitle">
                        <h2 i18n="@@selfJoin.licenseTitle">Your license</h2>
                        <p i18n="@@selfJoin.license"
                        >If you already purchased a license code, you may fill it in below to activate your account.</p>
                    </div>

                    <img
                        ngSrc="assets/images/typingCourse/signUpFinal.png"
                        class="heroImg"
                        width="483"
                        height="373"
                        alt=""
                    />
                </div>

                <ng-container *ngIf="licenseAlreadyClaimed">
                    <div class="dialog error">
                        <h2 i18n="@@activateLicense.licenseAlreadyClaimedTitle">License code already in use</h2>
                        <p
                            i18n="@@activateLicense.licenseAlreadyClaimed"
                        >This license code was already claimed before. Each license code can only be used once.</p>
                    </div>
                </ng-container>

                <div *ngIf="missingBasicProduct" class="dialog error">
                    <h2 i18n="@@activateLicense.missingBasicProductTitle">Access to the basic course required</h2>
                    <p
                        i18n="@@activateLicense.missingBasicProduct"
                    >In order to activate this add-on, access to the basic course is required.</p>
                </div>

                <p>
                    <mat-radio-group (change)="enableDisableLicense($event.value)" formControlName="enableLicenseCode">
                        <mat-radio-button [value]="true" i18n="@@selfJoin.yesLicense">Yes, I already have a license code</mat-radio-button>
                        <mat-radio-button [value]="false" i18n="@@selfJoin.noLicense">No, I don't have a license code yet</mat-radio-button>
                    </mat-radio-group>
                </p>

                <div [hidden]="!showLicense">
                    <h3
                        i18n="@@selfJoin.enterLicenseCode"
                    >Enter your license code</h3>

                    <p>
                        <mat-form-field appearance="outline">
                            <mat-label i18n="@@licenseCode">License code</mat-label>
                            <input matInput required formControlName="licenseCode">
                            <mat-hint i18n="@@activateLicense.licenseCodeHint">Sent to you upon purchase</mat-hint>
                            <mat-error *ngIf="errors?.license_code">
                                <ng-container *ngFor="let err of errors.license_code">
                                    {{ err }} <br>
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-checkbox required formControlName="waiveRightToWithdraw">
                                <span
                                    i18n="@@activateLicense.waiveRightToWithdraw"
                                >All purchases can be refunded within 14 days after purchase, as long as the license codes involved have not been activated.<br />EU residents have a right at law to withdraw from a purchase until 14 days after that purchase.<strong>However, by activating this license you expressly consent to waive your right to withdraw from this purchase.</strong></span>
                        </mat-checkbox>
                        <mat-error *ngIf="errors?.waive_right_to_withdraw">
                            <ng-container *ngFor="let err of errors.waive_right_to_withdraw">
                                {{ err }} <br>
                            </ng-container>
                        </mat-error>
                    </p>
                </div>

                <hr />

                <p>
                    <mat-checkbox
                        required
                        formControlName="acceptTerms"
                        i18n="@@acceptTerms"
                    >I have read and accept the <a routerLink="/terms" target="_blank">Terms of Service</a> and <a routerLink="/privacy" target="_blank">Privacy Policy</a>.</mat-checkbox>
                    <mat-error *ngIf="errors?.accept_terms">
                        <ng-container *ngFor="let err of errors.accept_terms">
                            {{ err }} <br>
                        </ng-container>
                    </mat-error>
                </p>

                <!-- invisible button to make submit with enter key work -->
                <button
                    type="submit"
                    class="invisible"
                ></button>
            </div>
        </form>
    </ng-container>
</course-tabbed-auth-form-layout>
