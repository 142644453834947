import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ExerciseFactory} from './factories/exercise.factory';
import {Exercise} from '../../models/exercise';
import {ChapterFactory} from './factories/chapter.factory';
import {ExerciseTypes} from '../../models/enums/exerciseTypes';
import {ExerciseAccessTypes} from '../../models/enums/exerciseAccessTypes';
import {ResultService} from './result.service';
import {CourseFactory} from './factories/course.factory';
import {AppService} from './app.service';
import {ClassFactory} from './factories/class.factory';
import {UserService} from './user.service';
import {StateService} from '../../services/state.service';

@Injectable()
export class  ExerciseService {
    constructor(
        private http: HttpClient,
        private exerciseFactory: ExerciseFactory,
        private chapterFactory: ChapterFactory,
        private appService: AppService,
        private classFactory: ClassFactory,
        private userService: UserService,
        private resultService: ResultService,
        private injector: Injector,
        private stateService: StateService
    ) {}

    getOne(id): Observable<Exercise> {
        return this.http.get<{exercise: Exercise, activeLicenseCount?: number, instructorCount?: null}>(
            environment.apiUrl + 'exercises/' + id
        ).pipe(
            map(
                (exercise) => {
                    return this.exerciseFactory.map(exercise);
                }
            )
        );
    }

    countMine() {
        return this.http.get<number>(environment.apiUrl + 'exercises/count_for_user/mine');
    }

    getOrganizationExercise(id: number): Observable<Exercise> {
        return this.http.get<any>(environment.apiUrl + 'exercises/get_organization_exercise/' + id)
            .pipe(
                map(
                    (response) => {
                        return this.exerciseFactory.map(response);
                    }
                )
            );
    }

    getClassExerciseList(id: number) {
        return this.http.get<any>(environment.apiUrl + 'exercises/get_group_exercise_list/' + id)
            .pipe(
                map(
                    (response) => {
                        const courseFactory = this.injector.get(CourseFactory);
                        return response.map(e => courseFactory.map(e));
                    }
                )
            );
    }

    getForOrganization(
        organizationId: number | string,
        userId: number,
        excludeUserId: number,
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string
    ) {
        let url = environment.apiUrl + 'exercises/for_organization/' + organizationId;

        if (userId) {
            url += '/' + userId;
        }

        if (!filter) {
            filter = '';
        }

        url += '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&filter=' + filter;

        if (excludeUserId) {
            url += '&exclude-user-id=' + excludeUserId;
        }

        return this.http.get<any>(
            url
        );
    }

    create(
        organizationId: number | undefined,
        courseId: number,
        chapterId: number,
        lessonId: number,
        afterExerciseId: number,
        title: string,
        exerciseType: ExerciseTypes,
        data: string,
        cpm: number,
        correctRatio: number,
        maxAttempts: number,
        access: ExerciseAccessTypes,
        adaptive: boolean
    ) {
        return this.http.post<any>(environment.apiUrl + 'exercises/create', {
            organization_id: organizationId,
            course_id: courseId,
            chapter_id: chapterId,
            lesson_id: lessonId,
            after_exercise_id: afterExerciseId,
            title,
            exercise_type: exerciseType,
            data,
            cpm,
            correct_ratio: correctRatio,
            max_attempts: maxAttempts,
            access,
            adaptive: !!adaptive
        }).pipe(
            map(
                (response) => {
                    return {
                        exercise: this.exerciseFactory.map(response.exercise),
                        invalidChars: response.invalid_chars
                    };
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    update(
        id: number,
        courseId: number,
        chapterId: number,
        lessonId: number,
        afterExerciseId: number,
        title: string,
        exerciseType: ExerciseTypes,
        data: string,
        cpm: number,
        correctRatio: number,
        maxAttempts: number,
        access: ExerciseAccessTypes,
        adaptive: boolean
    ) {
        return this.http.patch<any>(environment.apiUrl + 'exercises/' + id, {
            course_id: courseId,
            chapter_id: chapterId,
            lesson_id: lessonId,
            after_exercise_id: afterExerciseId,
            title,
            exercise_type: exerciseType,
            data,
            cpm,
            correct_ratio: correctRatio,
            max_attempts: maxAttempts,
            access,
            adaptive: !!adaptive
        }).pipe(
            map(
                (response) => {
                    return {
                        exercise: this.exerciseFactory.map(response.exercise),
                        invalidChars: response.invalid_chars
                    };
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + 'exercises/' + id);
    }

    updateExerciseAccess(id: number, classIds: number[]) {
        return this.http.put<any>(environment.apiUrl + 'exercises/update_access/' + id, {
            group_ids: classIds
        });
    }

    updateClassExerciseAccess(id: number, exerciseIds: number[]) {
        return this.http.put<any>(environment.apiUrl + 'exercises/update_group_access/' + id, {
            exercise_ids: exerciseIds
        });
    }

    getAccessClasses(id: number) {
        return this.http.get<any>(environment.apiUrl + 'exercises/get_access_groups/' + id)
            .pipe(
                map(
                    (response) => {
                        return response.map(c => this.classFactory.map(c));
                    }
                )
            );
    }

    getClassExercises(id: number) {
        return this.http.get<any>(environment.apiUrl + 'exercises/get_group_exercises/' + id)
            .pipe(
                map(
                    (response) => {
                        return {
                            manualSelected: response.manual_selected.map(e => this.exerciseFactory.map(e)),
                            manual: response.manual.map(e => this.exerciseFactory.map(e)),
                            other: response.other.map(e => this.exerciseFactory.map(e))
                        };
                    }
                )
            );
    }

    getNextExerciseId(exercises: Exercise[]) {
        const lessonExerciseIds = exercises.map(exercise => exercise.id);
        let completedExerciseIds = [];

        if (this.stateService.getActiveUser()) {
            // logged in: results from API
            completedExerciseIds = exercises.filter(exercise => exercise.isCompleted)
                .map(exercise => exercise.id);
        } else {
            // guest: results from localStorage
            completedExerciseIds = this.resultService.getLocalResults().filter(
                (result) => {
                    return !!result.completed
                        && lessonExerciseIds.includes(result.exerciseId);
                }
            ).map(result => result.exerciseId);

            completedExerciseIds = [... new Set(completedExerciseIds)];
        }

        let nextId = exercises[0].id;
        for (const exercise of exercises) {
            if (!completedExerciseIds.includes(exercise.id)) {
                nextId = exercise.id;
                break;
            }
        }

        return nextId;
    }

    blockKeyboardNavigation(evt: KeyboardEvent) {
        if (['Tab', 'Enter'].includes(evt.key)) {
            // if the closeLessonConfirmation modal is open, we do allow keyboard navigation!
            const modal = this.stateService.getCurrentModal();

            if (!modal || modal.name !== 'closeLessonConfirmation') {
                evt.preventDefault();
            }
        }
    }

    getLessonExercises(
        lessonId: number,
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string
    ) {
        let url = environment.apiUrl + 'exercises/by_lesson_sorted/' + lessonId;

        if (!filter) {
            filter = '';
        }

        url += '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&filter=' + filter;

        return this.http.get<any>(
            url
        );
    }
}
