import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'course-book-a-demo',
    templateUrl: './bookADemo.component.html',
    styleUrls: ['./bookADemo.component.scss'],
})
export class BookADemoComponent implements OnInit {
    @Input() type: 'school' | 'business' = 'school';

    ngOnInit() {
        this.initCalendarScript();
    }

    initCalendarScript() {
        const script = document.createElement('script');
        script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        script.type = 'text/javascript';
        script.async = true;
        script.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(script);
    }
}

