import {Injectable, Injector} from '@angular/core';
import {Exercise} from '../../../models/exercise';
import {ChapterFactory} from './chapter.factory';
import {LessonFactory} from './lesson.factory';
import {UserFactory} from './user.factory';
import {OrganizationFactory} from './organization.factory';

@Injectable()
export class ExerciseFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        const userFactory = this.injector.get(UserFactory);
        const organizationFactory = this.injector.get(OrganizationFactory);
        const lessonFactory = this.injector.get(LessonFactory);
        const chapterFactory = this.injector.get(ChapterFactory);

        return new Exercise(
            response.id,
            response.course_id,
            response.chapter_id,
            response.lesson_id,
            response.title,
            response.exercise_type,
            response.cpm,
            response.correct_ratio,
            response.max_attempts,
            response.access,
            response.user_id,
            response.organization_id,
            response.adaptive,
            response.data,
            undefined,
            response.completed_previous ? response.completed_previous : undefined,
            response.user ? userFactory.map(response.user) : undefined,
            response.organization ? organizationFactory.map(response.organization) : undefined,
            response.position ? response.position : undefined,
            response.lesson ? lessonFactory.map(response.lesson) : undefined,
            response.chapter ? chapterFactory.map(response.chapter) : undefined,
            response.lesson
                && response.lesson.chapter
                && response.lesson.chapter.course
                && response.lesson.chapter.course.keyboard_layout ?
                    response.lesson.chapter.course.keyboard_layout.code
                    : undefined,
            response.best_stars ? response.best_stars : undefined,
            response.best_cpm ? response.best_cpm : undefined,
            response.best_correct_ratio ? response.best_correct_ratio : undefined,
            response.is_completed ? response.is_completed : undefined
        );
    }
}
