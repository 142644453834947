<course-auth-form-layout>
    <div class="formArea">
        <h1
            *ngIf="!success"
            i18n="@@forgotPassword.resetYourPassword"
        >Choose a new password</h1>

        <ng-container *ngIf="success">
            <div class="dialog success">
                <h2 i18n="@@forgotPassword.resetSuccessTitle">The password has been changed!</h2>
                <p
                    i18n="@@forgotPassword.resetSuccess"
                >You can now log in with your new password.</p>
            </div>

            <p class="listActions">
                <a routerLink="/login" mat-flat-button color="primary">
                    <mat-icon>login</mat-icon>
                    <ng-container i18n="@@login.login">Log in</ng-container>
                </a>
            </p>
        </ng-container>

        <ng-container *ngIf="hasExpiredError">
            <div class="dialog error">
                <h2 i18n="@@forgotPassword.linkExpiredTitle">Link expired!</h2>
                <p
                    i18n="@@forgotPassword.linkExpired"
                >This link is only valid for 24 hours. Please <a routerLink="/forgot-password">request a new password</a> again.</p>
            </div>

            <p class="listActions">
                <a
                    routerLink="/forgot-password"
                    mat-flat-button
                    color="primary"
                    i18n="@@forgotPassword.requestPassword"
                >Request a new password</a>
            </p>
        </ng-container>

        <form *ngIf="!hasExpiredError && !success" (submit)="submit()" [formGroup]="form">
            <mat-form-field appearance="outline">
                <mat-label i18n="@@forgotPassword.newPassword">New password</mat-label>
                <input
                    matInput
                    required
                    [type]="hidePassword ? 'password' : 'text'"
                    tabindex="1"
                    formControlName="password"
                    autocomplete="new-password"
                />
                <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="hidePassword = !hidePassword"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hidePassword"
                >
                    <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="errors?.password">
                    <ng-container *ngFor="let err of errors.password">
                        {{ err }} <br>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n="@@forgotPassword.repeatNewPassword">Repeat new password</mat-label>
                <input
                    matInput required
                    [type]="hidePassword2 ? 'password' : 'text'"
                    tabindex="2"
                    formControlName="repeatPassword"
                    autocomplete="new-password"
                />
                <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="hidePassword2 = !hidePassword2"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hidePassword2"
                >
                    <mat-icon>{{ hidePassword2 ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-error *ngIf="errors?.repeat_password">
                    <ng-container *ngFor="let err of errors.repeat_password">
                        {{ err }} <br>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <p class="alignRight">
                <button
                    type="submit"
                    tabindex="3"
                    class="keyboardButton orangeKeyboardButton"
                >
                    <span i18n="@@save">Save</span>
                </button>
            </p>
        </form>
    </div>
</course-auth-form-layout>
