import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Menu} from '../../../models/menu';
import {MenuService} from '../../../shared/services/menu.service';
import {NavigationService} from '../../../shared/services/navigation.service';

@Component({
    selector: 'course-mobile-menu',
    templateUrl: './mobileMenu.component.html',
    styleUrls: ['./mobileMenu.component.scss'],
})
export class MobileMenuComponent extends OnDestroyMixin implements OnInit {
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
    items$: Observable<Menu>;

    constructor(
        public breakpointObserver: BreakpointObserver,
        private menuService: MenuService,
        public navigationService: NavigationService
    ) {
        super();
    }

    ngOnInit() {
        this.items$ = this.menuService.getMenu(false, false, true);

        this.initCloseOnResize();
    }

    close() {
        this.closeMenu.emit();
    }

    initCloseOnResize() {
        this.breakpointObserver.observe('(min-width: 800px)').pipe(
            untilComponentDestroyed(this)
        ).subscribe(
            (res) => {
                if (!!res.matches) {
                    this.close();
                }
            }
        );
    }
}

