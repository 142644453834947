import {Injectable} from '@angular/core';
import {CharacterTracking} from '../../../models/characterTracking';

@Injectable()
export class CharacterTrackingFactory {
    map(response) {
        return new CharacterTracking (
            response.character,
            response.state,
            response.id,
            response.tracking_block_id
        );
    }
}
