import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CurrencyService} from '../../shared/services/currency.service';
import {StateService} from '../state.service';
import {AppService} from '../../shared/services/app.service';


@Injectable()
export class DomainCurrencyResolver  {
    constructor(
        private currencyService: CurrencyService,
        private stateService: StateService,
        private appService: AppService
    ) {}

    resolve(): Observable<any>|Promise<any>|any {
        this.stateService.setActiveCurrency(
            this.appService.currencies.filter(currency => currency.id === this.appService.getDomainDefaults().currencyId)[0]
        );
    }
}
