import {Injectable, Injector} from '@angular/core';
import {ProductCategory} from '../../../models/productCategory';

@Injectable()
export class ProductCategoryFactory {
    constructor(
        private injector: Injector
    ) {
    }

    map(response) {
        return new ProductCategory(
            response.id,
            response.code
        );
    }
}
