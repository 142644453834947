<course-auth-form-layout>
    <div class="formArea">
        <h1
            *ngIf="!success"
            i18n="@@forgotPassword.forgotYourPassword"
        >Forgot your password?</h1>

        <div *ngIf="success" class="dialog success">
            <h2
                i18n="@@forgotPassword.successTitle"
            >Check your e-mail!</h2>
            <ng-container i18n="@@forgotPassword.success">
                <p>We have sent you a link with instructions to reset your password.</p>
                <p>Note that this link is <strong>only valid for 24 hours!</strong></p>
                <p>If you don't receive the e-mail within the next 30 minutes, make sure to check if it accidentally ended up in your spambox.</p>
            </ng-container>
        </div>

        <div *ngIf="organizationFeatureDisabled" class="dialog error">
            <h2
                i18n="@@account.unavailableForYourAccountTitle"
            >This feature has been disabled for your account</h2>
            <ng-container i18n="@@account.changePasswordUnavailable">
                <p>Your organization does not allow students to change their password.</p>
                <p>If you suspect your password has been compromised, please reach out to your instructor or your organization's administration.</p>
            </ng-container>
        </div>

        <form *ngIf="!success" (submit)="submit()" [formGroup]="form">
            <mat-form-field appearance="outline">
                <mat-label i18n="@@email">E-mail</mat-label>
                <input type="email" matInput required formControlName="email">
                <mat-error *ngIf="errors?.email">
                    <ng-container *ngFor="let err of errors.email">
                        {{ err }} <br>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label i18n="@@confirmEmail">Confirm e-mail</mat-label>
                <input type="email" matInput required formControlName="repeatEmail">
                <mat-error *ngIf="errors?.repeat_email">
                    <ng-container *ngFor="let err of errors.repeat_email">
                        {{ err }} <br>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <p class="alignRight">
                <button
                    type="submit"
                    class="keyboardButton orangeKeyboardButton"
                >
                    <span i18n="@@submit">Submit</span>
                </button>
            </p>
        </form>
    </div>
</course-auth-form-layout>
