import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {NEVER, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {DiscountCodeService} from '../../shared/services/discountCode.service';

@Injectable()
export class DiscountCodeResolver  {
    constructor(
        private discountCodeService: DiscountCodeService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        return this.discountCodeService.getOne(
            route.params.id
        ).pipe(
            catchError(
                (err) => {
                    return NEVER;
                }
            )
        );
    }
}
