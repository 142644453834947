import {AfterViewChecked, Component} from '@angular/core';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {AppService} from '../../../../shared/services/app.service';

@Component({
    selector: 'course-why-touch-typing',
    templateUrl: './whyTouchTyping.component.html',
    styleUrls: ['./whyTouchTyping.component.scss'],
})
export class WhyTouchTypingComponent implements AfterViewChecked {
    constructor(
        public navigationService: NavigationService,
        private appService: AppService
    ) {
    }

    ngAfterViewChecked() {
        this.appService.initAnimations('course-why-touch-typing .slideUp', 0.2);
    }
}

