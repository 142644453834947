import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {StateService} from '../../services/state.service';

@Injectable()
export class ModalService {
    private renderer: Renderer2;

    constructor(
        private rendererFactory: RendererFactory2,
        private stateService: StateService
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    setModal(name: string, params?: {[key: string]: any}) {
        this.stateService.setCurrentModal({
            name,
            params
        });

        this.renderer.addClass(document.body, 'modalOpen');
    }

    closeModal() {
        const modal = this.stateService.getCurrentModal();

        if (!modal) {
            return;
        }

        this.stateService.setModalCloseEvents(modal.name);
        this.setModal(undefined);

        // clear the closed event, otherwise it might keep emitting the same close events
        this.stateService.setModalCloseEvents(undefined);

        this.renderer.removeClass(document.body, 'modalOpen');
    }
}
