import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {first, map, switchMap} from 'rxjs/operators';
import {Course} from '../../models/course';
import {HttpClient} from '@angular/common/http';
import {CourseFactory} from './factories/course.factory';
import {KeyboardLayout} from '../../models/keyboardLayout';
import {KeyboardLayoutFactory} from './factories/keyboardLayout.factory';
import {Chapter} from '../../models/chapter';
import {ChapterFactory} from './factories/chapter.factory';
import {AppService} from './app.service';
import {LanguageService} from './language.service';
import {UserService} from './user.service';
import {StateService} from '../../services/state.service';

@Injectable()
export class CourseService {
    constructor(
        private http: HttpClient,
        private courseFactory: CourseFactory,
        private appService: AppService,
        private languageService: LanguageService,
        private keyboardLayoutFactory: KeyboardLayoutFactory,
        private injector: Injector,
        private userService: UserService,
        private stateService: StateService
    ) {}

    getAll() {
        return this.http.get<any>(environment.apiUrl + 'courses/all').pipe(
            map(
                (response) => {
                    return response.map(
                        (course) => {
                            return this.courseFactory.map(course);
                        }
                    );
                }
            )
        );
    }

    getAllSorted(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string
    ) {
        let url = environment.apiUrl + 'courses/all_sorted';

        if (!filter) {
            filter = '';
        }

        url += '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&filter=' + filter;

        return this.http.get<any>(
            url
        );
    }

    getOne(id: number): Observable<Course> {
        return this.stateService.personalSettings.getSetting('disableGames').pipe(
            first(),
            switchMap(
                (disableGames) => {
                    let url = environment.apiUrl + 'courses/' + id;

                    if (disableGames) {
                        url += '/' + true;
                    }

                    return this.http.get<Course>(url)
                        .pipe(
                            map(
                                (response) => {
                                    return this.courseFactory.map(response);
                                }
                            )
                        );
                }
            )
        );
    }

    getByUserId(userId: number): Observable<Course> {
        return this.http.get<Course>(environment.apiUrl + 'courses/by_user_id/' + userId)
            .pipe(
                map(
                    (response) => {
                        return this.courseFactory.map(response);
                    }
                )
            );
    }

    getIdByCode(code: string) {
        return this.http.get<number>(environment.apiUrl + 'courses/id_by_code/' + code);
    }

    getDefaultCourse() {
        const domainDefault = this.appService.getDomainDefaults();
        const courseCode = domainDefault.defaultCourse;
        return this.getIdByCode(courseCode);
    }

    setDefaultCourse() {
        this.getDefaultCourse().pipe(
            first()
        ).subscribe(
            (id) => {
                this.stateService.setActiveCourseId(id);
                localStorage.setItem('courseId', '' + id);
            }
        );
    }

    getKeyboardLayout(id: number): Observable<KeyboardLayout> {
        return this.http.get<KeyboardLayout>(environment.apiUrl + 'courses/keyboard_layout/' + id)
            .pipe(
                map(
                    (response) => {
                        return this.keyboardLayoutFactory.map(response);
                    }
                )
            );
    }

    getCourseChapters(courseId: number, excludeExam = false): Observable<Chapter[]> {
        const chapterFactory = this.injector.get(ChapterFactory);

        return this.http.get<Chapter[]>(environment.apiUrl + 'chapters/course_id/' + courseId + '?exclude-exam=' + excludeExam)
            .pipe(
                map(
                    (chapters) => {
                        return chapters.map(chapter => chapterFactory.map(chapter));
                    }
                )
            );
    }
}
