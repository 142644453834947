import {AfterViewChecked, Component, Input} from '@angular/core';
import {AppService} from '../../../../shared/services/app.service';

@Component({
    selector: 'course-how-does-it-work',
    templateUrl: './howDoesItWork.component.html',
    styleUrls: ['./howDoesItWork.component.scss'],
})
export class HowDoesItWorkComponent implements AfterViewChecked {
    @Input() isSchool: boolean;

    constructor(
        private appService: AppService
    ) {}

    ngAfterViewChecked() {
        this.appService.initAnimations('course-how-does-it-work .slideLeft, course-how-does-it-work .slideRight', 0.5);
    }
}

