import {Injectable} from '@angular/core';
import {Currency} from '../../../models/currency';

@Injectable()
export class CurrencyFactory {

    map(response) {
        return new Currency(
            response.id,
            response.code,
            response.name,
            response.symbol
        );
    }
}
