import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';

@Injectable()
export class MyMatPaginatorIntl extends MatPaginatorIntl {

    /** A label for the page size selector. */
    itemsPerPageLabel = $localize `:@@paginator.itemsPerPage:Items per page`;
    /** A label for the button that increments the current page. */
    nextPageLabel = $localize `:@@paginator.nextPage:Next page`;
    /** A label for the button that decrements the current page. */
    previousPageLabel = $localize `:@@paginator.previousPage:Previous page`;
    /** A label for the button that moves to the first page. */
    firstPageLabel = $localize `:@@paginator.firstPage:First page`;
    /** A label for the button that moves to the last page. */
    lastPageLabel = $localize `:@@paginator.lastPage:Last page`;
    /** A label for the range of items within the current page and the length of the whole list. */
    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return '0 ' + $localize `:@@paginator.of:of` + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' ' + $localize `:@@paginator.of:of` + ' ' + length;
    }
}
