<course-auth-form-layout>
    <div class="formArea">
        <h1 i18n="@@login.title">Log in</h1>

        <div *ngIf="hasLoginError" class="dialog error">
            <h2 i18n="@@login.invalidLogin">Invalid credentials!</h2>
        </div>
        <form [formGroup]="form" (submit)="submit()">
            <mat-form-field appearance="outline">
                <mat-label i18n="@@login.emailOrUsername">E-mail or username</mat-label>
                <input type="text" matInput required tabindex="1" formControlName="email">
                <mat-error *ngIf="errors?.email">
                    <ng-container *ngFor="let err of errors.email">
                        {{ FormErrors[err] }} <br>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="passwordField">
                <mat-label i18n="@@password">Password</mat-label>
                <input matInput required [type]="hidePassword ? 'password' : 'text'" tabindex="2" formControlName="password">
                <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="hidePassword = !hidePassword"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hidePassword"
                >
                    <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
                <mat-hint>
                    <a
                        routerLink="/forgot-password"
                        i18n="@@forgotPassword.forgotYourPassword"
                    >Forgot your password?</a>
                </mat-hint>
                <mat-error *ngIf="errors?.password">
                    <ng-container *ngFor="let err of errors.password">
                        {{ FormErrors[err] }}
                        <a
                            *ngIf="err === 'required'"
                            routerLink="/forgot-password"
                            i18n="@@forgotPassword.forgotYourPassword"
                        >Forgot your password?</a>
                        <br>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <p class="alignRight">
                <button
                    type="submit"
                    tabindex="3"
                    class="keyboardButton orangeKeyboardButton"
                >
                    <span i18n="@@login.login">Log in</span>
                </button>
            </p>
        </form>

        <course-sso-buttons (onSsoAccountCreationRequest)="handleSsoAccountCreationRequest()"></course-sso-buttons>
    </div>
    <div class="registerArea">
        <p i18n="@@login.noAccountYet">Not signed up yet?</p>
        <p>
            <a
                [routerLink]="'/' + navigationService.getRouteTranslation('purchase')"
                mat-stroked-button
                color="primary"
                class="purchaseButton roundedButton"
                i18n="@@login.purchaseButton"
            >Purchase Tyyp!</a>
        </p>
    </div>
</course-auth-form-layout>
