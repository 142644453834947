import {AfterViewChecked, ChangeDetectorRef, Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../../shared/services/modal.service';
import {OrderService} from '../../../../shared/services/order.service';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {StateService} from '../../../../services/state.service';
import {AppService} from '../../../../shared/services/app.service';

@Component({
    selector: 'course-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements AfterViewChecked {
    @Input() parentPage = 'home';
    features = ['fun', 'complete', 'diploma', 'online', 'accessible'];
    activeIndex = 0;
    contentActiveIndex = 0;
    isHiding = false;

    constructor(
        private cd: ChangeDetectorRef,
        private modalService: ModalService,
        private router: Router,
        private orderService: OrderService,
        public navigationService: NavigationService,
        private stateService: StateService,
        private appService: AppService
    ) {}

    ngAfterViewChecked() {
        this.initAnimations();
    }

    initAnimations() {
        if (!this.appService.isBrowser) {
            return;
        }

        // feature description and image
        const featureObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach(
                    (entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('inViewport');
                        } else {
                            /*
                            We usually don't remove the inViewport class when an element is out of view,
                            because we don't want to repeat the animation when f.e. scrolling up again.
                            However, in this case, a user can scroll through the features multiple times.
                            In that case, we want to keep showing the courseAnimations.
                            An unwanted side effect of that is that the animation also plays again while scrolling
                            to the features block again.
                            To avoid this, we don't remove the inViewport if the feature is active, as that means
                            we scrolled away instead of going to another feature.
                             */
                            if (
                                this.features[this.activeIndex] !== entry.target.parentElement.id
                                && this.features[this.activeIndex] !== entry.target.parentElement.parentElement.id
                            ) {
                                entry.target.classList.remove('inViewport');
                            }
                        }
                    }
                );
            }, {
                threshold: 0
            });

        document.querySelectorAll('course-features .slideLeft, course-features .slideRight').forEach(
            (target) => {
                featureObserver.observe(target);
            }
        );

        // cta button
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(
                    (entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('inViewport');
                        }
                    }
                );
            }, {
                threshold: 0
            });

        document.querySelectorAll('course-features .slideUp').forEach(
            (target) => {
                observer.observe(target);
            }
        );
    }

    previous() {
        let index = this.activeIndex - 1;

        if (index < 0) {
            index = this.features.length - 1;
        }

        this.goTo(index);
    }

    next() {
        let index = this.activeIndex + 1;

        if (index > this.features.length - 1) {
            index = 0;
        }

        this.goTo(index);
    }

    goTo(index: number) {
        if (index === this.activeIndex) {
            return;
        }

        this.activeIndex = index;
        this.isHiding = true;

        window.setTimeout(
            () => {
                this.isHiding = false;
                this.contentActiveIndex = this.activeIndex;
                this.cd.detectChanges();
            }, 200
        );
    }

    addToCart() {
        this.stateService.setCartItems(1);

        this.router.navigate(['/checkout']);
    }
}

