import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'course-tabbed-auth-form-layout',
    templateUrl: './authFormLayout.component.html',
    styleUrls: ['./authFormLayout.component.scss'],
})
export class TabbedAuthFormLayoutComponent {
    @Input() success = false;
    @Input() formPages: string[] = [];
    @Input() validatedPages: {[key: number]: boolean} = {};
    @Input() pageNames: {[key: string]: string} = {};
    @Input() pageIndex = 0;
    @Output() onGoTo: EventEmitter<number> = new EventEmitter();
    @Output() onRequestValidationAndGoTo: EventEmitter<number> = new EventEmitter();
    @Output() onBack: EventEmitter<void> = new EventEmitter();
    @Output() onContinue: EventEmitter<void> = new EventEmitter();
    @Output() onSubmit: EventEmitter<void> = new EventEmitter();

    goToPage(goToIndex: number) {
        // can't jump to a page if it and its previous page aren't yet validated
        if (!this.validatedPages[goToIndex] && !this.validatedPages[goToIndex - 1]) {
            return;
        }

        if (goToIndex < this.pageIndex) {
            // don't validate when going back
            this.onGoTo.emit(goToIndex);
        } else {
            this.onRequestValidationAndGoTo.emit(goToIndex);
        }
    }

    back() {
        this.onBack.emit();
    }

    continue() {
        this.onContinue.emit();
    }

    submit() {
        this.onSubmit.emit();
    }
}

