import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {NEVER, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UserService} from '../../shared/services/user.service';
import {StateService} from '../state.service';

@Injectable()
export class StudentDetailsResolver  {
    constructor(
        private userService: UserService,
        private stateService: StateService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        let id = route.params.id;
        if (!id) {
            id = this.stateService.getActiveUser().id;
        }

        return this.userService.getStudentDetails(
            id
        ).pipe(
            catchError(
                (err) => {
                    return NEVER;
                }
            )
        );
    }
}
