import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StateService} from '../state.service';

@Injectable()
export class LoggedInGuard  {
    constructor(
        private stateService: StateService,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        if (this.stateService.getActiveUser()) {
            return true;
        }

        // redirect
        this.router.navigate(['/login']);
    }
}
