import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'course-loading-error',
    templateUrl: './loadingError.component.html',
    styleUrls: ['./loadingError.component.scss'],
})
export class LoadingErrorComponent {
    constructor(
        private router: Router
    ) {}

    retry() {
        window.location.reload();
    }
}

