<div class="overlayBlur" [class.cookieConsent]="customClass === 'cookieConsent'" @fade [@.disabled]="disableEnterAnimation">
    <div class="overlay">
        <div class="modalWrapper" [style.max-width.px]="modalWidth" @slideDownModal>
            <div
                class="modal"
                [class.successModal]="success"
                [class.errorModal]="error"
            >
                <ng-content select="[slot=modal]"></ng-content>

                <p class="modalFormControls">
                    <span *ngIf="!disableClose">
                        <a mat-stroked-button class="cancelBtn" (click)="doClose()">
                            <ng-container *ngIf="closeLabel">{{ closeLabel }}</ng-container>
                            <ng-container *ngIf="!closeLabel" i18n="@@close">Close</ng-container>
                        </a>
                    </span>

                    <span class="alignRight">
                        <ng-content select="[slot=controls]"></ng-content>
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>

<a *ngIf="!disableClose" (click)="doClose()" mat-icon-button class="closeBtn">
    <mat-icon>close</mat-icon>
</a>
