import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class DownloadService {
    constructor(
        private http: HttpClient
    ) {}

    downloadFile(endpoint: string) {
        const requestOptions: {} = {
            responseType: 'blob',
            observe: 'response'
        };

        return this.http.get<any>(environment.apiUrl + endpoint, requestOptions).pipe(
            tap(
                (response) => {
                    const filename = response.headers.get('Filename');
                    const fileURL = URL.createObjectURL(response.body);

                    const a = document.createElement('a');
                    a.href = fileURL;
                    a.download = filename;
                    a.click();
                }
            )
        );
    }
}
