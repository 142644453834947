import {
    animate,
    animateChild,
    query,
    style,
    transition,
    trigger
} from '@angular/animations';

export const animations = [
    trigger('fade', [
        transition('void => *', [
            style({opacity: 0}),
            animate('300ms ease-in', style({opacity: 1})),
            query('@slideDownModal', animateChild(), {optional: true})
        ]),
        transition('* => void', [
            animate('300ms ease-out', style({opacity: 0}))
        ])
    ]),
    trigger('slideDownModal', [
        transition('void => *', [
            style({transform: 'translateY(-20px)', opacity: 0}),
            animate('300ms 100ms ease-in-out', style({transform: 'translateY(0)', opacity: 1}))
        ])
    ])
];
