import {Component} from '@angular/core';
import {StateService} from '../../../../services/state.service';


@Component({
    selector: 'course-user-menu',
    templateUrl: './userMenu.component.html',
    styleUrls: ['./userMenu.component.scss'],
})
export class UserMenuComponent {
    constructor(
        private stateService: StateService
    ) {}

    getActiveUser() {
        return this.stateService.getActiveUser();
    }
}

