import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {NEVER, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ClassService} from '../../shared/services/class.service';

@Injectable()
export class ClassSelfJoinResolver  {
    constructor(
        private classService: ClassService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        return this.classService.getBySelfJoinCode(
            route.params.selfJoinCode
        ).pipe(
            catchError(
                (err) => {
                    return NEVER;
                }
            )
        );
    }
}
