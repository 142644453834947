import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LanguageService} from '../../shared/services/language.service';

@Injectable()
export class HeaderInterceptor {
    constructor(
        private languageService: LanguageService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = request.headers.append('Accept', 'application/json')
            .append('App-Language', '' + this.languageService.getActiveLanguageCode())
            .append('App-Timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

        const accessToken = localStorage.getItem('auth.accessToken');
        if (accessToken) {
            headers = headers.append('Authorization', 'Bearer ' + accessToken);
        }

        const clonedRequest = request.clone({
            headers
        });

        return next.handle(clonedRequest);
    }
}
