import {Injectable} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BreakpointObserver} from '@angular/cdk/layout';
import {environment} from '../../../environments/environment';
import {StateService} from '../../services/state.service';

@Injectable()
export class AdminService {
    constructor(
        private http: HttpClient,
        private breakpointObserver: BreakpointObserver,
        private stateService: StateService
    ) {
    }

    loadNotifications() {
        return this.http.get<any>(environment.apiUrl + 'notifications', {headers: {SkipRetryOrFail: '1'}})
            .pipe(
                map(
                    (notifications: {[key: string]: any}) => {
                        return {
                            unpaidInvoices: notifications.unpaid_invoices ? notifications.unpaid_invoices : undefined,
                            withoutLicense: notifications.without_license ? notifications.without_license : undefined
                        };
                    }
                ),
                tap(
                    (notifications) => {
                        this.stateService.setNotifications(notifications);
                    }
                )
            );
    }

    getVisibleTableColumns(
        displayedColumns: string[],
        hideOnMobile: string[] = [],
        separateRowOnMobile: string[] = []
    ) {
        return this.breakpointObserver.observe('(max-width: 700px)').pipe(
            map(
                (res) => {
                    if (!!res.matches) {
                        // filter hidden on mobile
                        const visibleColumns = displayedColumns.filter(
                            (col) => {
                                return !hideOnMobile.includes(col);
                            }
                        );

                        if (separateRowOnMobile.length > 0) {
                            const row2 = separateRowOnMobile;
                            const row1 = visibleColumns.filter(
                                (col) => {
                                    return !row2.includes(col);
                                }
                            );

                            return [
                                row1,
                                row2
                            ];
                        }

                        return [visibleColumns];
                    } else {
                        return [displayedColumns];
                    }
                }
            )
        );
    }

    determineColSpans(rowCols) {
        const colSpans = [];

        // col spans for second row?
        if (rowCols.length > 1) {
            const firstRowCols = rowCols[0].length;
            const secondRowCols = rowCols[1].length;

            // the row with the fewest column gets colSpans
            let rowWithColSpans = rowCols[0];
            if (firstRowCols > secondRowCols) {
                rowWithColSpans = rowCols[1];
            }

            let usedCols = 0;
            rowWithColSpans.forEach(
                (col) => {
                    colSpans[col] = Math.ceil(
                        Math.max(firstRowCols, secondRowCols) / Math.min(firstRowCols, secondRowCols)
                    );
                    usedCols += colSpans[col];

                    if (usedCols > Math.max(firstRowCols, secondRowCols)) {
                        colSpans[col] -= 1;
                    }
                }
            );
        }

        return colSpans;
    }
}
