import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '../../shared/services/user.service';
import {StateService} from '../state.service';

@Injectable()
export class RestoreUserGuard  {
    constructor(
        private userService: UserService,
        private stateService: StateService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        if (!this.stateService.getActiveUser() && localStorage.getItem('auth.accessToken')) {
            return this.userService.getLoggedInUser().pipe(
                map(
                    (user) => true
                )
            );
        } else {
            return true;
        }
    }
}
