import {Pipe, PipeTransform} from '@angular/core';
import {Currency} from '../../models/currency';

@Pipe({
    name: 'formatCurrency'
})
export class FormatCurrencyPipe implements PipeTransform {

    transform(value: any, currency: Currency, currencySpan = false): any {
        value = parseFloat(value);

        let currencySymbol = currency.symbol;
        if (currencySpan) {
            currencySymbol = '<span>' + currencySymbol + '</span>';
        }

        // IDEA: this could be more flexible to allow currency symbol before or after, or use a period or comma for decimals
        if (value >= 0) {
            return currencySymbol + ' ' + (Math.round(value * 100) / 100).toFixed(2).replace('.', ',');
        } else {
            return '- ' + currencySymbol + ' ' + (Math.round(value * -1 * 100) / 100).toFixed(2).replace('.', ',');;
        }
    }

}
