import {UrlSegment} from '@angular/router';
import {RouteTranslations} from '../../models/abstract/routeTranslations';

export class RouteMatcher {
    /**
     * Url matcher: so we can have translated paths for important public pages.
     *
     * Note: for rel="alternate" links we need to link to all other language versions,
     * so we need to tell the meta directive which route segments should be translated in those languages:
     * translatedSegments: [{i: 0, value: RouteTranslations.course}]
     */
    static matchFirstSegment(
        keys: string[],
        segments: UrlSegment[],
        consumeSegment = true,
        translateSegment = true
    ) {
        let match = null;

        for (const key of keys) {
            if (segments.length > 0) {
                if (
                    (
                        translateSegment && Object.values(RouteTranslations[key]).includes(segments[0].path)
                    )
                    || (
                        !translateSegment && segments[0].path === key
                    )
                ) {
                    const consumed = [];
                    if (consumeSegment) {
                        consumed.push(segments[0]);
                    }

                    match = {
                        consumed,
                        posParams: {}
                    };

                    break;
                }
            }
        }

        return match;
    }
}
