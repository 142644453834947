import {Component, Input, OnChanges} from '@angular/core';

@Component({
    selector: 'course-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
    @Input({ required: true }) size = 40;
    @Input({ required: true }) avatar: string;
    backColor = 10;
    bodyColor = 1;
    body = 1;
    face = 1;

    ngOnChanges() {
        this.extractAvatarData();
    }

    extractAvatarData() {
        const parts = this.avatar.split('.');

        if (parts.length !== 4) {
            return;
        }

        this.backColor = parseInt(parts[0], 10);
        this.bodyColor = parseInt(parts[1], 10);
        this.body = parseInt(parts[2], 10);
        this.face = parseInt(parts[3], 10);
    }
}

