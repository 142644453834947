import {Injectable} from '@angular/core';
import {Language} from '../../../models/language';

@Injectable()
export class LanguageFactory {
    map(response) {
        return new Language(
            response.id,
            response.code,
            response.name
        );
    }
}
