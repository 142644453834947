import {AfterViewChecked, Component, Input, OnChanges, OnInit} from '@angular/core';
import {Currency} from '../../../../models/currency';
import {Product} from '../../../../models/product';
import {StateService} from '../../../../services/state.service';
import {AppService} from '../../../../shared/services/app.service';

@Component({
    selector: 'course-organization-features',
    templateUrl: './organizationFeatures.component.html',
    styleUrls: ['./organizationFeatures.component.scss'],
})
export class OrganizationFeaturesComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input() isSchool: boolean;
    @Input() product: Product;
    currency: Currency;
    featureList: string[] = [];

    constructor(
        private stateService: StateService,
        private appService: AppService
    ) {
    }

    ngOnInit() {
        this.currency = this.stateService.getActiveCurrency();
    }

    ngOnChanges() {
        if (this.isSchool) {
            this.featureList = ['lessonPlan', 'trackingPlatform', 'extraDiscount', 'createExercises', 'interestingExercises'];
        } else {
            this.featureList = ['trackingPlatform', 'extraDiscount'];
        }
    }

    ngAfterViewChecked() {
        this.appService.initAnimations('course-organization-features .slideLeft, course-organization-features .slideRight');
    }
}

