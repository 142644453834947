import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AppService} from './app.service';
import {environment} from '../../../environments/environment';
import {Country} from '../../models/country';

@Injectable()
export class CountryService {
    constructor(
        private http: HttpClient,
        private appService: AppService
    ) {}

    countryHasStates(countryCode: string) {
        const countrySettings = this.appService.activeCountries[countryCode];
        return countrySettings && countrySettings.hasStates;
    }

    getAll(): Observable<any[]> {
        return this.http.get<any>(environment.apiUrl + 'countries')
            .pipe(
                map(
                    (response) => {
                        return Object.entries(response).map(
                            (country) => {
                                return new Country(country[0], '' + country[1]);
                            }
                        );
                    }
                )
            );
    }

    getLocalizedList(items: {countryCode: string, languageCode: string}[]) {
        return this.http.get<any>(environment.apiUrl + 'countries/localized_list/' + JSON.stringify(items));
    }
}
