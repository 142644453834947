import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, first, map, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Chapter} from '../../models/chapter';
import {ChapterFactory} from './factories/chapter.factory';
import {StateService} from '../../services/state.service';
import {AppService} from './app.service';
import {User} from '../../models/user';
import {LicenseService} from './license.service';

@Injectable()
export class ChapterService {
    constructor(
        private http: HttpClient,
        private chapterFactory: ChapterFactory,
        private stateService: StateService,
        private appService: AppService,
        private licenseService: LicenseService
    ) {}

    getOne(id: number): Observable<Chapter> {
        return this.stateService.personalSettings.getSetting('disableGames').pipe(
            first(),
            switchMap(
                (disableGames) => {
                    let url = environment.apiUrl + 'chapters/' + id;

                    if (disableGames) {
                        url += '/' + true;
                    }

                    return this.http.get<Chapter>(url)
                        .pipe(
                            map(
                                (response) => {
                                    return this.chapterFactory.map(response);
                                }
                            )
                        );
                }
            )
        );
    }

    getCourseChapters(
        courseId: number,
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string
    ) {
        let url = environment.apiUrl + 'chapters/by_course_sorted/' + courseId;

        if (!filter) {
            filter = '';
        }

        url += '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&filter=' + filter;

        return this.http.get<any>(
            url
        );
    }

    create(
        courseId: number,
        afterChapterId: number,
        title: string,
        icon: string,
        isExam: boolean,
        keyboardHome: boolean,
        keyboardTop: boolean,
        keyboardBottom: boolean,
        keyboardFull: boolean,
        characters: string,
        theme: string
    ) {
        return this.http.post<any>(environment.apiUrl + 'chapters/create', {
            course_id: courseId,
            after_chapter_id: afterChapterId,
            title,
            icon,
            exam: isExam,
            keyboard_home: keyboardHome,
            keyboard_top: keyboardTop,
            keyboard_bottom: keyboardBottom,
            keyboard_full: keyboardFull,
            characters,
            theme
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    update(
        id: number,
        courseId: number,
        afterChapterId: number,
        title: string,
        icon: string,
        isExam: boolean,
        keyboardHome: boolean,
        keyboardTop: boolean,
        keyboardBottom: boolean,
        keyboardFull: boolean,
        characters: string,
        theme: string
    ) {
        return this.http.patch<any>(environment.apiUrl + 'chapters/' + id, {
            course_id: courseId,
            after_chapter_id: afterChapterId,
            title,
            icon,
            exam: isExam,
            keyboard_home: keyboardHome,
            keyboard_top: keyboardTop,
            keyboard_bottom: keyboardBottom,
            keyboard_full: keyboardFull,
            characters,
            theme
        }).pipe(
            map(
                (response) => {
                    return this.chapterFactory.map(response);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + 'chapters/' + id);
    }

    hasChapterAccess(user: User, chapter: Chapter) {
        if (
            chapter.requiresBaseProductId
            && (
                !user
                || !this.licenseService.hasAccessToBaseProductId(user, chapter.requiresBaseProductId)
            )
        ) {
            return false;
        }

        return true;
    }
}
