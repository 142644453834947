import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {KeyboardLayout} from '../../models/keyboardLayout';
import {first, map, switchMap} from 'rxjs/operators';
import {combineLatest, Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {KeyboardLayoutFactory} from './factories/keyboardLayout.factory';
import {AzertyBelgischPunt} from '../../models/keyboardLayouts/azertyBelgischPunt';
import {KeyboardLayoutMap} from '../../models/keyboardLayouts/keyboardLayoutMap';
import {AzertyBelgischPuntApple} from '../../models/keyboardLayouts/azertyBelgischPuntApple';
import {CourseService} from './course.service';
import {UserService} from './user.service';
import {StateService} from '../../services/state.service';


@Injectable()
export class KeyboardLayoutService {
    constructor(
        private http: HttpClient,
        private keyboardLayoutFactory: KeyboardLayoutFactory,
        private courseService: CourseService,
        private stateService: StateService
    ) {
    }

    getByCode(code: string): Observable<KeyboardLayout> {
        return this.http.get<KeyboardLayout>(environment.apiUrl + 'keyboard_layouts/by_code/' + code)
            .pipe(
                map(
                    (response) => {
                        return this.keyboardLayoutFactory.map(response);
                    }
                )
            );
    }

    determineLayout(layoutName?: string): Observable<KeyboardLayoutMap> {
        const isAppleObservable = this.stateService.personalSettings.getSetting('appleKeyboard');

        let nameObservable: Observable<string> = of(layoutName);
        if (!layoutName) {
            /*
            We always get a layoutName from the exercise. The only exception to this are
            the custom organization exercises, which do not belong to any specific course.
            Custom exercises are only available to logged in users, who have a course
            preference in their profile.
            So for custom exercises we need to make a request to get the layout for their course.

            Also for the keyboard knowledge heat map we don't pass a layout name.
             */
            const activeUser = this.stateService.getActiveUser();
            nameObservable = this.courseService.getKeyboardLayout(activeUser.courseId).pipe(
                map(
                    (keyboardLayout: KeyboardLayout) => {
                        return keyboardLayout.code;
                    }
                )
            );
        }

        return combineLatest([
            nameObservable,
            isAppleObservable
        ]).pipe(
            first(),
            switchMap(
                ([name, isApple]) => {
                    switch (name) {
                        case 'azertyBelgischPunt':
                            if (isApple) {
                                return of(AzertyBelgischPuntApple);
                            }
                            return of(AzertyBelgischPunt);
                    }
                }
            )
        );
    }
}
