<course-purchase-individual *ngIf="mainProduct" [product]="mainProduct"></course-purchase-individual>

<div *ngIf="!stateService.getActiveUser()" class="organizations">
    <div class="school">
        <img ngSrc="assets/images/shop/school.webp" alt="school" width="500" height="333" class="slideRight" />
        <div class="productDescription slideLeft">
            <div class="descr">
                <h3 i18n="@@website.shop.forSchools">Tyyp for Schools</h3>
                <p
                    i18n="@@website.shop.schoolInfo"
                >Enjoy extra favourable rates and use the follow-up platform for free! This makes it really easy to follow up your pupils.</p>
            </div>

            <p class="cta">
                <a
                    routerLink="/school-registration"
                    mat-flat-button
                    class="productBtn"
                    i18n="@@website.startFree"
                >Start for free</a>
                <a
                    [routerLink]="'/' + navigationService.getRouteTranslation('forSchools')"
                    mat-stroked-button
                    color="primary"
                    class="infoBtn"
                    i18n="@@moreInfo"
                >More info</a>
            </p>
        </div>
    </div>
</div>

<course-features parentPage="purchase"></course-features>
