<course-modal
    [modalWidth]="480"
    [disableClose]="!canClose"
    [escapeToClose]="canClose"
    [customClass]="'cookieConsent'"
    [disableEnterAnimation]="!canClose"
>
    <ng-container slot="modal">
        <div class="cookieConsentContent">
            <p class="mascotte">
                <img
                    ngSrc="assets/images/cookieConsent/mascotteClip.svg"
                    width="948.81"
                    height="924.96"
                    i18n-alt="@@cookieConsent.mascotteAlt"
                    alt="Mascotte eating cookie"
                />
            </p>
            <div *ngIf="!showSettings" class="copy">
                <h1 i18n="@@cookieConsent.title">Tyyp uses cookies</h1>
                <p  i18n="@@cookieConsent.copy">We use cookies to adjust our website to your preferences and to analyze our website traffic.</p>
            </div>

            <div *ngIf="showSettings" class="settings">
                <div>
                    <mat-slide-toggle
                        disabled="disabled"
                        checked="checked"
                    ></mat-slide-toggle>
                    <div>
                        <h3 i18n="@@cookieConsent.necessary">Necessary</h3>
                        <p i18n="@@cookieConsent.necessaryInfo">These cookies are necessary to make the website and course work.</p>
                    </div>
                </div>

                <div>
                    <mat-slide-toggle
                        [checked]="checkAnalyticalConsent()"
                        #analytical
                    ></mat-slide-toggle>
                    <div>
                        <h3 i18n="@@cookieConsent.analytical">Analytics</h3>
                        <p i18n="@@cookieConsent.analyticalInfo">These cookies help us analyze our website traffic.</p>
                    </div>
                </div>

                <div>
                    <mat-slide-toggle
                        [checked]="checkMarketingConsent()"
                        #marketing
                    ></mat-slide-toggle>
                    <div>
                        <h3 i18n="@@cookieConsent.marketing">Marketing</h3>
                        <p i18n="@@cookieConsent.marketingInfo">With these cookies we can show you personalized advertisements and measure their results.</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container slot="controls">
        <ng-container *ngIf="!showSettings">
            <button mat-stroked-button color="primary" (click)="configure()">
                <mat-icon>settings</mat-icon>
                <ng-container i18n="@@cookieConsent.configure">Configure cookies</ng-container>
            </button>

            <button mat-flat-button color="primary" (click)="confirmAll()">
                <mat-icon>check</mat-icon>
                <ng-container  i18n="@@cookieConsent.accept">Accept</ng-container>
            </button>
        </ng-container>

        <button *ngIf="showSettings" mat-flat-button color="primary" (click)="confirmSettings()">
            <mat-icon>check</mat-icon>
            <ng-container  i18n="@@save">Save</ng-container>
        </button>
    </ng-container>
</course-modal>
