import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LanguageFactory} from './factories/language.factory';
import {AppService} from './app.service';
import {DOCUMENT} from '@angular/common';
import {StateService} from '../../services/state.service';

@Injectable()
export class LanguageService {
    constructor(
        private http: HttpClient,
        private languageFactory: LanguageFactory,
        private appService: AppService,
        private stateService: StateService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LOCALE_ID) public locale: string
    ) {}

    getActiveLanguageCode() {
        return this.locale.split('-')[0];
    }

    getActiveLanguage() {
        return this.appService.languages.filter(
            (language) => language.code === this.getActiveLanguageCode()
        )[0];
    }
}
