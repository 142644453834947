import {Component} from '@angular/core';
import {animations} from '../../../shared/services/animations';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {StateService} from '../../../services/state.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'course-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    animations
})
export class LoadingComponent extends OnDestroyMixin {
    isBlocking: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private stateService: StateService
    ) {
        super();
    }
    ngOnInit() {
        this.initLoading();
    }

    initLoading() {
        this.stateService.getLoadingRequestsObservable().pipe(
            untilComponentDestroyed(this),
        ).subscribe(
            (requests) => {
                this.isBlocking.next(
                    requests.filter(
                        (request) => request.isBlocking
                    ).length > 0
                );

                this.showLoader.next(
                    requests.filter(
                        (request) => request.showLoader
                    ).length > 0
                );
            }
        );
    }
}

