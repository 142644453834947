<div class="container">
    <div class="content">
        <ng-container *ngIf="!isPaid">
            <div class="dialog error">
                <h2 i18n="@@order.paymentCancelled">Your payment was cancelled</h2>
                <p i18n="@@order.paymentCancelledInfo">We did not receive your payment. Please click the button below to complete your payment.</p>
            </div>

            <p>
                <a (click)="retryPayment()" mat-flat-button color="primary" class="largeButton" i18n="@@order.completePayment">Complete payment</a>
            </p>
        </ng-container>

        <ng-container *ngIf="isPaid">
            <div class="sectionHeader">
                <div class="websiteSectionTitle">
                    <h2 i18n="@@purchase.paymentCompleteTitle">Thank you for your order!</h2>
                    <p i18n="@@purchase.paymentComplete">We have sent your license details to your e-mail.</p>

                    <p>
                        <a routerLink="/activate-license" class="keyboardButton">
                            <span i18n="@@activateLicense.activateLicense">Activate your license</span>
                        </a>
                    </p>
                </div>

                <img
                    ngSrc="assets/images/typingCourse/signUpFinal.png"
                    class="heroImg"
                    width="483"
                    height="373"
                    alt=""
                />
            </div>

            <div class="spamboxInfo" i18n="@@purchase.spamBox">
                <p><strong>Didn't receive the e-mail?</strong> Sometimes e-mails arrive with a little delay. If our e-mail doesn't reach you within <strong>30 minutes</strong>, check if it didn't accidentaly end up in your <strong>spambox</strong>!</p>
                <p>
                    Still didn't receive the e-mail?
                    <a [routerLink]="'/' + navigationService.getRouteTranslation('contact')">Send us a message</a>
                    , we're happy to help!
                </p>
            </div>
        </ng-container>
    </div>
</div>
