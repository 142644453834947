import {Component} from '@angular/core';

@Component({
    selector: 'course-organization-question-call',
    templateUrl: './questionCall.component.html',
    styleUrls: ['./questionCall.component.scss'],
})
export class QuestionCallComponent {

}
