import {Injectable} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SaleFactory} from './factories/sale.factory';
import {Sale} from '../../models/sale';
import {environment} from '../../../environments/environment';
import {AppService} from './app.service';

@Injectable()
export class SaleService {
    constructor(
        private http: HttpClient,
        private appService: AppService,
        private saleFactory: SaleFactory
    ) {}

    getAll(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string,
        recurring: boolean,
        includeActive: boolean,
        includeInactive: boolean
    ) {
        if (!filter) {
            filter = '';
        }

        return this.http.get<any>(
            environment.apiUrl + 'sales/all'
            + '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&filter=' + filter
            + '&recurring=' + (recurring ? 1 : 0)
            + '&include-active=' + (includeActive ? 1 : 0)
            + '&include-inactive=' + (includeInactive ? 1 : 0)
        );
    }

    getOne(id: number | string) {
        return this.http.get<Sale>(environment.apiUrl + 'sales/' + id)
            .pipe(
                map(
                    (response) => {
                        return this.saleFactory.map(response);
                    }
                )
            );
    }

    create(
        name: string,
        productId: number,
        percentage: number,
        validFrom: string,
        validUntil: string,
        recurring: boolean
    ) {
        return this.http.post<any>(environment.apiUrl + 'sales/create', {
            name,
            product_id: productId,
            percentage,
            valid_from: validFrom,
            valid_until: validUntil,
            recurring
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    update(
        id: number,
        name: string,
        productId: number,
        percentage: number,
        validFrom: string,
        validUntil: string,
        recurring: boolean
    ) {
        return this.http.patch<any>(environment.apiUrl + 'sales/' + id, {
            name,
            product_id: productId,
            percentage,
            valid_from: validFrom,
            valid_until: validUntil,
            recurring
        }).pipe(
            map(
                (response) => {
                    return this.saleFactory.map(response);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + 'sales/' + id);
    }

    checkActive(sale: Sale) {
        const now = Date.now();
        const thisYear = new Date().getFullYear();
        let from = sale.validFrom;
        let until = sale.validUntil;

        if (sale.recurring) {
            const fromDate = new Date(from);
            const untilDate = new Date(until);

            // sale can run over multiple years (f.e. from 25/12 until 5/1)
            let untilYearDif = 0;
            if (fromDate.getMonth() > untilDate.getMonth()) {
                untilYearDif = 1;
            }

            // set the from date to the current year
            let year = thisYear;
            fromDate.setFullYear(year);

            // if the from date is yet to come in this year, we try previous year
            if (fromDate.getTime() > now) {
                year -= 1;
            }

            fromDate.setFullYear(year);
            untilDate.setFullYear(year + untilYearDif);

            // until -> end of the day
            untilDate.setHours(23);
            untilDate.setMinutes(59);
            untilDate.setSeconds(59);

            from = fromDate.getTime();
            until = untilDate.getTime();
        }

        return now > from && now < until;
    }
}
