<div class="container">
    <h1>Speel de spannendste typerace</h1>
    <h2>op Nerdland Festival 2024!</h2>
    <p class="info">
        Neem plaats in de Tyyp Race Arena en kies je personage! Typ zo snel en juist mogelijk om power-ups te unlocken. Alleen zo kan je de race winnen. Kom je skills testen en ontdek de kracht van blind typen. Niet gewonnen? Geen probleem! Iedereen kan leren typen met de online typecursus van Tyyp.
    </p>

    <p class="gameImage">
        <img
            ngSrc="assets/images/typerace/typerace.webp"
            alt="TypeRace"
            width="900"
            height="467"
        />
    </p>

    <p>
        <a href="https://www.nerdlandfestival.be/nl/" target="_blank">
            <img
                ngSrc="assets/images/typerace/nerdland.png"
                alt="TypeRace"
                width="1079"
                height="1079"
                class="nerdland"
            />
        </a>
    </p>
</div>
