import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, first} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {UserService} from '../../../shared/services/user.service';
import {StateService} from '../../../services/state.service';

@Component({
    selector: 'course-reset-password',
    templateUrl: './resetPassword.component.html',
    styleUrls: ['./resetPassword.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    success = false;
    hasExpiredError = false;
    form = new FormGroup({
        password: new FormControl<string | null>(null),
        repeatPassword: new FormControl<string | null>(null)
    });
    errors: {[field: string]: string[]} = {};
    hidePassword = true;
    hidePassword2 = true;



    constructor(
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private stateService: StateService
    ) {}

    ngOnInit() {
        this.subscribeToActiveUser();
    }

    subscribeToActiveUser() {
        this.stateService.getActiveUserObservable().subscribe(
            (activeUser) => {
                if (activeUser) {
                    this.router.navigate(['/']);
                }
            }
        );
    }

    submit() {
        this.hasExpiredError = false;

        const password = this.form.get('password').value;
        const repeatPassword = this.form.get('repeatPassword').value;

        const userId = this.activatedRoute.snapshot.params.userId;
        const code = this.activatedRoute.snapshot.params.code;

        this.userService.submitPasswordReset(password, repeatPassword, userId, code).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.error === 'requestExpired') {
                        this.hasExpiredError = true;
                    }

                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        this.form.controls.password.markAsTouched();
                        this.form.controls.password.setErrors(this.errors.password);

                        this.form.controls.repeatPassword.markAsTouched();
                        this.form.controls.repeatPassword.setErrors(this.errors.repeat_password);
                    }

                    return NEVER;
                }
            )
        ).subscribe(
            () => {
                this.success = true;
            }
        );

        return false;
    }
}

