import {Component, Renderer2} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {catchError, first} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {ContactService} from '../../../shared/services/contact.service';

/**
 * NOTE: if we ever want to protect this form, FriendlyCaptcha seems like a good option.
 */
@Component({
    selector: 'course-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
    errors: {[field: string]: string[]} = {};
    success = false;

    form = new FormGroup({
        name: new FormControl<string | null>(''),
        email: new FormControl<string | null>(''),
        message: new FormControl<string | null>('')
    });


    constructor(
        private renderer: Renderer2,
        private contactService: ContactService
    ) {}

    reset() {
        this.success = false;
        this.form.controls.message.reset();
    }

    submit() {
        this.contactService.send(
            this.form.controls.name.value,
            this.form.controls.email.value,
            this.form.controls.message.value,
        ).pipe(
            first(),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.errors = err.error.errors;

                        if (err.error.errors) {
                            this.form.controls.name.setErrors(err.error.errors.name);
                            this.form.controls.email.setErrors(err.error.errors.email);
                            this.form.controls.message.setErrors(err.error.errors.message);
                        }
                    }

                    return NEVER;
                }
            )
        ).subscribe(
            () => {
                this.errors = {};
                this.success = true;
            }
        );
    }
}

