import {Injectable, Injector} from '@angular/core';
import {ExerciseFactory} from './exercise.factory';
import {KeyboardZones} from '../../../models/enums/keyboardZones';
import {Lesson} from '../../../models/lesson';
import {ChapterFactory} from './chapter.factory';

@Injectable()
export class LessonFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        const exerciseFactory = this.injector.get(ExerciseFactory);
        const chapterFactory = this.injector.get(ChapterFactory);

        // keyboard zones
        const zones = [];
        if (response.keyboard_home) {
            zones.push(KeyboardZones.Home);
        }
        if (response.keyboard_top) {
            zones.push(KeyboardZones.Top);
        }
        if (response.keyboard_bottom) {
            zones.push(KeyboardZones.Bottom);
        }
        if (response.keyboard_full) {
            zones.push(KeyboardZones.Full);
        }

        // chapter
        return new Lesson(
            response.id,
            response.title,
            response.icon,
            response.chapter_id,
            response.course_id,
            response.position,
            response.exam,
            response.basic_exam,
            response.licensed_only,
            response.exercises ? response.exercises.map(exercise => exerciseFactory.map(exercise)) : [],
            response.chapter ? chapterFactory.map(response.chapter) : undefined,
            response.exercises_count ? response.exercises_count : undefined,
            response.global_position ? response.global_position : undefined
        );
    }
}
