import {Injectable, Injector} from '@angular/core';
import {Exercise} from '../../../models/exercise';
import {ChapterFactory} from './chapter.factory';
import {LessonFactory} from './lesson.factory';
import {UserFactory} from './user.factory';
import {OrganizationFactory} from './organization.factory';
import {ProductFactory} from './product.factory';
import {License} from '../../../models/license';

@Injectable()
export class LicenseFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        const productFactory = this.injector.get(ProductFactory);

        return new License(
            response.id,
            response.user_id,
            response.product ? productFactory.map(response.product) : undefined,
            response.claimed_at,
            response.valid_until
        );
    }
}
