export abstract class FormErrors {
    public static required = $localize `:@@errors.required:This field is required.`;

    public static addStudentsErrors = {
        nameRequired: $localize `:@@organization.addStudentsErrors.nameRequired:Please fill in the name.`,
        firstNameRequired: $localize `:@@organization.addStudentsErrors.firstNameRequired:Please fill in the first name.`,
        emailOrUsernameRequired: $localize `:@@organization.addStudentsErrors.emailOrUsernameRequired:Please fill in either the e-mail or username.`,
        invalidEmail: $localize `:@@organization.addStudentsErrors.invalidEmail:Invalid e-mail address.`,
        emailAlreadyInUse: $localize `:@@organization.addStudentsErrors.emailAlreadyInUse:This e-mail is already in use.`,
        usernameAlreadyInUse: $localize `:@@organization.addStudentsErrors.usernameAlreadyInUse:Someone is already using this username. Please pick a unique username, f.e. Tom.Hanks`
    };
}
