<div class="howDoesItWork">
    <div class="instructions slideRight">
        <h2>
            <ng-container
                *ngIf="isSchool"
                i18n="@@website.how.titleSchool"
            >How to get started in your school?</ng-container>
            <ng-container
                *ngIf="!isSchool"
                i18n="@@website.how.titleBusiness"
            >How to get started in your company?</ng-container>
        </h2>
        <ol *ngIf="isSchool">
            <li
                i18n="@@website.how.school1"
            ><strong>Register your school</strong> - it's fast and free!</li>
            <li
                i18n="@@website.how.school2"
            >Create your <strong>classes</strong>.</li>
            <li
                i18n="@@website.how.school3"
            ><strong>Enroll your students</strong> or let them enroll themselves using the class code.</li>
            <li
                i18n="@@website.how.school4"
            >If desired, link <strong>teachers</strong> to classes. They can then follow up their own <strong>students</strong>.</li>
        </ol>
        <ol *ngIf="!isSchool">
            <li
                i18n="@@website.how.business1"
            ><strong>Register your company</strong> - it's fast and free!</li>
            <li
                i18n="@@website.how.business2"
            >Create one or more <strong>groups</strong>.</li>
            <li
                i18n="@@website.how.business3"
            ><strong>Enroll your participants</strong> or let them enroll themselves via the group code.</li>
            <li
                i18n="@@website.how.business4"
            >If desired, link <strong>instructors</strong> to groups. They can then follow up their own <strong>participants</strong>.</li>
        </ol>

        <p class="cta">
            <a
                routerLink="/{{ isSchool ? 'school' : 'business' }}-registration"
                mat-stroked-button
                color="primary"
                class="websiteButton"
                i18n="@@website.startFree"
            >Start for free</a>
        </p>
    </div>

    <div class="img">
        <img
            *ngIf="isSchool"
            ngSrc="assets/images/typingCourse/features/typing_course_schools.webp"
            i18n-alt="@@website.how.schoolsAlt"
            alt="Learn to type in school"
            class="slideLeft"
            width="500"
            height="600"
        />
        <img
            *ngIf="!isSchool"
            ngSrc="assets/images/typingCourse/features/typing_course_businesses.webp"
            i18n-alt="@@website.how.businessesAlt"
            alt="Learn to type at work"
            class="slideLeft"
            width="500"
            height="600"
        />
    </div>
</div>
