<p class="orLogInWith">
    <span *ngIf="signUp" i18n="@@orSignUpWith">Or sign up with</span>
    <span *ngIf="!signUp" i18n="@@orLogInWith">Or log in with</span>
</p>

<div class="ssoButtons">
    <a *ngIf="enableLeerId" href="{{ environment.apiUrl }}auth/leer-id/authenticate" class="leerID">
        <img ngSrc="assets/images/sso/leerID.svg" width="20" height="20" alt="LeerID" /> LeerID
    </a>

    <a (click)="loginWithMicrosoft()" class="microsoft">
        <img ngSrc="assets/images/sso/microsoft.svg" width="21" height="21" alt="Microsoft" /> Microsoft
    </a>

    <div class="googleLoginBtn">
        <div id="g_id_onload"
             [attr.data-client_id]="googleOAuthClientId"
             data-context="signin"
             data-ux_mode="popup"
             data-callback="onGoogleSignIn"
             data-auto_prompt="false">
        </div>

        <div class="g_id_signin"
             data-type="standard"
             data-shape="pill"
             data-theme="filled_blue"
             [attr.data-text]="signUp ? 'signup_with' : 'signin_with'"
             data-size="medium"
             [attr.data-locale]="locale"
             data-logo_alignment="left">
        </div>
    </div>
</div>
