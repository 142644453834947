import {Key} from '../key';
import {SpecialKeys} from '../enums/specialKeys';
import {KeyboardZones} from '../enums/keyboardZones';
import {DeadKey, DeadKeys} from './deadKeys';

export abstract class AzertyBelgischPuntApple {
    public static layoutName = 'Azerty (Belgisch Punt)';
    public static isApple = true;
    public static deadKeys: DeadKey[] = [
        DeadKeys.azertyCirconflexe,
        DeadKeys.azertyDiaeresis
    ];
    public static keyRows = [
        [
            new Key('Backquote', 1, ['#', '@'], KeyboardZones.Full),
            new Key('Digit1', 1, ['1', '&'], KeyboardZones.Full),
            new Key('Digit2', 2, ['2', 'é'], KeyboardZones.Full),
            new Key('Digit3', 3, ['3', '"'], KeyboardZones.Full),
            new Key('Digit4', 4, ['4', '\''], KeyboardZones.Full),
            new Key('Digit5', 4, ['5', '('], KeyboardZones.Full),
            new Key('Digit6', 7, ['6', '§'], KeyboardZones.Full),
            new Key('Digit7', 7, ['7', 'è'], KeyboardZones.Full),
            new Key('Digit8', 8, ['8', '!'], KeyboardZones.Full),
            new Key('Digit9', 9, ['9', 'ç'], KeyboardZones.Full),
            new Key('Digit0', 10, ['0', 'à'], KeyboardZones.Full),
            new Key('Minus', 10, ['°', ')'], KeyboardZones.Full),
            new Key('Equal', 10, ['_', '-'], KeyboardZones.Full),
            new Key('Backspace', 10, [], KeyboardZones.Full, SpecialKeys.BackSpace, 2)
        ],
        [
            new Key('Tab', 1, [], KeyboardZones.Full, SpecialKeys.Tab, 1.5),
            new Key('KeyQ', 1, [null, 'a'], KeyboardZones.Top),
            new Key('KeyW', 2, [null, 'z'], KeyboardZones.Top),
            new Key('KeyE', 3, [null, 'e'], KeyboardZones.Top),
            new Key('KeyR', 4, [null, 'r'], KeyboardZones.Top),
            new Key('KeyT', 4, [null, 't'], KeyboardZones.Top),
            new Key('KeyY', 7, [null, 'y'], KeyboardZones.Top),
            new Key('KeyU', 7, [null, 'u'], KeyboardZones.Top),
            new Key('KeyI', 8, [null, 'i'], KeyboardZones.Top),
            new Key('KeyO', 9, [null, 'o'], KeyboardZones.Top),
            new Key('KeyP', 10, [null, 'p'], KeyboardZones.Top),
            new Key(
                'BracketLeft',
                10,
                ['¨', '^'],
                KeyboardZones.Full,
                undefined,
                undefined,
                false,
                false,
                ['¨', '^']
            ),
            new Key('BracketRight', 10, ['*', '$', '€'], KeyboardZones.Full),
            new Key('Enter', 10, [], KeyboardZones.Full, SpecialKeys.Enter, 1.5, true)
        ],
        [
            new Key('CapsLock', 1, [], KeyboardZones.Full, SpecialKeys.CapsLock, 1.8),
            new Key('KeyA', 1, [null, 'q'], KeyboardZones.Home),
            new Key('KeyS', 2, [null, 's'], KeyboardZones.Home),
            new Key('KeyD', 3, [null, 'd'], KeyboardZones.Home),
            new Key('KeyF', 4, [null, 'f'], KeyboardZones.Home, undefined, undefined, false, true),
            new Key('KeyG', 4, [null, 'g'], KeyboardZones.Home),
            new Key('KeyH', 7, [null, 'h'], KeyboardZones.Home),
            new Key('KeyJ', 7, [null, 'j'], KeyboardZones.Home, undefined, undefined, false, true),
            new Key('KeyK', 8, [null, 'k'], KeyboardZones.Home),
            new Key('KeyL', 9, [null, 'l'], KeyboardZones.Home),
            new Key('Semicolon', 10, [null, 'm'], KeyboardZones.Home),
            new Key('Quote', 10, ['%', 'ù'], KeyboardZones.Full),
            new Key('Backslash', 10, ['£', '`'], KeyboardZones.Full)
        ],
        [
            new Key('ShiftLeft', 1, [], KeyboardZones.Full, SpecialKeys.LShift, 1.2),
            new Key('IntlBackslash', 1, ['>', '<'], KeyboardZones.Full),
            new Key('KeyZ', 1, [null, 'w'], KeyboardZones.Bottom),
            new Key('KeyX', 2, [null, 'x'], KeyboardZones.Bottom),
            new Key('KeyC', 3, [null, 'c'], KeyboardZones.Bottom),
            new Key('KeyV', 4, [null, 'v'], KeyboardZones.Bottom),
            new Key('KeyB', 4, [null, 'b'], KeyboardZones.Bottom),
            new Key('KeyN', 7, [null, 'n'], KeyboardZones.Bottom),
            new Key('KeyM', 7, ['?', ','], KeyboardZones.Bottom),
            new Key('Comma', 8, ['.', ';'], KeyboardZones.Bottom),
            new Key('Period', 9, ['/', ':'], KeyboardZones.Bottom),
            new Key('Slash', 10, ['+', '='], KeyboardZones.Bottom),
            new Key('ShiftRight', 10, [], KeyboardZones.Full, SpecialKeys.RShift, 2.86)
        ],
        [
            new Key('ControlLeft', 1, [], KeyboardZones.Full, SpecialKeys.LControl, 1.48),
            new Key('AltLeft', 1, [], KeyboardZones.Full, SpecialKeys.LOption, 1.3),
            new Key('MetaLeft', 5, [], KeyboardZones.Full, SpecialKeys.LCommand, 1.48),
            new Key('Space', 6, [], KeyboardZones.Home, SpecialKeys.SpaceBar, 6.93),
            new Key('MetaRight', 6, [], KeyboardZones.Full, SpecialKeys.RCommand, 1.48),
            new Key('AltRight', 10, [], KeyboardZones.Full, SpecialKeys.ROption, 1.3),
            new Key('ControlRight', 10, [], KeyboardZones.Full, SpecialKeys.RControl, 1.48),
        ]
    ];
}
