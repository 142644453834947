import {Component, Input, OnInit} from '@angular/core';
import {catchError, first} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {Course} from '../../../models/course';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../services/user.service';
import {CourseService} from '../../services/course.service';
import {LanguageService} from '../../services/language.service';
import {ModalService} from '../../services/modal.service';
import {NavigationService} from '../../services/navigation.service';
import {StateService} from '../../../services/state.service';

@Component({
    selector: 'course-keyboard-settings',
    templateUrl: './keyboardSettings.component.html',
    styleUrls: ['./keyboardSettings.component.scss'],
})
export class KeyboardSettingsComponent extends OnDestroyMixin implements OnInit {
    @Input() layoutName: string;
    @Input() isKeyboardDialog = false;
    isApple = false;
    isEditingLayout = false;
    courses: Course[] = [];
    myLanguageCourses: Course[] = [];
    otherCourses: Course[] = [];
    initialCourseId: number;
    canEditCourse: boolean;

    constructor(
        public userService: UserService,
        private courseService: CourseService,
        private languageService: LanguageService,
        private snackBar: MatSnackBar,
        private router: Router,
        private modalService: ModalService,
        private navigationService: NavigationService,
        private stateService: StateService
    ) {
        super();
    }

    ngOnInit() {
        this.initialCourseId = this.getCurrentCourseId();

        this.stateService.personalSettings.getSetting('appleKeyboard').pipe(
            untilComponentDestroyed(this)
        ).subscribe(
            (isApple) => {
                this.isApple = isApple;
            }
        );

        this.stateService.personalSettings.getSetting('allowUpdateCourse').pipe(
            untilComponentDestroyed(this)
        ).subscribe(
            (canEdit) => {
                this.canEditCourse = canEdit;
            }
        );

        this.groupCourses();
    }

    changeApple(evt) {
        const isApple = evt.checked;

        this.userService.updatePreference('appleKeyboard', isApple).pipe(
            first()
        ).subscribe(
            () => {
            }
        );
    }

    editCourse() {
        if (this.courses.length > 0) {
            this.isEditingLayout = true;
        } else {
            this.courseService.getAll().pipe(
                first(),
                catchError(
                    (err) => {
                        return NEVER;
                    }
                )
            ).subscribe(
                (courses) => {
                    this.courses = courses;
                    this.groupCourses();
                    this.isEditingLayout = true;
                }
            );
        }
    }

    groupCourses() {
        const languageId = this.languageService.getActiveLanguage().id;

        this.myLanguageCourses = this.courses.filter(
            (course) => course.languageId === languageId
        );

        this.otherCourses = this.courses.filter(
            (course) => course.languageId !== languageId
        );
    }

    getCurrentCourseId() {
        if (this.stateService.getActiveUser()) {
            // logged in: get from account
            return this.stateService.getActiveUser().courseId;
        } else {
            // guest: get from localStorage
            return parseInt(localStorage.getItem('courseId'), 10);
        }
    }

    confirmChangeCourse(id) {
        // set current course name in this component
        this.layoutName = this.courses.filter(
            (course) => course.id === parseInt(id, 10)
        )[0].title;

        // save course preference
        if (this.stateService.getActiveUser()) {
            // logged in: save on api
            this.userService.updateCourse(id).pipe(
                first()
            ).subscribe(
                () => {
                    // edit activeUser course in userService
                    const user = this.stateService.getActiveUser();
                    user.courseId = id;
                    user.course = undefined;

                    this.stateService.setActiveUser(user);

                    // edit active course
                    this.stateService.setActiveCourseId(id);

                    this.showConfirmation(id);
                }
            );
        } else {
            // guest: update localstorage
            localStorage.setItem('courseId', id);

            // edit active course
            this.stateService.setActiveCourseId(id);

            this.showConfirmation(id);
        }
    }

    showConfirmation(id) {
        this.isEditingLayout = false;

        this.snackBar.open(
            $localize`:@@account.changeCourseSuccess:Your course has been changed!`,
            '',
            {
                duration: 5000,
                panelClass: ['successSnackbar']
            }
        );

        if (this.isKeyboardDialog && this.initialCourseId !== parseInt(id, 10)) {
            this.router.navigate(['/' + this.navigationService.getRouteTranslation('course')]);
            this.modalService.closeModal();
        }
    }

    cancelChangeCourse() {
        this.isEditingLayout = false;
    }
}

