import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform {

    transform(value: any): any {
        value = parseInt(value, 10);

        return this.formatDuration(value);
    }

    formatDuration(ms: number) {
        // ms to seconds
        let seconds = Math.round(ms / 1000);

        // hours
        const hours = Math.floor(seconds / 3600);

        // minutes
        seconds = seconds % 3600;
        const minutes = Math.floor(seconds / 60);

        // seconds
        seconds = seconds % 60;

        // compose string
        let str = '';
        if (hours > 0) {
            str += String(hours).padStart(2, '0') + ':';
        }

        str += String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');

        return str;
    }
}
