import {Component, Input} from '@angular/core';
import {Product} from '../../../../models/product';

@Component({
    selector: 'course-school-features',
    templateUrl: './schoolFeatures.component.html',
    styleUrls: ['./schoolFeatures.component.scss'],
})
export class SchoolFeaturesComponent {
    @Input() product: Product;

}

