import {OrganizationSettings} from './organizationSettings';
import {OrganizationProductCategory} from './organizationProductCategory';

export class Organization {
    constructor(
        public id: number,
        public name: string,
        public isSchool: boolean,
        public taxNumber: string,
        public address: string,
        public city: string,
        public state: string,
        public postalCode: string,
        public countryCode: string,
        public billingEmail: string,
        public billingName: string,
        public billingTaxNumber: string,
        public billingAddress: string,
        public billingCity: string,
        public billingState: string,
        public billingPostalCode: string,
        public billingCountryCode: string,
        public createdAt: number,
        public studentsCount?: number,
        public instructorsCount?: number,
        public licensesCount?: number,
        public settings?: OrganizationSettings,
        public organizationTypeId?: number,
        public productCategories?: OrganizationProductCategory
    ) {}
}
