import {Component, Inject, Input, OnInit} from '@angular/core';
import {LocalizedLanguages} from '../../../../models/abstract/localizedLanguages';
import {AppService} from '../../../../shared/services/app.service';
import {LanguageService} from '../../../../shared/services/language.service';
import {first} from 'rxjs/operators';
import {CountryService} from '../../../../shared/services/country.service';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'course-language-menu',
    templateUrl: './languageMenu.component.html',
    styleUrls: ['./languageMenu.component.scss'],
})
export class LanguageMenuComponent implements OnInit {
    @Input() smallMobileMode: boolean; // cart icon takes up space, so we make the language menu smaller on mobile
    activeOption;
    options: {
        countryCode: string,
        country: string,
        languageCode: string,
        language: string,
        url: string,
    }[] = [];
    localizedCountries;

    constructor(
        private appService: AppService,
        public languageService: LanguageService,
        private countryService: CountryService,
        @Inject(DOCUMENT) private document: any
    ) {
    }

    ngOnInit() {
        this.getLocalizedCountries();
    }

    getLocalizedCountries() {
        const list: {
            countryCode: string,
            languageCode: string
        }[] = [];

        Object.keys(this.appService.domainDefaults).forEach(
            (domain) => {
                const defaults = this.appService.domainDefaults[domain];

                if (defaults.countryCode) {
                    list.push(
                        {
                            countryCode: defaults.countryCode,
                            languageCode: defaults.language
                        }
                    );
                }
            }
        );


        this.countryService.getLocalizedList(list).pipe(
            first()
        ).subscribe(
            (localizedCountries) => {
                this.localizedCountries = localizedCountries;
                this.setOptions();
            }
        );
    }

    setOptions() {
        const hostname = this.appService.getHostname().replace('www.', '');

        const options = this.appService.domainDefaults;
        Object.keys(options).forEach(
            (domain) => {
                const defaults = options[domain];

                const option = {
                    countryCode: defaults.countryCode || 'EARTH',
                    country: this.localizedCountries[defaults.countryCode + '-' + defaults.language] || 'International',
                    languageCode: defaults.language,
                    language: LocalizedLanguages[defaults.language],
                    url: 'https://www.' + domain
                };

                this.options.push(option);

                // active option?
                if (
                    hostname === domain
                    || hostname === 'dev-' + domain
                    || hostname === 'devprod-' + domain
                ) {
                    this.activeOption = option;
                }
            }
        );
    }
}

