<div class="aboutUs">
    <img
        ngSrc="assets/images/team/overview/team.webp"
        i18n-alt="@@website.about.teamAlt"
        alt="Tyyp team"
        [class.slideRight]="stateService.getFirstHeroAnimationSkipped()"
        width="500"
        height="375"
        priority="priority"
        class="aboutUsImg roundedImg"
    />
    <div
        [class.slideLeft]="stateService.getFirstHeroAnimationSkipped()"
        class="aboutUsDescription"
    >
        <h1>
            <ng-container i18n="@@website.aboutUs.hey">Hey!</ng-container>
            <br />
            <strong i18n="@@website.aboutUs.weAreTyyp">We are Tyyp</strong> 👋
        </h1>
        <div class="aboutUsInfo">
            <p i18n="@@website.aboutUs.subTitle">We create fun courses that get students excited about learning to type through play.</p>
        </div>
    </div>
</div>


<div class="ourStoryContainer">
    <div class="ourStory">
        <div class="ourStoryDescription slideRight">
            <h2 i18n="@@website.aboutUs.ourStoryTitle">Our story</h2>
            <div class="ourStoryInfo" i18n="@@website.aboutUs.ourStory">
                <p>
                    Tyyp was founded in 2021 on the belief that <strong>learning to type can also just be fun!</strong> Founder Kris noticed that young people - because of their smartphones - are becoming less and less handy with the computer, while <strong>it is becoming increasingly important for their studies and careers.</strong> Many existing typing lessons were either so boring they put you to sleep, or they just looked more like flashy games with no real content. And let's face it: typing lessons are often quite pricey, while accessibility is usually seen as an afterthought. There really was room for improvement!
                </p>
                <p>
                    So Kris and illustrator Eline have spent two years enthusiastically working on a <strong>playful learning method</strong>, full of cheerful illustrations and fun games. A course that really makes <strong>students want to keep practising!</strong>
                </p>
                <p>
                    And it doesn't stop there! At the end of 2023, we welcomed Ken and Thijs, two enthusiastic newcomers full of fresh ideas. They are committed to getting even more pupils <strong>ready for their digital future.</strong> Ken is not to be missed, whether you meet him in a webinar, a demo or at a trade fair - he helps teachers get started in the <strong>follow-up platform</strong> with a smile!
                </p>
                <p>
                    In short, we keep working hard to make the course even better and more fun, so that <strong>everyone can really enjoy learning to type!</strong>
                </p>
            </div>
        </div>
        <img
            ngSrc="assets/images/team/overview/ken_demo.webp"
            i18n-alt="@@website.about.storyAlt"
            alt="Ken demo"
            width="500"
            height="333"
            class="slideLeft ourStoryImg roundedImg"
        />
    </div>
</div>


<div class="ourValues">
    <div class="websiteSectionTitle">
        <h2 i18n="@@website.aboutUs.ourValuesTitle">Our values</h2>
        <p i18n="@@website.aboutUs.ourValues">At Tyyp, we think it is very important that truly everyone can participate! This thought is the common thread in all our core values. Learning to type should be accessible and fun for everyone, so that everyone can participate in this adventure together!</p>
    </div>

    <div class="values">
        <div class="value slideUp">
            <h3>
                <mat-icon>accessibility</mat-icon>
                <ng-container i18n="@@website.aboutUs.accessibilityTitle">Accessible to all</ng-container>
            </h3>
            <ng-container i18n="@@website.aboutUs.accessibility">
                <p>
                    When building the course, we took extra care to accommodate learners with <strong>dyslexia</strong> and <strong>autism</strong>. We have also added several accessibility settings for <strong>visually impaired, colour-blind</strong> and <strong>deaf students.</strong>
                    <br />
                    This way, the whole class can participate and no one is excluded!
                </p>
            </ng-container>
        </div>

        <div class="value slideUp">
            <h3>
                <mat-icon>savings</mat-icon>
                <ng-container i18n="@@website.aboutUs.affordableTitle">Affordable</ng-container>
            </h3>
            <ng-container i18n="@@website.aboutUs.affordable">
                <p>
                    Typing lessons can often be quite pricey, leaving some students out of the loop. However, our course is so complete that <strong>no specialised instructor is needed!</strong>
                </p>
                <p>
                    Thanks to the <strong>preferential rate for schools</strong>, the course remains accessible to every child.
                </p>
            </ng-container>
        </div>

        <div class="value slideUp">
            <h3>
                <mat-icon>celebration</mat-icon>
                <ng-container i18n="@@website.aboutUs.funTitle">Learning is fun</ng-container>
            </h3>
            <ng-container i18n="@@website.aboutUs.fun">
                <p>
                    Who says learning has to be boring? We make it fun by alternating exercises with <strong>exciting games</strong> and <strong>cheerful instructional videos</strong> to keep students plenty excited. As extra motivation, students can earn <strong>fun badges</strong> for their achievements! This makes practising not only fun, but also a rewarding experience. Because practice makes perfect!
                </p>
            </ng-container>
        </div>

        <div class="value slideUp">
            <h3>
                <mat-icon>school</mat-icon>
                <ng-container i18n="@@website.aboutUs.educationalTitle">Educationally sound</ng-container>
            </h3>
            <ng-container i18n="@@website.aboutUs.educational">
                <p>
                    We are not fond of flashy games that offer no real educational value. Learning should be fun, but it is equally important that <strong>pupils get something out of it!</strong>
                </p>
                <p>
                    Thus, playtime becomes not only fun, but also a great opportunity to learn new things!
                </p>
            </ng-container>
        </div>
    </div>
</div>


<div class="slideshow">
    <div class="slideshowImages">
        <img
            ngSrc="assets/images/team/slideshow/1.webp"
            width="450"
            height="300"
            class="roundedImg"
            priority="priority"
        />

        <img
            ngSrc="assets/images/team/slideshow/7.webp"
            width="400"
            height="300"
            class="roundedImg"
            priority="priority"
        />

        <img
            ngSrc="assets/images/team/slideshow/2.webp"
            width="400"
            height="300"
            class="roundedImg"
            priority="priority"
        />

        <img
            ngSrc="assets/images/team/slideshow/3.webp"
            width="400"
            height="300"
            class="roundedImg"
            priority="priority"
        />

        <img
            ngSrc="assets/images/team/slideshow/4.webp"
            width="450"
            height="300"
            class="roundedImg"
            priority="priority"
        />

        <img
            ngSrc="assets/images/team/slideshow/8.webp"
            width="400"
            height="300"
            class="roundedImg"
            priority="priority"
        />

        <img
            ngSrc="assets/images/team/slideshow/5.webp"
            width="400"
            height="300"
            class="roundedImg"
            priority="priority"
        />

        <img
            ngSrc="assets/images/team/slideshow/6.webp"
            width="450"
            height="300"
            class="roundedImg"
            priority="priority"
        />
    </div>
</div>


<div class="numbersContainer">
    <div class="numbers">
        <h2 i18n="@@website.aboutUs.numbersTitle">Tyyp in figures</h2>

        <div class="numberStats">
            <div class="numberStat">
                <div class="number">
                    <span
                        [countUp]="aboutStats.gamesPlayed.nr"
                        [options]="{ enableScrollSpy: true, decimalPlaces: aboutStats.gamesPlayed.decimalPlaces, decimal: ',' }"
                    >0</span>{{ aboutStats.gamesPlayed.symbol }}
                </div>
                <div class="numberDescr" i18n="@@website.aboutUs.gamesPlayed">games played</div>
            </div>

            <div class="numberStat">
                <div class="number">
                    <span
                        [countUp]="aboutStats.videosWatched.nr"
                        [options]="{ enableScrollSpy: true, decimalPlaces: aboutStats.videosWatched.decimalPlaces, decimal: ',' }"
                    >0</span>{{ aboutStats.videosWatched.symbol }}
                </div>
                <div class="numberDescr" i18n="@@website.aboutUs.videosWatched">videos watched</div>
            </div>

            <div class="numberStat">
                <div class="number">
                    <span
                        [countUp]="aboutStats.mistakesTyped.nr"
                        [options]="{ enableScrollSpy: true, decimalPlaces: aboutStats.mistakesTyped.decimalPlaces, decimal: ',' }"
                    >0</span>{{ aboutStats.mistakesTyped.symbol }}
                </div>
                <div class="numberDescr" i18n="@@website.aboutUs.mistakesTyped">mistakes types</div>
            </div>
        </div>
    </div>
</div>


<div class="team">
    <div class="websiteSectionTitle">
        <h2 i18n="@@website.aboutUs.ourTeamTitle">Get to know our team</h2>
        <p i18n="@@website.aboutUs.ourTeamSubTitle">Meet the happy faces of our enthusiastic team! Every day, they ensure that thousands of students enjoy learning to type.</p>
    </div>

    <div class="teamMembers">
        <div class="teamMember slideUp">
            <img
                ngSrc="assets/images/team/members/kris.webp"
                width="180"
                height="270"
                alt="Kris"
            />
            <div>
                <p class="name">Kris Van Roosbroeck</p>
                <p class="title" i18n="@@website.aboutUs.krisTitle">Founder</p>
                <p class="story" i18n="@@website.aboutUs.krisStory">
                    Kris is the creative engine and founder of Tyyp. For {{ krisCareerLength }} years, he has been tinkering with the most fun digital courses, learning platforms and educational games. He also enjoys <em>‘researching’</em> new games in his spare time. At home, Kris has three free-roaming bunnies and one crawling baby.
                </p>
            </div>
        </div>

        <div class="teamMember slideUp">
            <img
                ngSrc="assets/images/team/members/ken.webp"
                width="180"
                height="270"
                alt="Ken"
            />
            <div>
                <p class="name">Ken De Pooter</p>
                <p class="title" i18n="@@website.aboutUs.kenTitle">Point of Contact for School</p>
                <p class="story" i18n="@@website.aboutUs.kenStory">
                    Ken loves writing and likes to explain. You can come to him for events, all Tyyp questions, game design and good conversation. So his favourite quote is: <em>‘We don't stop playing because we get older. We get older because we stop playing.’</em> - George Bernard Shaw
                </p>
            </div>
        </div>

        <div class="teamMember slideUp">
            <img
                ngSrc="assets/images/team/members/thijs.webp"
                width="180"
                height="270"
                alt="Thijs"
            />
            <div>
                <p class="name">Thijs Van der Schaeghe</p>
                <p class="title" i18n="@@website.aboutUs.thijsTitle">Business Developer</p>
                <p class="story" i18n="@@website.aboutUs.thijsStory">
                    Thijs has already collected thousands of steam games, four cats and one glorious <em>man bun</em>. He passionately deploys the latest technology at Quizwitz, the most fun quiz software. At Tyyp, he helps think of innovative ways to grow Tyyp.
                </p>
            </div>
        </div>

        <div class="teamMember slideUp">
            <img
                ngSrc="assets/images/team/members/eline.webp"
                width="180"
                height="270"
                alt="Eline"
            />
            <div>
                <p class="name">Eline Claeyssens</p>
                <p class="title" i18n="@@website.aboutUs.elineTitle">UX Designer & Illustrator</p>
                <p class="story" i18n="@@website.aboutUs.elineStory">
                    Eline is the creative brain behind Tyyp's designs. Logo illustrations, web design, she does it all! Eline loves everything to do with design: from architecture and fashion to cinematography. But most of all, she is into illustration and art.
                </p>
            </div>
        </div>
    </div>
</div>
