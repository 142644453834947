<div class="upgradeToFull" [class.fullEnabled]="comboDealEnabled" [class.hideFeatures]="!showFeatures">
    <div>
        <div class="upgradeToFullHeader">
            <img
                ngSrc="assets/images/shop/{{ upgradeProduct.image }}.webp"
                alt="{{ Products[comboDealProduct.title] }}"
                class="productImage"
                width="500"
                height="412"
            />

            <div class="advancedHeader">
                <p i18n="@@advancedTypeClass">Advanced Typing Course</p>
                <p i18n="@@discountExtraOrder">Order with extra discount</p>
            </div>

            <span class="discount">-{{ getUpgradeDiscount() }}%</span>
        </div>

        <ul *ngIf="showFeatures" class="checklist">
            <li><span i18n="@@discountExtraOrderPrice">Order along at an <strong>extra discount</strong> for {{ comboDealProduct.calculatedIncl - basicProduct.calculatedIncl | formatCurrency:currency }}</span></li>
            <li><span i18n="@@discountExtraOrderMoreContent">Even more <strong>exercises</strong>, <strong>games</strong> and <strong>videos</strong></span></li>
            <li><span i18n="@@discountExtraOrderExtraChapters">Learn how to type <strong>numbers</strong> and <strong>special characters</strong></span></li>
            <li><span i18n="@@discountExtraOrderTypingSpeed">Keep increasing your <strong>typing speed</strong></span></li>
            <li><span i18n="@@discountExtraOrderExtraYear">Get <strong>an extra year of access</strong></span></li>
        </ul>
    </div>

    <div>
        <p class="advancedToggle">
            <mat-slide-toggle (change)="toggleFullVersion($event)"></mat-slide-toggle>
        </p>

        <span *ngIf="quantity" class="subtotal">
            <span class="before">{{ getComboDealListPrice() | formatCurrency:currency }}</span><br />
            <span class="now">{{ getComboDealTotalPrice() | formatCurrency:currency }}</span>
        </span>
    </div>
</div>
