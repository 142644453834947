import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../../shared/services/user.service';
import {NavigationService} from '../../shared/services/navigation.service';
import {StateService} from '../state.service';

@Injectable()
export class HomeGuard  {
    constructor(
        private userService: UserService,
        private router: Router,
        private navigationService: NavigationService,
        private stateService: StateService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        const user = this.stateService.getActiveUser();

        if (user) {
            let path;
            switch (user.roleId) {
                case 1:
                    path = '/admin/organizations';
                    break;

                case 2:
                    path = '/organization/dashboard';
                    break;

                case 3:
                    path = '/organization/dashboard';
                    break;

                case 4:
                    path = '/' + this.navigationService.getRouteTranslation('course');
                    break;

                case 5:
                    path = '/sales';
                    break;
            }

            this.router.navigate([path]);
        }

        return true;
    }
}
