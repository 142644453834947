export class Product {
    constructor(
        public id: number,
        public title: string,
        public totalDaysValid: number,
        public listPrice: number,
        public taxIncluded: boolean,
        public business: boolean,
        public school: boolean,
        public basic: boolean,
        public dlc: boolean,
        public comboDeal: boolean,
        public image: string,
        public fullPriceExcl?: number,
        public fullPriceIncl?: number,
        public discountOnFullPrice?: number,
        public calculatedExcl?: number,
        public calculatedIncl?: number,
        public saleName?: {[key: string]: string},
        public saleUntil?: number,
        public productTypeCode?: string
    ) {}
}
