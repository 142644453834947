<course-cta-footer></course-cta-footer>

<div class="footer">
    <div class="wrapper">
        <a routerLink="/">
            <img
                ngSrc="assets/images/layout/allWhiteLogo.svg"
                i18n-alt="@@website.logoAlt"
                alt="Tyyp logo"
                class="logo"
                width="285.42"
                height="80"
            />
        </a>

        <nav class="footerMenu">
            <ng-container *ngFor="let item of (items$ | async)">
                <a
                    *ngIf="item.label"
                    [routerLink]="item.translatedRouterLink ? '/' + navigationService.getRouteTranslation(item.translatedRouterLink) : item.routerLink"
                    routerLinkActive="active"
                >
                    {{ item.label }}
                </a>
                <span *ngIf="item === '|'" class="menuSeparator"></span>
            </ng-container>
        </nav>

        <div class="socialIcons">
            <a href="https://www.facebook.com/tyyp.be">
                <img
                    ngSrc="assets/images/icons/facebook.svg"
                    i18n-alt="@@website.facebookAlt"
                    alt="Facebook"
                    width="512"
                    height="512"
                />
            </a>

            <a href="https://www.instagram.com/tyyp.be">
                <img
                    ngSrc="assets/images/icons/instagram.svg"
                    i18n-alt="@@website.instagramAlt"
                    alt="Instagram"
                    width="448"
                    height="512"
                />
            </a>

            <a href="https://www.linkedin.com/company/tyyp-io/">
                <img
                    ngSrc="assets/images/icons/linkedin.svg"
                    i18n-alt="@@website.linkedinAlt"
                    alt="LinkedIn"
                    width="455"
                    height="455"
                />
            </a>
        </div>
    </div>
</div>
