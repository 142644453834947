<mat-toolbar color="primary">
    <a routerLink="/" (click)="close()">
        <img
            ngSrc="assets/images/layout/whiteLogo.svg"
            i18n-alt="@@website.logoAlt"
            alt="Tyyp logo"
            class="logo"
            width="302.12"
            height="77.22"
        />
    </a>

    <div class="spacer"></div>

    <button mat-icon-button (click)="close()" class="closeButton">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<ng-container *ngFor="let item of items$ | async">
    <a
        *ngIf="item.label"
        [routerLink]="item.translatedRouterLink ? '/' + navigationService.getRouteTranslation(item.translatedRouterLink) : item.routerLink"
        (click)="close()"
        routerLinkActive="active"
        class="mobileMenuBtn"
    >
        <span class="label">{{ item.label }}</span>
        <img
            *ngIf="item.icon"
            ngSrc="assets/images/icons/{{ item.icon }}.svg"
            class="svgIcon"
            width="300"
            height="300"
            alt=""
        />
    </a>
    <div *ngIf="item === '|'" class="menuSeparator"></div>
</ng-container>
