import {Injectable, Injector} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SupportArticle} from '../../models/supportArticle';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AppService} from './app.service';
import {UserFactory} from './factories/user.factory';
import {OrganizationFactory} from './factories/organization.factory';

@Injectable()
export class SupportService {
    private readonly articles: SupportArticle = {
        slug: 'support',
        title: $localize `:@@support.support:Help center`,
        children: [
            {
                icon: 'students.svg',
                slug: 'students',
                title: $localize`:@@support.forStudents:For students`,
                children: [
                    {
                        icon: 'usingTheCourse.svg',
                        slug: 'using-the-course',
                        title: $localize`:@@support.usingTheCourse:Using the course`,
                        children: [
                            {
                                slug: 'settings',
                                title: $localize`:@@support.courseSettings:Keyboard layout, language and settings`
                            },
                            {
                                slug: 'accessibility',
                                title: $localize`:@@support.courseAccessibility:Accessibility settings`
                            },
                            {
                                slug: 'trial',
                                title: $localize`:@@support.trial:Trial version`
                            }
                        ]
                    },
                    {
                        icon: 'selfJoin.svg',
                        slug: 'account-setup',
                        title: $localize`:@@support.accountSetup:Set up your account`
                    }
                ]
            },
            {
                icon: 'instructors.svg',
                slug: 'organizations',
                title: $localize`:@@support.forOrganizations:For schools & businesses`,
                children: [
                    {
                        icon: 'start.svg',
                        slug: 'getting-started',
                        title: $localize`:@@support.gettingStarted:Getting started in your school/business`
                    },
                    {
                        icon: 'classes.svg',
                        slug: 'classes',
                        title: $localize`:@@support.classes:Manage your classes`,
                        children: [
                            {
                                slug: 'add',
                                title: $localize`:@@support.addClass:Add a class`
                            },
                            {
                                slug: 'edit',
                                title: $localize`:@@support.editClass:Edit a class`
                            },
                            {
                                slug: 'archive',
                                title: $localize`:@@support.archiveClass:Archive a class`
                            }
                        ]
                    },
                    {
                        icon: 'students.svg',
                        slug: 'students',
                        title: $localize`:@@support.students:Manage your students`,
                        children: [
                            {
                                slug: 'enroll',
                                title: $localize`:@@support.enroll:Enroll students`
                            },
                            {
                                slug: 'login-credentials',
                                title: $localize`:@@support.loginCredentials:Providing login credentials`
                            },
                            {
                                slug: 'edit',
                                title: $localize`:@@support.editStudent:Edit a student`
                            },
                            {
                                slug: 'reset-password',
                                title: $localize`:@@support.resetStudentPassword:Reset a student's password`
                            },
                            {
                                slug: 'delete',
                                title: $localize`:@@support.deleteStudent:Delete a student`
                            },
                            {
                                slug: 'restore',
                                title: $localize`:@@support.restoreStudent:Restore a student`
                            },
                            {
                                slug: 'progress',
                                title: $localize`:@@support.studentProgress:Monitor student progress`
                            }
                        ]
                    },
                    {
                        icon: 'licenses.svg',
                        slug: 'licenses',
                        title: $localize`:@@support.licenses:Manage your licenses`,
                        children: [
                            {
                                slug: 'enroll-order',
                                title: $localize`:@@support.enrollOrder:Purchase when enrolling students`
                            },
                            {
                                slug: 'advance-order',
                                title: $localize`:@@support.advanceOrder:Order in advance`
                            },
                            {
                                slug: 'assign-licenses',
                                title: $localize`:@@support.assignLicenses:Assign licenses`
                            },
                            {
                                slug: 'student-order',
                                title: $localize`:@@support.studentOrder:Purchased by students`
                            }
                        ]
                    },
                    {
                        icon: 'instructors.svg',
                        slug: 'instructors',
                        title: $localize`:@@support.instructors:Manage your instructors and administrators`,
                        children: [
                            {
                                slug: 'instructor-administrator',
                                title: $localize`:@@support.differenceInstructorAdministrator:Instructor or administrator?`
                            },
                            {
                                slug: 'add',
                                title: $localize`:@@support.addInstructor:Add an instructor or administrator`
                            },
                            {
                                slug: 'assign',
                                title: $localize`:@@support.assignInstructor:Assign an instructor to classes`
                            },
                            {
                                slug: 'edit',
                                title: $localize`:@@support.editInstructor:Edit an instructor or administrator`
                            },
                            {
                                slug: 'reset-password',
                                title: $localize`:@@support.resetInstructorPassword:Reset an instructor or administrator's password`
                            },
                            {
                                slug: 'delete',
                                title: $localize`:@@support.deleteInstructor:Delete an instructor or administrator`
                            },
                            {
                                slug: 'restore',
                                title: $localize`:@@support.restoreInstructor:Restore an instructor or administrator`
                            },
                            {
                                slug: 'course-access',
                                title: $localize`:@@support.instructorCourseAccess:Access to the course`
                            }
                        ]
                    },
                    {
                        icon: 'exercises.svg',
                        slug: 'exercises',
                        title: $localize`:@@support.exercises:Create your own exercises`,
                        children: [
                            {
                                slug: 'add',
                                title: $localize`:@@support.addExercise:Create an exercise`
                            },
                            {
                                slug: 'access',
                                title: $localize`:@@support.exerciseAccess:Student access to exercises`
                            },
                            {
                                slug: 'edit',
                                title: $localize`:@@support.editExercise:Edit an exercise`
                            },
                            {
                                slug: 'delete',
                                title: $localize`:@@support.deleteExercise:Delete an exercise`
                            }
                        ]
                    },
                    {
                        icon: 'resources.svg',
                        slug: 'resources',
                        title: $localize`:@@support.resources:Resources`
                    }
                ]
            }
        ]
    };

    constructor(
        private http: HttpClient,
        private injector: Injector,
        private appService: AppService
    ) {}

    getArticles() {
        return this.articles;
    }

    getArticle(
        slugs?: string[]
    ) {
        // main articles
        let article = this.articles;

        // loop the provided slugs and filter
        if (slugs) {
            for (const slug of slugs) {
                const result = article.children.filter(child => child.slug === slug)[0];

                if (result) {
                    article = result;
                }
            }
        }

        return article;
    }

    getPin(): Observable<string> {
        return this.http.get<string>(environment.apiUrl + 'support/pin');
    }

    validatePin(pin): Observable<any> {
        return this.http.post<any>(environment.apiUrl + 'support/validate_pin', {
            pin
        }).pipe(
            map(
                (result) => {
                    const userFactory = this.injector.get(UserFactory);
                    const organizationFactory = this.injector.get(OrganizationFactory);

                    return {
                        user: result.user ? userFactory.map(result.user) : undefined,
                        organization: result.organization ? organizationFactory.map(result.organization) : undefined,
                        timestamp: Date.parse(result.timestamp)
                    };
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }
}
