<span>
    <a
        *ngIf="!getActiveUser()"
        routerLink="/login"
        routerLinkActive="active"
        i18n="@@login.login"
        class="loginBtn"
        matRipple
    >Log in</a>

    <a *ngIf="getActiveUser()" class="userButton" [matMenuTriggerFor]="userMenu">
        <course-avatar [size]="40" [avatar]="getActiveUser().avatar"></course-avatar>
        <mat-icon class="arrowIcon">arrow_drop_down</mat-icon>
    </a>
    <mat-menu #userMenu="matMenu" class="userMenu">
        <button routerLink="/account" mat-menu-item>
            <img
                ngSrc="assets/images/icons/users2.svg"
                class="svgIcon"
                i18n-alt="@@admin.users"
                alt="Users"
                width="300"
                height="300"
            />
            <ng-container i18n="@@account.myAccount">My account</ng-container>
        </button>
        <button routerLink="/logout" mat-menu-item>
            <img
                ngSrc="assets/images/icons/logout.svg"
                class="svgIcon"
                i18n-alt="@@auth.logout"
                alt="Log out"
                width="300"
                height="300"
            />
            <ng-container i18n="@@auth.logout">Log out</ng-container>
        </button>
    </mat-menu>
</span>

