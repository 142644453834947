import {Order} from './order';
import {Product} from './product';

export class Sale {
    constructor(
        public id: number,
        public name: {[key: string]: string},
        public productId: number,
        public percentage: number,
        public validFrom: number,
        public validUntil: number,
        public recurring: boolean,
        public ordersCount?: number,
        public orders?: Order[],
        public product?: Product
    ) {}
}
