import {DiscountCode} from './discountCode';
import {Currency} from './currency';
import {Product} from './product';
import {Organization} from './organization';
import {ExternalProduct} from './externalProduct';

export class Order {
    constructor(
        public id: number,
        public nr: number,
        public formattedNr: string,
        public creditedOrderId: number,
        public userId: number,
        public organizationId: number,
        public paidAt: number,
        public licensesReleasedAt: number,
        public cancelledAt: number,
        public firstName: string,
        public name: string,
        public email: string,
        public companyName: string,
        public taxNumber: string,
        public countryCode: string,
        public address: string,
        public postalCode: string,
        public city: string,
        public state: string,
        public createdAt: number,
        public dueAt: number,
        public external: boolean,
        public externalProducts?: ExternalProduct[],
        public totalIncl?: number,
        public totalExcl?: number,
        public tax?: number,
        public taxPercentage?: number,
        public discount?: number,
        public discountCode?: DiscountCode,
        public currency?: Currency,
        public products?: {amount: number, productListPrice: number, product: Product},
        public organization?: Organization,
        public licensesWithTrashedCount?: number,
        public unusedLicensesCount?: number,
        public creditNotes?: Order[],
        public creditedOrder?: Order,
        public poNumber?: string,
        public salePercentage?: number
    ) {}
}
