import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../shared/services/navigation.service';
import {AppService} from '../../../shared/services/app.service';
import {StateService} from '../../../services/state.service';

@Component({
    selector: 'course-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewChecked {
    constructor(
        public navigationService: NavigationService,
        private appService: AppService,
        public stateService: StateService
    ) {}

    ngOnInit() {
        // test: scroll to an element
        // window.setTimeout(
        //     () => {
        //         // document.getElementsByClassName('products')[0].scrollIntoView(true);
        //         // document.getElementsByTagName('course-faq')[0].scrollIntoView(true);
        //     }, 100
        // );
        // end test
    }

    ngAfterViewChecked() {
        this.appService.initAnimations('.hero .slideLeft', 0);
    }
}

