import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Modal} from '../../../models/modal';
import {StateService} from '../../../services/state.service';

@Component({
    selector: 'course-modals',
    templateUrl: './modals.component.html',
    styleUrls: ['./modals.component.scss']
})
export class ModalsComponent implements OnInit {
    modal: Observable<Modal | undefined>;

    constructor(
        private stateService: StateService
    ) {
    }

    ngOnInit() {
        this.modal = this.stateService.getCurrentModalObservable();
    }
}

