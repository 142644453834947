import {ExerciseTypes} from './enums/exerciseTypes';
import {ExerciseAccessTypes} from './enums/exerciseAccessTypes';
import {User} from './user';
import {Organization} from './organization';
import {Lesson} from './lesson';
import {Chapter} from './chapter';

export class Exercise {
    constructor(
        public id: number,
        public courseId: number,
        public chapterId: number,
        public lessonId: number,
        public title: string,
        public exerciseType: ExerciseTypes,
        public cpm: number,
        public correctRatio: number,
        public maxAttempts: number,
        public access: ExerciseAccessTypes,
        public userId: number,
        public organizationId: number,
        public adaptive: boolean,
        public data?: string,
        public index?: number,
        public completedPrevious?: boolean,
        public user?: User,
        public organization?: Organization,
        public position?: number,
        public lesson?: Lesson,
        public chapter?: Chapter,
        public keyboardLayoutName?: string,
        public bestStars?: number,
        public bestCpm?: number,
        public bestCorrectRatio?: number,
        public isCompleted?: boolean
    ) {}
}
