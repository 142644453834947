import {Injectable} from '@angular/core';
import {KeyboardLayout} from '../../../models/keyboardLayout';

@Injectable()
export class KeyboardLayoutFactory {
    map(response) {
        return new KeyboardLayout(
            response.id,
            response.code
        );
    }
}
