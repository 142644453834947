import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../../shared/services/user.service';
import {StateService} from '../state.service';

@Injectable()
export class StudentGuard  {
    constructor(
        private userService: UserService,
        private router: Router,
        private stateService: StateService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
        const user = this.stateService.getActiveUser();
        if (user && user.roleId === 4) {
            return true;
        } else {
            this.router.navigate(['/login']);
        }
    }
}
