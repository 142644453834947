import {Injectable, Injector} from '@angular/core';
import {Class} from '../../../models/class';
import {UserFactory} from './user.factory';
import {OrganizationFactory} from './organization.factory';

@Injectable()
export class ClassFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        const userFactory = this.injector.get(UserFactory);
        const organizationFactory = this.injector.get(OrganizationFactory);

        return new Class(
            response.id,
            response.name,
            response.self_join_code,
            !!response.archived,
            Date.parse(response.created_at),
            response.instructors ? response.instructors.map(
                (instructor) => {
                    return userFactory.map(instructor);
                }
            ) : undefined,
            response.students_count ? response.students_count : undefined,
            response.organization ? organizationFactory.map(response.organization) : undefined
        );
    }
}
