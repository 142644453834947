import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {OrganizationType} from '../../models/organizationType';
import {KnownOrganization} from '../../models/knownOrganization';
import {Organization} from '../../models/organization';
import {HttpClient} from '@angular/common/http';
import {OrganizationFactory} from './factories/organization.factory';
import {AppService} from './app.service';
import {KnownOrganizationFactory} from './factories/knownOrganization.factory';
import {StateService} from '../../services/state.service';

@Injectable()
export class OrganizationService {
    constructor(
        private http: HttpClient,
        private organizationFactory: OrganizationFactory,
        private appService: AppService,
        private knownOrganizationFactory: KnownOrganizationFactory,
        private stateService: StateService
    ) {
    }

    getAll(
        page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        filter: string
    ) {
        if (!filter) {
            filter = '';
        }

        return this.http.get<any>(
            environment.apiUrl + 'organizations/all'
            + '?page=' + page
            + '&page-size=' + pageSize
            + '&sort-by=' + sortBy
            + '&sort-direction=' + sortDirection
            + '&filter=' + filter
        );
    }

    getOne(id: number | string) {
        return this.http.get<Organization>(environment.apiUrl + 'organizations/' + id)
            .pipe(
                map(
                    (response) => {
                        return this.organizationFactory.map(response);
                    }
                )
            );
    }

    /**
     * Schools registered before 2024-09-15 can use the legacy product
     * in schoolyear 2024-2025.
     * After that schoolyear, no one has access to the legacy product anymore.
     */
    checkHasLegacyPricing() {
        let organization = this.stateService.getActiveOrganization();

        if (!organization) {
            const user = this.stateService.getActiveUser();
            if (user && user.organization) {
                organization = user.organization;
            }
        }

        if (!organization || !organization.isSchool) {
            return false;
        }

        const createdAt = organization.createdAt;

        const creationDeadlineDate = new Date('2024-09-15T23:59:59').getTime();
        const endDate = new Date('2025-06-30T23:59:59').getTime();
        const now = Date.now();

        return createdAt < creationDeadlineDate
            && now < endDate;
    }

    validateOrganizationDetails(
        countryCode: string,
        organizationTypeId: number,
        name: string,
        address: string,
        state: string,
        postalCode: string,
        city: string
    ) {
        return this.http.post<any>(environment.apiUrl + 'organizations/validate_organization_details', {
            country_code: countryCode,
            organization_type_id: organizationTypeId,
            name,
            address,
            state,
            postal_code: postalCode,
            city
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    validateAccountDetails(
        firstName: string,
        name: string,
        email: string,
        password: string
    ) {
        return this.http.post<any>(environment.apiUrl + 'organizations/validate_account_details', {
            first_name: firstName,
            name,
            email,
            password
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    create(
        isSchool: boolean,
        countryCode: string,
        organizationTypeId: number,
        organizationName: string,
        address: string,
        state: string,
        postalCode: string,
        city: string,
        firstName: string,
        name: string,
        email: string,
        password: string,
        billingEmail: string,
        taxNumber: string,
        optIn: boolean,
        acceptTerms: boolean,
        languageId: number
    ) {
        return this.http.post<any>(environment.apiUrl + 'organizations/create', {
            school: isSchool,
            country_code: countryCode,
            organization_type_id: organizationTypeId,
            organization_name: organizationName,
            address,
            state,
            postal_code: postalCode,
            city,
            first_name: firstName,
            name,
            email,
            password,
            billing_email: billingEmail,
            tax_number: taxNumber,
            opt_in: optIn,
            accept_terms: acceptTerms,
            language_id: languageId
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    createBasic(
        name: string,
        billingName: string,
        billingEmail: string,
        billingTaxNumber: string,
        billingAddress: string,
        billingPostalCode: string,
        billingCity: string,
        billingState: string,
        billingCountryCode: string,
        isSchool: boolean,
        organizationTypeId: number,
        taxNumber: string,
        address: string,
        postalCode: string,
        city: string,
        state: string,
        countryCode: string,
        languageId: number
    ) {
        return this.http.post<any>(environment.apiUrl + 'organizations/create_basic', {
            name,
            billing_name: billingName,
            billing_email: billingEmail,
            billing_tax_number: billingTaxNumber,
            billing_address: billingAddress,
            billing_postal_code: billingPostalCode,
            billing_city: billingCity,
            billing_state: billingState,
            billing_country_code: billingCountryCode,
            school: isSchool,
            organization_type_id: organizationTypeId,
            tax_number: taxNumber,
            address,
            postal_code: postalCode,
            city,
            state,
            country_code: countryCode,
            language_id: languageId
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    update(
        id: number,
        companyName: string,
        billingName: string,
        billingEmail: string,
        billingTaxNumber: string,
        billingAddress: string,
        billingPostalCode: string,
        billingCity: string,
        billingState: string,
        billingCountryCode: string,
        taxNumber: string,
        address: string,
        postalCode: string,
        city: string,
        state: string,
        countryCode: string,
        school?: boolean,
        organizationTypeId?: number
    ) {
        return this.http.patch<any>(environment.apiUrl + 'organizations/' + id, {
            name: companyName,
            billing_name: billingName,
            billing_email: billingEmail,
            billing_tax_number: billingTaxNumber,
            billing_address: billingAddress,
            billing_postal_code: billingPostalCode,
            billing_city: billingCity,
            billing_state: billingState,
            billing_country_code: billingCountryCode,
            tax_number: taxNumber,
            address,
            postal_code: postalCode,
            city,
            state,
            country_code: countryCode,
            school,
            organization_type_id: organizationTypeId
        }).pipe(
            map(
                (response) => {
                    return this.organizationFactory.map(response);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    updateBilling(
        id: number,
        billingName: string,
        billingEmail: string,
        billingTaxNumber: string,
        billingAddress: string,
        billingPostalCode: string,
        billingCity: string,
        billingState: string,
        billingCountryCode: string
    ) {
        return this.http.patch<any>(environment.apiUrl + 'organizations/' + id + '/billing', {
            billing_name: billingName,
            billing_email: billingEmail,
            billing_tax_number: billingTaxNumber,
            billing_address: billingAddress,
            billing_postal_code: billingPostalCode,
            billing_city: billingCity,
            billing_state: billingState,
            billing_country_code: billingCountryCode
        }).pipe(
            map(
                (response) => {
                    return this.organizationFactory.map(response);
                }
            ),
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    delete(id: number) {
        return this.http.delete(environment.apiUrl + 'organizations/' + id);
    }

    updateSetting(id: number, setting: string, value: any) {
        return this.http.patch<any>(environment.apiUrl + 'organizations/' + id + '/setting/' + setting, {
            value
        });
    }

    updateSettings(
        id: number,
        courseId: number,
        languageId: number,
        allowUpdateAccountDetails: boolean,
        allowUpdatePassword: boolean,
        allowUpdateCourse: boolean,
        showKeyboard: boolean,
        allowBackspace: boolean,
        appleKeyboard: boolean,
        basicExamUseDefaultCriteria: boolean,
        basicExamCpm: number,
        basicExamCorrectRatio: number,
        finalExamUseDefaultCriteria: boolean,
        finalExamCpm: number,
        finalExamCorrectRatio: number,
        organizationPaysForLicenses: boolean
    ) {
        return this.http.patch<any>(environment.apiUrl + 'organizations/' + id + '/settings', {
            course_id: courseId,
            language_id: languageId,
            allow_update_account_details: allowUpdateAccountDetails,
            allow_update_password: allowUpdatePassword,
            allow_update_course: allowUpdateCourse,
            show_keyboard: showKeyboard,
            allow_backspace: allowBackspace,
            apple_keyboard: appleKeyboard,
            basic_exam_use_default_criteria: basicExamUseDefaultCriteria,
            basic_exam_cpm: basicExamCpm,
            basic_exam_correct_ratio: basicExamCorrectRatio,
            final_exam_use_default_criteria: finalExamUseDefaultCriteria,
            final_exam_cpm: finalExamCpm,
            final_exam_correct_ratio: finalExamCorrectRatio,
            organization_pays_for_licenses: organizationPaysForLicenses
        }).pipe(
            map(
                (response) => {
                    return this.organizationFactory.map(response);
                }
            )
        );
    }

    getMaxLogins(organization?: Organization) {
        if (!organization) {
            organization = this.stateService.getActiveOrganization();
        }

        if (organization.isSchool) {
            return this.appService.maxLicensesOnlinePayment.school;
        }

        return this.appService.maxLicensesOnlinePayment.company;
    }

    getAllOrganizationTypes(): Observable<OrganizationType[]> {
        return this.http.get<any>(environment.apiUrl + 'organization_types/all')
            .pipe(
                map(
                    (response) => {
                        return response.map(
                            (type) => {
                                return new OrganizationType(
                                    type.id,
                                    type.name,
                                    !!type.school
                                );
                            }
                        );
                    }
                )
            );
    }

    updateProductCategoryProduct(productCategoryCode: string, productId: number) {
        return this.http.patch<any>(environment.apiUrl + 'organizations/update_product_category_product', {
            product_category_code: productCategoryCode,
            product_id: productId
        }).pipe(
            catchError(
                (err) => {
                    if (err.status === 422) {
                        this.appService.clearLoadingError();
                    }

                    throw err;
                }
            )
        );
    }

    searchKnownOrganizations(
        value: string,
        countryCode?: string,
        organizationTypeId?: number
    ): Observable<KnownOrganization[]> {
        value = value.replace(/\//g, '_SLASH_');

        return this.http.get<KnownOrganization[]>(
            environment.apiUrl + 'known_organizations/search/' + value + '/' + countryCode + '/' + organizationTypeId
        )
            .pipe(
                map(
                    (response) => {
                        return response.map(
                            (org) => {
                                return this.knownOrganizationFactory.map(org);
                            }
                        );
                    }
                )
            );
    }
}
