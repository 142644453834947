import {Component, HostListener, Input} from '@angular/core';
import {animations} from '../../../services/animations';
import {ModalService} from '../../../services/modal.service';

@Component({
    selector: 'course-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    animations
})
export class ModalComponent {
    @Input() modalWidth = 800;
    @Input() success = false;
    @Input() error = false;
    @Input() disableClose = false;
    @Input() closeLabel: string;
    @Input() escapeToClose = false;
    @Input() customClass: string;
    @Input() disableEnterAnimation = false;

    constructor(
        private modalService: ModalService
    ) {}

    doClose() {
        if (this.disableClose) {
            return;
        }

        this.modalService.closeModal();
    }

    @HostListener('window:keydown', ['$event'])
    keyDown(evt: KeyboardEvent) {
        if (!this.escapeToClose) {
            return;
        }

        if (evt.key === 'Escape') {
            this.modalService.closeModal();
        }
    }
}

