import {Directive, Inject, OnInit} from '@angular/core';
import {filter, first, map, switchMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {OnDestroyMixin, untilComponentDestroyed} from '@w11k/ngx-componentdestroyed';
import {DOCUMENT} from '@angular/common';
import {AppService} from '../shared/services/app.service';


/**
 * Automatically set the browser title and metadata for the current page, based on the route.
 * Simply add a title attribute to the data attribute in the relevant routes file.
 * Route data (f.e. resolved by the resolver) can be used by placing a dot-separated key chain parameter in the title.
 * Example:
 * {
 *     path: ':id',
 *     component: ViewCourseComponent,
 *     resolve: {
 *         course: CourseFromStateResolver
 *     },
 *     data: {title: $localize `:@@course.view:View course` + '  "{{course.name}}"'}
 * }
 *
 * In this example, this directive will look for a "course" attribute in the data of this route, and display the name
 * of the data we find there. Note that the "course" attribute doesn't exist in the data. However, you can see
 * the resolver will resolve to "course".
 */
@Directive({
    selector: '[courseMeta]'
})
export class MetaDirective extends OnDestroyMixin implements OnInit {
    routeData;
    routeParams;
    alternateLinks: { [key: string]: HTMLLinkElement } = {};

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private doc: Document,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private title: Title,
        private meta: Meta,
        private appService: AppService
    ) {
        super();
    }

    /**
     * On init
     */
    ngOnInit() {
        this.createAlternateLinks();
        this.initRouteTitles();
    }

    /**
     * Listen to router events and change the title depending on the route.
     */
    initRouteTitles() {
        // The router events observable
        const routerEvents = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }

                this.setParams(route.params);

                return route;
            }),
            switchMap(
                (route) => route.data
            )
        );

        routerEvents.pipe(
            untilComponentDestroyed(this)
        ).subscribe(
            (data) => {
                this.routeData = data;

                // title
                const title = this.replaceTags(data.title) + ' - Tyyp';
                this.title.setTitle(title);

                // meta: description
                let description = '';
                if (data.description) {
                    description = this.replaceTags(data.description);
                }

                this.meta.updateTag({
                    name: 'description',
                    content: description
                });

                // opengraph meta tags
                this.meta.updateTag({
                    name: 'og:title',
                    content: title
                });

                this.meta.updateTag({
                    name: 'og:description',
                    content: description
                });

                this.meta.updateTag({
                    name: 'og:image',
                    content: 'https://' + this.appService.getHostname() + '/assets/images/typingCourse/business.svg'
                });

                this.meta.updateTag({
                    name: 'og:site_name',
                    content: 'Tyyp'
                });

                // alternate language versions
                this.setAlternateLinks();

                // structured data
                this.setStructuredData(data.structuredData);
            }
        );
    }

    setParams(params) {
        params.pipe(
            first()
        ).subscribe(
            (routeParams) => {
                this.routeParams = routeParams;
            }
        );
    }

    replaceTags(str): string {
        // data
        str = str.replaceAll(/{{[a-zA-Z0-9.-\|]+}}/g, (match) => {
            return this.replaceData(match);
        });

        // route params
        str = str.replaceAll(/{[a-zA-Z0-9.-]+}/g, (match) => {
            return this.replaceRouteParam(match);
        });

        return str;
    }

    replaceRouteParam(match) {
        match = match.replace('{', '').replace('}', '');

        const parts = match.split('.');

        let params = this.routeParams;

        parts.forEach(
            (part) => {
                if (params) {
                    params = params[part];
                } else {
                    params = '';
                }
            }
        );

        return params;
    }

    replaceData(match) {
        match = match.replace('{{', '').replace('}}', '');

        const mainParts = match.split('|');
        const main = mainParts[0];
        const alternative = mainParts[1];

        const parts = main.split('.');

        let data = this.routeData;

        parts.forEach(
            (part) => {
                if (data) {
                    data = data[part];
                } else {
                    data = '';
                }
            }
        );

        if (data === '' && alternative) {
            data = alternative;
        }

        return data;
    }

    createAlternateLinks() {
        const domainDefaults = this.appService.domainDefaults;

        Object.keys(domainDefaults).forEach(
            (domain) => {
                const link = this.doc.createElement('link');
                link.setAttribute('rel', 'alternate');
                link.setAttribute('hreflang', domainDefaults[domain].i18nCode);
                this.doc.head.appendChild(link);
                this.alternateLinks[domain] = link;
            }
        );
    }

    setAlternateLinks() {
        const domainDefaults = this.appService.domainDefaults;

        Object.keys(domainDefaults).forEach(
            (domain) => {
                let href = 'https://www.' + domain;

                // path
                let path = this.router.url;

                // replace translated path segments
                if (this.routeData.translatedSegments) {
                    const segments = path.split('/');
                    segments.shift();

                    this.routeData.translatedSegments.forEach(
                        (translatedSegment) => {
                            segments[translatedSegment.i] = translatedSegment.value[domainDefaults[domain].language];
                        }
                    );

                    path = '/' + segments.join('/');
                }

                href += path;

                this.alternateLinks[domain].setAttribute('href', href);
            }
        );
    }

    setStructuredData(structuredData: string[]) {
        let output = '';
        if (structuredData) {
            const items = [];
            structuredData.forEach(
                (type) => {
                    switch (type) {
                        case 'WebSite':
                            items.push({
                                '@context': 'https://schema.org',
                                '@type': 'WebSite',
                                name: 'Tyyp',
                                url: 'https://' + this.appService.getHostname()
                            });
                            break;

                        case 'Organization':
                            items.push({
                                '@context': 'https://schema.org',
                                '@type': 'Organization',
                                url: 'https://' + this.appService.getHostname(),
                                logo: 'https://' + this.appService.getHostname() + '/assets/images/layout/logo.svg'
                            });
                            break;


                        /*
                        Note: type Product is added in the purchase component, since we need the product
                        info from the API.
                        We can't use resolvers (SEO) so this data is not available here.
                        In theory could be possible by setting the product in a service and subscribing to it here.
                        But for the moment you can find this data in the purchase component.
                         */
                    }
                }
            );

            output = JSON.stringify(items);
        }

        this.doc.getElementById('structuredData').innerHTML = output;
    }
}
