import {Component, Input} from '@angular/core';

@Component({
    selector: 'course-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
    @Input() section: 'home' | 'school' | 'business';
}

