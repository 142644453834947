<div class="hero">
    <div class="heroIllustration">
        <img
            ngSrc="assets/images/typingCourse/typing.svg"
            i18n-alt="@@website.home.heroAlt"
            alt="Typing on keyboard"
            class="heroImg"
            width="669.19"
            height="553.74"
            priority="priority"
        />
    </div>
    <div class="heroContent">
        <div
            [class.slideLeft]="stateService.getFirstHeroAnimationSkipped()"
            [class.slideLeftMedium]="stateService.getFirstHeroAnimationSkipped()"
        >
            <h1
                i18n="@@website.home.title"
            ><strong>Learn to type</strong> in the fastest<br />and most fun way</h1>

            <ul class="checklist">
                <li>
                    <span
                        i18n="@@website.home.hero1"
                    >Fascinating <strong>how-to videos</strong></span>
                </li>
                <li>
                    <span
                        i18n="@@website.home.hero2"
                    >Fun <strong>exercises</strong> and <strong>typing games</strong></span>
                </li>
                <li>
                    <span
                        i18n="@@website.home.hero3"
                    >Get your <strong>typing diploma</strong></span>
                </li>
            </ul>


            <p class="startBtn">
                <a [routerLink]="'/' + navigationService.getRouteTranslation('course')" class="keyboardButton">
                    <span i18n="@@website.getStarted">Try it free</span>
                </a>

                <a [routerLink]="'/' + navigationService.getRouteTranslation('purchase')" class="keyboardButton blueKeyboardButton">
                    <span i18n="@@nav.purchase">Purchase</span>
                </a>
            </p>
        </div>

        <img
            ngSrc="assets/images/layout/hero_sockets.svg"
            class="sockets"
            alt=""
            width="36.21"
            height="16.25"
        />
    </div>
</div>

<course-why-touch-typing></course-why-touch-typing>

<course-features></course-features>

<div class="products">
    <div class="websiteSectionTitle">
        <h2 i18n="@@website.shop.forWho">Who can use Tyyp?</h2>
        <p
            i18n="@@website.shop.forWhoSub"
        >Everyone who can read, can learn touch typing with Tyyp!</p>
    </div>

    <course-products></course-products>
</div>

<course-faq section="home"></course-faq>
