import {Injectable, Injector} from '@angular/core';
import {OrganizationSettings} from '../../../models/organizationSettings';

@Injectable()
export class OrganizationSettingsFactory {
    constructor(
        private injector: Injector
    ) {}

    map(response) {
        return new OrganizationSettings(
            response.organization_id,
            response.course_id,
            response.language_id,
            !!response.allow_update_account_details,
            !!response.allow_update_password,
            !!response.allow_update_course,
            !!response.show_keyboard,
            !!response.allow_backspace,
            !!response.apple_keyboard,
            !!response.basic_exam_use_default_criteria,
            response.basic_exam_cpm,
            response.basic_exam_correct_ratio,
            !!response.final_exam_use_default_criteria,
            response.final_exam_cpm,
            response.final_exam_correct_ratio,
            !!response.organization_pays_for_licenses
        );
    }
}
