import {Injectable, Injector} from '@angular/core';
import {Result} from '../../../models/result';
import {TrackingBlockFactory} from './trackingBlock.factory';
import {ExerciseFactory} from './exercise.factory';
import {UserFactory} from './user.factory';
import {MistakeFactory} from './mistake.factory';

@Injectable()
export class ResultFactory {
    constructor(
        private trackingBlockFactory: TrackingBlockFactory,
        private injector: Injector
    ) {}

    map(response) {
        const exerciseFactory = this.injector.get(ExerciseFactory);
        const userFactory = this.injector.get(UserFactory);
        const mistakeFactory = this.injector.get(MistakeFactory);

        return new Result(
            response.exercise_id,
            Date.parse(response.created_at),
            response.data,
            response.duration,
            response.id,
            response.user_id,
            response.cpm,
            response.correct_ratio,
            response.stars,
            !!response.completed,
            response.exercise ? exerciseFactory.map(response.exercise) : undefined,
            response.user ? userFactory.map(response.user) : undefined,
            response.score ? response.score : undefined,
            response.mistakes ? response.mistakes.map(
                (mistake) => {
                    return mistakeFactory.map(mistake);
                }
            ) : undefined,
            response.tracking_blocks ?
                response.tracking_blocks.map(trackingBlock => this.trackingBlockFactory.map(trackingBlock))
                : [],
        );
    }
}
