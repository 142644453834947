export interface RouteTranslation {
    en: string;
    nl: string;
    fr: string;
}
export abstract class RouteTranslations {
    public static course: RouteTranslation = {
        en: 'typing-course',
        nl: 'typecursus',
        fr: 'cours-de-dactylo'
    };

    public static progress: RouteTranslation = {
        en: 'progress',
        nl: 'voortgang',
        fr: 'progres'
    };

    public static about: RouteTranslation = {
        en: 'about-us',
        nl: 'over-ons',
        fr: 'qui-sommes-nous'
    };

    public static contact: RouteTranslation = {
        en: 'contact',
        nl: 'contact',
        fr: 'contact'
    };

    public static forSchools: RouteTranslation = {
        en: 'typing-course-for-schools',
        nl: 'typecursus-voor-scholen',
        fr: 'cours-de-dactylo-pour-ecoles'
    };

    public static purchase: RouteTranslation = {
        en: 'purchase',
        nl: 'kopen',
        fr: 'commander'
    };
}
